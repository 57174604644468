html {
    background-color: #F3F3F3;    
}

.engagementDetailsWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    /* background-color:#FFFFFF; */
}

.row {
    width: 100%;
    background-color: #F3F3F3;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.descriptiveInfo {
    background-color: #FFFFFF;
    display: inline-block;
    padding: 1rem;
    padding-right: 0;
    margin-top: 2px;
}

.quantInfo {
    display: inline-block;
    background-color: #FFFFFF;
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
}

.quad {
    display: inline-block;
    width: 49.5%;
    margin: 0.005rem;
    padding-left: 1rem;
}

.quad:nth-child(odd) {
    border: 1px solid #F3F3F3;
}

.quad:nth-child(even) {
    border-left: 1px solid #F3F3F3;
    border-bottom: 1px solid #F3F3F3;
    border-top: 1px solid #F3F3F3;; 
}

@media (max-width: 1056px) {
    .quantInfo {
        margin-top: 1px;
    }
    .quad:nth-child(odd) {
        border: none;
    }
    .quad:nth-child(even) {
        border: none;
        border-left: 1px solid #F3F3F3;
    }
    .quad:nth-child(1), .quad:nth-child(2) {
        border-bottom: 1px solid #F3F3F3;
    }
}

.quad h2 {
    font-weight: 350;
    font-size: 1.25rem;
}

p {
    font-size: 1rem;
    line-height: 1.5;
    margin: 0.5rem 0rem 0.5rem 0rem;
    padding: 0 !important;
}

.currentEngagement, .quad p {
    color: #B7BABC;
}

.descriptiveInfo h2 {
    margin: 0 !important;
    font-weight: 500;
    color: #003B75;
}

.location {
    font-weight: 600;
}

.garageName, .address {
    margin: 0.1rem 0rem 0.1rem 0rem;
}

.banner {
    width: 100%;
    height: 100%;
}

.engagementPrice {
    margin-bottom: 0.1rem;
}

.banner {
    position: relative;
}

.bannerText {
    position: absolute;
    color: #FFFFFF;
    padding-top: 2%;
    padding-left: 1rem;
}

.bannerText h2, .bannerText h3{
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    padding: none;
}

.learnCard {
    margin: 0 auto;
}

.logoWrapper {
    z-index: 0;
    display: flex;
    justify-content: center;
}

.blue {
    background-color: #0129B0;
}

.greenBlue {
    background-color: #017D79;
}

.green {
    background-color: #0D8035;
}

.learnImg {
    z-index: 1;
    width: 50%;
    max-height: 80px;
    max-width: 80px;
}

#learnGarageRow {
    margin: 1rem 0;
}

.learnGarage {
    margin: 0;
}

.moreInfo {
    margin-top: none;
    width: 100%;
    background-color: #FFFFFF;
    padding: 1rem;
}

.moreInfoTitle {
    margin: 0;
    font-weight: 600;
}

.arrowWrapper {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: row;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.emptySpacer {
    width: 85%;
}

.full-width-error {
    width: 100%;
    justify-content: left;
    margin-right: 0;
}

#lookForwardSmall {
    background-color: #FFFFFF;
    margin-top: 1rem;
    padding: 1rem;
}

@media (max-width: 672px) {
    #lookForwardBig {
        display: none;
    }
}

@media (min-width: 673px) {    
    #lookForwardSmall {
        display: none;
    }
}

#order-warning {
    width: 100%;
    margin-right: 0;
}

#order-warning .bx--toast-notification__details {
    width: 100%;
}