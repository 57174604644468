body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  zoom: 1;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  /* margin: 0; */
  /* margin-top: 100px; */
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

button,
input[type='button'],
input[type='submit'],
input[type='reset'],
input[type='file'] {
  border-radius: 0; }

input[type='text']::-ms-clear {
  display: none; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

sup {
  vertical-align: super; }

sub {
  vertical-align: sub; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--text-truncate--end {
  width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.bx--text-truncate--front {
  width: 100%;
  display: inline-block;
  direction: rtl;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

@font-face {
  font-family: 'ibm-plex-mono';
  font-style: normal;
  font-weight: 300;
  src: url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexMono-Light-Pi.woff2") format("woff2"), url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexMono-Light-Pi.woff") format("woff");
  unicode-range: "U+03C0, U+0E3F, U+2070, U+2074-2079, U+2080-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1, U+EBE3-EBE4, U+EBE6-EBE7, U+ECE0, U+EFCC"; }

@font-face {
  font-family: 'ibm-plex-mono';
  font-style: normal;
  font-weight: 300;
  src: url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexMono-Light-Latin3.woff2") format("woff2"), url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexMono-Light-Latin3.woff") format("woff");
  unicode-range: "U+0102-0103, U+1EA0-1EF9, U+20AB"; }

@font-face {
  font-family: 'ibm-plex-mono';
  font-style: normal;
  font-weight: 300;
  src: url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexMono-Light-Latin2.woff2") format("woff2"), url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexMono-Light-Latin2.woff") format("woff");
  unicode-range: "U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02"; }

@font-face {
  font-family: 'ibm-plex-mono';
  font-style: normal;
  font-weight: 300;
  src: url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexMono-Light-Latin1.woff2") format("woff2"), url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexMono-Light-Latin1.woff") format("woff");
  unicode-range: "U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+20AC, U+2122, U+2212, U+FB01-FB02"; }

@font-face {
  font-family: 'ibm-plex-mono';
  font-style: normal;
  font-weight: 400;
  src: url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexMono-Regular-Pi.woff2") format("woff2"), url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexMono-Regular-Pi.woff") format("woff");
  unicode-range: "U+03C0, U+0E3F, U+2070, U+2074-2079, U+2080-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1, U+EBE3-EBE4, U+EBE6-EBE7, U+ECE0, U+EFCC"; }

@font-face {
  font-family: 'ibm-plex-mono';
  font-style: normal;
  font-weight: 400;
  src: url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexMono-Regular-Latin3.woff2") format("woff2"), url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexMono-Regular-Latin3.woff") format("woff");
  unicode-range: "U+0102-0103, U+1EA0-1EF9, U+20AB"; }

@font-face {
  font-family: 'ibm-plex-mono';
  font-style: normal;
  font-weight: 400;
  src: url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexMono-Regular-Latin2.woff2") format("woff2"), url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexMono-Regular-Latin2.woff") format("woff");
  unicode-range: "U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02"; }

@font-face {
  font-family: 'ibm-plex-mono';
  font-style: normal;
  font-weight: 400;
  src: url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexMono-Regular-Latin1.woff2") format("woff2"), url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexMono-Regular-Latin1.woff") format("woff");
  unicode-range: "U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+20AC, U+2122, U+2212, U+FB01-FB02"; }

@font-face {
  font-family: 'ibm-plex-mono';
  font-style: normal;
  font-weight: 600;
  src: url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexMono-SemiBold-Pi.woff2") format("woff2"), url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexMono-SemiBold-Pi.woff") format("woff");
  unicode-range: "U+03C0, U+0E3F, U+2070, U+2074-2079, U+2080-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1, U+EBE3-EBE4, U+EBE6-EBE7, U+ECE0, U+EFCC"; }

@font-face {
  font-family: 'ibm-plex-mono';
  font-style: normal;
  font-weight: 600;
  src: url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexMono-SemiBold-Latin3.woff2") format("woff2"), url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexMono-SemiBold-Latin3.woff") format("woff");
  unicode-range: "U+0102-0103, U+1EA0-1EF9, U+20AB"; }

@font-face {
  font-family: 'ibm-plex-mono';
  font-style: normal;
  font-weight: 600;
  src: url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexMono-SemiBold-Latin2.woff2") format("woff2"), url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexMono-SemiBold-Latin2.woff") format("woff");
  unicode-range: "U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02"; }

@font-face {
  font-family: 'ibm-plex-mono';
  font-style: normal;
  font-weight: 600;
  src: url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexMono-SemiBold-Latin1.woff2") format("woff2"), url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexMono-SemiBold-Latin1.woff") format("woff");
  unicode-range: "U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+20AC, U+2122, U+2212, U+FB01-FB02"; }

@font-face {
  font-family: 'ibm-plex-sans';
  font-style: normal;
  font-weight: 300;
  src: url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexSans-Light-Pi.woff2") format("woff2"), url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexSans-Light-Pi.woff") format("woff");
  unicode-range: "U+03C0, U+0E3F, U+2070, U+2074-2079, U+2080-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1, U+EBE3-EBE4, U+EBE6-EBE7, U+ECE0, U+EFCC"; }

@font-face {
  font-family: 'ibm-plex-sans';
  font-style: normal;
  font-weight: 300;
  src: url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexSans-Light-Latin3.woff2") format("woff2"), url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexSans-Light-Latin3.woff") format("woff");
  unicode-range: "U+0102-0103, U+1EA0-1EF9, U+20AB"; }

@font-face {
  font-family: 'ibm-plex-sans';
  font-style: normal;
  font-weight: 300;
  src: url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexSans-Light-Latin2.woff2") format("woff2"), url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexSans-Light-Latin2.woff") format("woff");
  unicode-range: "U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02"; }

@font-face {
  font-family: 'ibm-plex-sans';
  font-style: normal;
  font-weight: 300;
  src: url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexSans-Light-Latin1.woff2") format("woff2"), url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexSans-Light-Latin1.woff") format("woff");
  unicode-range: "U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+20AC, U+2122, U+2212, U+FB01-FB02"; }

@font-face {
  font-family: 'ibm-plex-sans';
  font-style: normal;
  font-weight: 400;
  src: url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexSans-Regular-Pi.woff2") format("woff2"), url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexSans-Regular-Pi.woff") format("woff");
  unicode-range: "U+03C0, U+0E3F, U+2070, U+2074-2079, U+2080-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1, U+EBE3-EBE4, U+EBE6-EBE7, U+ECE0, U+EFCC"; }

@font-face {
  font-family: 'ibm-plex-sans';
  font-style: normal;
  font-weight: 400;
  src: url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexSans-Regular-Latin3.woff2") format("woff2"), url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexSans-Regular-Latin3.woff") format("woff");
  unicode-range: "U+0102-0103, U+1EA0-1EF9, U+20AB"; }

@font-face {
  font-family: 'ibm-plex-sans';
  font-style: normal;
  font-weight: 400;
  src: url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexSans-Regular-Latin2.woff2") format("woff2"), url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexSans-Regular-Latin2.woff") format("woff");
  unicode-range: "U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02"; }

@font-face {
  font-family: 'ibm-plex-sans';
  font-style: normal;
  font-weight: 400;
  src: url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexSans-Regular-Latin1.woff2") format("woff2"), url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexSans-Regular-Latin1.woff") format("woff");
  unicode-range: "U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+20AC, U+2122, U+2212, U+FB01-FB02"; }

@font-face {
  font-family: 'ibm-plex-sans';
  font-style: normal;
  font-weight: 600;
  src: url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexSans-SemiBold-Pi.woff2") format("woff2"), url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexSans-SemiBold-Pi.woff") format("woff");
  unicode-range: "U+03C0, U+0E3F, U+2070, U+2074-2079, U+2080-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1, U+EBE3-EBE4, U+EBE6-EBE7, U+ECE0, U+EFCC"; }

@font-face {
  font-family: 'ibm-plex-sans';
  font-style: normal;
  font-weight: 600;
  src: url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexSans-SemiBold-Latin3.woff2") format("woff2"), url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexSans-SemiBold-Latin3.woff") format("woff");
  unicode-range: "U+0102-0103, U+1EA0-1EF9, U+20AB"; }

@font-face {
  font-family: 'ibm-plex-sans';
  font-style: normal;
  font-weight: 600;
  src: url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexSans-SemiBold-Latin2.woff2") format("woff2"), url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexSans-SemiBold-Latin2.woff") format("woff");
  unicode-range: "U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02"; }

@font-face {
  font-family: 'ibm-plex-sans';
  font-style: normal;
  font-weight: 600;
  src: url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexSans-SemiBold-Latin1.woff2") format("woff2"), url("https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexSans-SemiBold-Latin1.woff") format("woff");
  unicode-range: "U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+20AC, U+2122, U+2212, U+FB01-FB02"; }

.bx--visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  visibility: visible;
  white-space: nowrap; }

.bx--body {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  color: #152934;
  background-color: #f4f7fb;
  line-height: 1; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

body {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  color: #152934;
  line-height: 1; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--type-giga {
  font-size: 4.75rem;
  line-height: 1.25;
  font-weight: 300; }

.bx--type-mega {
  font-size: 3.375rem;
  line-height: 1.25;
  font-weight: 300; }

.bx--type-omega {
  font-size: 0.75rem;
  line-height: 1.25;
  font-weight: 600; }

.bx--type-caption {
  font-size: 0.75rem;
  line-height: 1.5;
  font-weight: 400; }

.bx--type-legal {
  font-size: 0.6875rem;
  line-height: 1.5;
  font-weight: 400; }

.bx--type-caps {
  text-transform: uppercase; }

strong,
.bx--type-strong {
  font-weight: 700; }

p {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400; }

em {
  font-style: italic; }

a {
  color: #3d70b2; }

h1,
.bx--type-alpha {
  font-size: 2.25rem;
  line-height: 1.25;
  font-weight: 300; }

h2,
.bx--type-beta {
  font-size: 1.75rem;
  line-height: 1.25;
  font-weight: 300; }

h3,
.bx--type-gamma {
  font-size: 1.25rem;
  line-height: 1.25;
  font-weight: 300; }

h4,
.bx--type-delta {
  font-size: 1.125rem;
  line-height: 1.25;
  font-weight: 600; }

h5,
.bx--type-epsilon {
  font-size: 1rem;
  line-height: 1.25;
  font-weight: 600; }

h6,
.bx--type-zeta {
  font-size: 0.875rem;
  line-height: 1.25;
  font-weight: 600; }

.bx--grid {
  margin-left: 3%;
  margin-right: 3%;
  padding-left: 5px;
  padding-right: 5px; }
  @media (min-width: 576px) {
    .bx--grid {
      margin-left: 5%;
      margin-right: 5%;
      padding-left: 10px;
      padding-right: 10px; } }
  @media (min-width: 1600px) {
    .bx--grid {
      margin: 0 auto; } }
  .bx--grid.max {
    max-width: 1600px; }

.bx--row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px; }
  @media (min-width: 576px) {
    .bx--row {
      margin: 0 -10px; } }

[class*='bx--col'] {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding: 0 5px; }
  @media (min-width: 576px) {
    [class*='bx--col'] {
      padding: 0 10px; } }

.bx--col-xs-1 {
  flex-basis: 8.33333%; }

.bx--col-xs-2 {
  flex-basis: 16.66667%; }

.bx--col-xs-3 {
  flex-basis: 25%; }

.bx--col-xs-4 {
  flex-basis: 33.33333%; }

.bx--col-xs-5 {
  flex-basis: 41.66667%; }

.bx--col-xs-6 {
  flex-basis: 50%; }

.bx--col-xs-7 {
  flex-basis: 58.33333%; }

.bx--col-xs-8 {
  flex-basis: 66.66667%; }

.bx--col-xs-9 {
  flex-basis: 75%; }

.bx--col-xs-10 {
  flex-basis: 83.33333%; }

.bx--col-xs-11 {
  flex-basis: 91.66667%; }

.bx--col-xs-12 {
  flex-basis: 100%; }

@media (min-width: 576px) {
  .bx--col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .bx--col-sm-1 {
    flex-basis: 8.33333%; }
  .bx--col-sm-2 {
    flex-basis: 16.66667%; }
  .bx--col-sm-3 {
    flex-basis: 25%; }
  .bx--col-sm-4 {
    flex-basis: 33.33333%; }
  .bx--col-sm-5 {
    flex-basis: 41.66667%; }
  .bx--col-sm-6 {
    flex-basis: 50%; }
  .bx--col-sm-7 {
    flex-basis: 58.33333%; }
  .bx--col-sm-8 {
    flex-basis: 66.66667%; }
  .bx--col-sm-9 {
    flex-basis: 75%; }
  .bx--col-sm-10 {
    flex-basis: 83.33333%; }
  .bx--col-sm-11 {
    flex-basis: 91.66667%; }
  .bx--col-sm-12 {
    flex-basis: 100%; } }

@media (min-width: 768px) {
  .bx--col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .bx--col-md-1 {
    flex-basis: 8.33333%; }
  .bx--col-md-2 {
    flex-basis: 16.66667%; }
  .bx--col-md-3 {
    flex-basis: 25%; }
  .bx--col-md-4 {
    flex-basis: 33.33333%; }
  .bx--col-md-5 {
    flex-basis: 41.66667%; }
  .bx--col-md-6 {
    flex-basis: 50%; }
  .bx--col-md-7 {
    flex-basis: 58.33333%; }
  .bx--col-md-8 {
    flex-basis: 66.66667%; }
  .bx--col-md-9 {
    flex-basis: 75%; }
  .bx--col-md-10 {
    flex-basis: 83.33333%; }
  .bx--col-md-11 {
    flex-basis: 91.66667%; }
  .bx--col-md-12 {
    flex-basis: 100%; } }

@media (min-width: 992px) {
  .bx--col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .bx--col-lg-1 {
    flex-basis: 8.33333%; }
  .bx--col-lg-2 {
    flex-basis: 16.66667%; }
  .bx--col-lg-3 {
    flex-basis: 25%; }
  .bx--col-lg-4 {
    flex-basis: 33.33333%; }
  .bx--col-lg-5 {
    flex-basis: 41.66667%; }
  .bx--col-lg-6 {
    flex-basis: 50%; }
  .bx--col-lg-7 {
    flex-basis: 58.33333%; }
  .bx--col-lg-8 {
    flex-basis: 66.66667%; }
  .bx--col-lg-9 {
    flex-basis: 75%; }
  .bx--col-lg-10 {
    flex-basis: 83.33333%; }
  .bx--col-lg-11 {
    flex-basis: 91.66667%; }
  .bx--col-lg-12 {
    flex-basis: 100%; } }

@media (min-width: 1200px) {
  .bx--col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .bx--col-xl-1 {
    flex-basis: 8.33333%; }
  .bx--col-xl-2 {
    flex-basis: 16.66667%; }
  .bx--col-xl-3 {
    flex-basis: 25%; }
  .bx--col-xl-4 {
    flex-basis: 33.33333%; }
  .bx--col-xl-5 {
    flex-basis: 41.66667%; }
  .bx--col-xl-6 {
    flex-basis: 50%; }
  .bx--col-xl-7 {
    flex-basis: 58.33333%; }
  .bx--col-xl-8 {
    flex-basis: 66.66667%; }
  .bx--col-xl-9 {
    flex-basis: 75%; }
  .bx--col-xl-10 {
    flex-basis: 83.33333%; }
  .bx--col-xl-11 {
    flex-basis: 91.66667%; }
  .bx--col-xl-12 {
    flex-basis: 100%; } }

@media (min-width: 1600px) {
  .bx--col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .bx--col-xxl-1 {
    flex-basis: 8.33333%; }
  .bx--col-xxl-2 {
    flex-basis: 16.66667%; }
  .bx--col-xxl-3 {
    flex-basis: 25%; }
  .bx--col-xxl-4 {
    flex-basis: 33.33333%; }
  .bx--col-xxl-5 {
    flex-basis: 41.66667%; }
  .bx--col-xxl-6 {
    flex-basis: 50%; }
  .bx--col-xxl-7 {
    flex-basis: 58.33333%; }
  .bx--col-xxl-8 {
    flex-basis: 66.66667%; }
  .bx--col-xxl-9 {
    flex-basis: 75%; }
  .bx--col-xxl-10 {
    flex-basis: 83.33333%; }
  .bx--col-xxl-11 {
    flex-basis: 91.66667%; }
  .bx--col-xxl-12 {
    flex-basis: 100%; } }

.bx--col-xs,
.bx--col-sm,
.bx--col-md,
.bx--col-lg {
  flex-basis: 0;
  flex: 1;
  flex-grow: 1;
  max-width: 100%; }

/**
 * We flag this variable as true if someone uses the globals/scss/styles.scss
 * entry-point. This allows us to collect all the messages and display them at
 * the end of the file instead of bringing it up per-component.
 *
 * If a consumer instead gets the components by importing the partial directly,
 * this variable _will not_ be set to true, so the deprecation message will be
 * displayed after the @import.
 */
/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

button.bx--btn {
  display: inline-block; }

button.bx--btn::-moz-focus-inner {
  padding: 0;
  border: 0; }

button.bx--btn .bx--btn__icon {
  position: relative;
  vertical-align: middle;
  top: -1px; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    button.bx--btn .bx--btn__icon {
      top: 0; } }

.bx--btn {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-size: 0.875rem;
  font-weight: 600;
  height: 2.5rem;
  padding: 0 1rem;
  border-radius: 0;
  text-align: center;
  text-decoration: none;
  transition-duration: 250ms;
  transition-timing-function: ease-in;
  white-space: nowrap;
  line-height: 16px; }
  .bx--btn:disabled {
    cursor: not-allowed;
    opacity: 0.5; }
  .bx--btn .bx--btn__icon {
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
    transition-duration: 250ms;
    transition-timing-function: ease-in; }

.bx--btn--primary {
  background-color: #3d70b2;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  color: #fff; }
  .bx--btn--primary:focus, .bx--btn--primary:hover {
    background-color: #30588c; }
  .bx--btn--primary:focus {
    border: 2px solid #f4f7fb;
    outline: 2px solid #30588c; }
  .bx--btn--primary:disabled:hover, .bx--btn--primary:disabled:focus {
    background-color: #3d70b2; }
  .bx--btn--primary:active {
    background-color: #234066; }
  .bx--btn--primary .bx--btn__icon {
    fill: #fff; }

.bx--btn--secondary {
  background-color: transparent;
  border-width: 2px;
  border-style: solid;
  border-color: #3d70b2;
  color: #3d70b2; }
  .bx--btn--secondary:focus, .bx--btn--secondary:hover {
    background-color: #3d70b2; }
  .bx--btn--secondary:focus {
    border: 2px solid #f4f7fb;
    outline: 2px solid #3d70b2; }
  .bx--btn--secondary:disabled:hover, .bx--btn--secondary:disabled:focus {
    background-color: transparent; }
  .bx--btn--secondary:active {
    background-color: rgba(0, 0, 0, 0); }
  .bx--btn--secondary .bx--btn__icon {
    fill: #3d70b2; }
  .bx--btn--secondary:hover, .bx--btn--secondary:focus {
    color: #fff; }
  .bx--btn--secondary:active {
    color: #3d70b2; }
  .bx--btn--secondary:hover > .bx--btn__icon,
  .bx--btn--secondary:focus > .bx--btn__icon {
    fill: #fff; }
  .bx--btn--secondary:hover:disabled, .bx--btn--secondary:focus:disabled {
    color: #3d70b2; }

.bx--btn--tertiary {
  background-color: transparent;
  border-width: 2px;
  border-style: solid;
  border-color: #5a6872;
  color: #5a6872; }
  .bx--btn--tertiary:focus, .bx--btn--tertiary:hover {
    background-color: #5a6872; }
  .bx--btn--tertiary:focus {
    border: 2px solid #f4f7fb;
    outline: 2px solid #5a6872; }
  .bx--btn--tertiary:disabled:hover, .bx--btn--tertiary:disabled:focus {
    background-color: transparent; }
  .bx--btn--tertiary:active {
    background-color: rgba(0, 0, 0, 0); }
  .bx--btn--tertiary .bx--btn__icon {
    fill: #5a6872; }
  .bx--btn--tertiary:hover, .bx--btn--tertiary:focus {
    color: #fff; }
  .bx--btn--tertiary:active {
    color: #5a6872; }
  .bx--btn--tertiary:hover:disabled, .bx--btn--tertiary:focus:disabled {
    color: #5a6872; }
  .bx--btn--tertiary:hover > .bx--btn__icon,
  .bx--btn--tertiary:focus > .bx--btn__icon {
    fill: #fff; }

.bx--btn--ghost {
  background-color: transparent;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  color: #3d70b2; }
  .bx--btn--ghost:focus, .bx--btn--ghost:hover {
    background-color: #3d70b2; }
  .bx--btn--ghost:focus {
    border: 2px solid #f4f7fb;
    outline: 2px solid #3d70b2; }
  .bx--btn--ghost:disabled:hover, .bx--btn--ghost:disabled:focus {
    background-color: transparent; }
  .bx--btn--ghost:active {
    background-color: rgba(0, 0, 0, 0); }
  .bx--btn--ghost .bx--btn__icon {
    fill: #3d70b2; }
  .bx--btn--ghost:hover, .bx--btn--ghost:focus {
    color: #fff; }
    .bx--btn--ghost:hover .bx--btn__icon, .bx--btn--ghost:focus .bx--btn__icon {
      fill: #fff; }
  .bx--btn--ghost .bx--btn__icon {
    margin-left: 0.5rem; }
  .bx--btn--ghost:hover:disabled, .bx--btn--ghost:focus:disabled {
    color: #3d70b2; }
    .bx--btn--ghost:hover:disabled .bx--btn__icon, .bx--btn--ghost:focus:disabled .bx--btn__icon {
      fill: #3d70b2; }

.bx--btn--danger {
  background-color: transparent;
  border-width: 2px;
  border-style: solid;
  border-color: #e0182d;
  color: #e0182d; }
  .bx--btn--danger:focus, .bx--btn--danger:hover {
    background-color: #e0182d; }
  .bx--btn--danger:focus {
    border: 2px solid #f4f7fb;
    outline: 2px solid #e0182d; }
  .bx--btn--danger:disabled:hover, .bx--btn--danger:disabled:focus {
    background-color: transparent; }
  .bx--btn--danger:active {
    background-color: rgba(0, 0, 0, 0); }
  .bx--btn--danger .bx--btn__icon {
    fill: #e0182d; }
  .bx--btn--danger:hover {
    color: #fff;
    border: 2px solid transparent; }
  .bx--btn--danger:focus {
    color: #fff; }
  .bx--btn--danger:active {
    color: #e0182d; }
  .bx--btn--danger:hover:disabled, .bx--btn--danger:focus:disabled {
    color: #e0182d;
    border: 2px solid #e0182d; }
  .bx--btn--danger:hover > .bx--btn__icon,
  .bx--btn--danger:focus > .bx--btn__icon {
    fill: #fff; }

.bx--btn--danger--primary {
  background-color: #e0182d;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  color: #fff; }
  .bx--btn--danger--primary:focus, .bx--btn--danger--primary:hover {
    background-color: #b21324; }
  .bx--btn--danger--primary:focus {
    border: 2px solid #f4f7fb;
    outline: 2px solid #b21324; }
  .bx--btn--danger--primary:disabled:hover, .bx--btn--danger--primary:disabled:focus {
    background-color: #e0182d; }
  .bx--btn--danger--primary:active {
    background-color: #840e1a; }
  .bx--btn--danger--primary .bx--btn__icon {
    fill: #fff; }
  .bx--btn--danger--primary:hover:disabled, .bx--btn--danger--primary:focus:disabled {
    color: #fff;
    border: 2px solid #e0182d; }

.bx--btn--sm {
  letter-spacing: 0;
  height: 2rem;
  padding: 0 0.5rem; }

.bx--btn--secondary + .bx--btn--primary,
.bx--btn--tertiary + .bx--btn--danger--primary {
  margin-left: 1rem; }

.bx--btn.bx--skeleton {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: rgba(61, 112, 178, 0.1);
  width: 9.375rem; }
  .bx--btn.bx--skeleton:hover, .bx--btn.bx--skeleton:focus, .bx--btn.bx--skeleton:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--btn.bx--skeleton:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    background: rgba(61, 112, 178, 0.1);
    animation: 3000ms ease-in-out skeleton infinite; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--btn--copy {
  position: relative;
  overflow: visible; }
  .bx--btn--copy .bx--btn__icon {
    margin-left: 0.3125rem; }

.bx--btn--copy__feedback {
  position: absolute;
  display: none;
  top: 1.2rem;
  left: 50%; }
  .bx--btn--copy__feedback:focus {
    border: 2px solid red; }
  .bx--btn--copy__feedback:before {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    font-size: 0.75rem;
    top: 1.1rem;
    padding: 0.25rem;
    color: #fff;
    content: attr(data-feedback);
    transform: translateX(-50%);
    white-space: nowrap;
    pointer-events: none;
    border-radius: 4px;
    font-weight: 400;
    z-index: 2; }
  .bx--btn--copy__feedback:after {
    top: 0.85rem;
    width: 0.6rem;
    height: 0.6rem;
    left: -0.3rem;
    border-right: 1px solid #272d33;
    border-bottom: 1px solid #272d33;
    content: '';
    transform: rotate(-135deg);
    z-index: 1; }
  .bx--btn--copy__feedback:before, .bx--btn--copy__feedback:after {
    position: absolute;
    display: block;
    background: #272d33; }
  .bx--btn--copy__feedback--displayed {
    display: inline-flex; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--fieldset {
  margin-bottom: 2rem; }

.bx--form-item {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start; }

.bx--form-item--light input,
.bx--form-item--light input[type='number'] {
  background: #fff !important; }

.bx--label {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: 0.5rem; }

.bx--label .bx--tooltip__trigger {
  font-size: 0.875rem; }

.bx--label--disabled {
  opacity: 0.5; }

.bx--label.bx--skeleton {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: rgba(61, 112, 178, 0.1);
  width: 4.6875rem;
  height: 0.875rem; }
  .bx--label.bx--skeleton:hover, .bx--label.bx--skeleton:focus, .bx--label.bx--skeleton:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--label.bx--skeleton:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    background: rgba(61, 112, 178, 0.1);
    animation: 3000ms ease-in-out skeleton infinite; }

input[data-invalid],
textarea[data-invalid],
select[data-invalid] {
  box-shadow: 0 2px 0px 0px #e0182d; }
  input[data-invalid] ~ .bx--form-requirement,
  textarea[data-invalid] ~ .bx--form-requirement,
  select[data-invalid] ~ .bx--form-requirement {
    max-height: 12.5rem;
    display: block; }

input:not(output):not([data-invalid]):-moz-ui-invalid {
  box-shadow: none; }

.bx--form-requirement {
  font-size: 0.75rem;
  margin: 0.75rem 0 0;
  max-height: 0;
  overflow: hidden;
  font-weight: 600;
  line-height: 1.5;
  display: none; }
  .bx--form-requirement::before {
    content: '*';
    display: inline-block;
    color: #e0182d; }

.bx--form__helper-text {
  font-size: 0.75rem;
  color: #5a6872;
  order: 1;
  line-height: 1.5;
  z-index: 0;
  max-height: 3rem;
  opacity: 1;
  margin-bottom: 0.5rem; }

@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes rotate-end-p1 {
  100% {
    transform: rotate(360deg); } }

@keyframes rotate-end-p2 {
  100% {
    transform: rotate(-360deg); } }

/* Stroke animations */
@keyframes init-stroke {
  0% {
    stroke-dashoffset: 240; }
  100% {
    stroke-dashoffset: 40; } }

@keyframes stroke-end {
  0% {
    stroke-dashoffset: 40; }
  100% {
    stroke-dashoffset: 240; } }

.bx--loading {
  animation-name: rotate;
  animation-duration: 500ms;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  width: 10.5rem;
  height: 10.5rem; }
  .bx--loading svg circle {
    animation-name: init-stroke;
    animation-duration: 1000ms;
    animation-timing-function: cubic-bezier(0.5, 0, 0.1, 1); }

.bx--loading__svg {
  fill: transparent;
  stroke: #6eedd8;
  stroke-width: 7;
  stroke-linecap: butt;
  stroke-dasharray: 240;
  stroke-dashoffset: 40; }

.bx--loading--stop {
  animation: rotate-end-p1 700ms cubic-bezier(0, 0, 0.25, 1) forwards, rotate-end-p2 700ms cubic-bezier(0, 0, 0.25, 1) 700ms forwards; }
  .bx--loading--stop svg circle {
    animation-name: stroke-end;
    animation-duration: 700ms;
    animation-timing-function: cubic-bezier(0, 0, 0.25, 1);
    animation-delay: 700ms;
    animation-fill-mode: forwards; }

.bx--loading--small {
  width: 2rem;
  height: 2rem; }
  .bx--loading--small .bx--loading__svg {
    stroke: #5a6872; }

.bx--loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 2000ms cubic-bezier(0.5, 0, 0.1, 1);
  z-index: 8000; }

.bx--loading-overlay--stop {
  display: none; }

.bx--file {
  width: 100%; }

.bx--file-input {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  visibility: visible;
  white-space: nowrap; }

.bx--file-btn {
  display: inline-flex;
  margin: 0; }

.bx--label-description {
  font-size: 0.875rem;
  line-height: 1.5;
  color: #5a6872;
  margin-bottom: 1.5rem; }

.bx--file-container {
  display: block;
  width: 100%;
  margin-top: 1.5rem; }

.bx--file__selected-file {
  display: block;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 1.875rem;
  background-color: #fff;
  border: 1px solid #dfe3e6;
  padding: 0 1rem;
  margin-bottom: 1rem; }
  .bx--file__selected-file:last-child {
    margin-bottom: 0; }

.bx--file-filename {
  font-size: 0.75rem;
  display: block;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  display: inline-flex;
  align-items: center;
  color: #152934;
  margin-right: 1rem;
  height: 1.875rem; }

.bx--file__state-container {
  display: flex;
  align-items: center; }
  .bx--file__state-container .bx--loading {
    width: 1.125rem;
    height: 1.125rem;
    margin-right: 0.5rem; }
  .bx--file__state-container .bx--loading__svg {
    stroke: #5a6872; }

.bx--file__state-container .bx--file-close,
.bx--file__state-container .bx--file-complete {
  width: 1rem;
  height: 1rem;
  fill: #152934;
  cursor: pointer; }
  .bx--file__state-container .bx--file-close:focus,
  .bx--file__state-container .bx--file-complete:focus {
    outline: 1px solid #3d70b2; }

.bx--file__state-container .bx--file-close {
  fill: #5a6872; }

.bx--file__state-container .bx--file-complete {
  fill: #5aa700; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--form-item.bx--checkbox-wrapper {
  margin-bottom: 1rem; }
  .bx--form-item.bx--checkbox-wrapper:first-of-type {
    margin-top: 0.5rem; }
  .bx--form-item.bx--checkbox-wrapper:last-of-type {
    margin-bottom: 0; }

.bx--checkbox {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  visibility: visible;
  white-space: nowrap; }

.bx--checkbox-label {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding-left: 1.5rem;
  min-height: 1rem; }

.bx--checkbox-label::before {
  box-sizing: border-box;
  content: '';
  position: absolute;
  left: 0;
  top: calc(50% - 9px);
  height: 1.125rem;
  width: 1.125rem;
  border: 2px solid #5a6872;
  background-color: #fff; }

.bx--checkbox-label::after {
  content: '';
  width: 7px;
  height: 3px;
  background: none;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: scale(0) rotate(-45deg);
  position: absolute;
  left: 0.3125rem;
  top: 50%;
  margin-top: -0.1875rem; }

.bx--checkbox:checked + .bx--checkbox-label::before,
.bx--checkbox:indeterminate + .bx--checkbox-label::before,
.bx--checkbox-label[data-contained-checkbox-state='true']::before,
.bx--checkbox-label[data-contained-checkbox-state='mixed']::before {
  background-color: #3d70b2;
  border-color: #3d70b2; }

.bx--checkbox:checked + .bx--checkbox-label::after,
.bx--checkbox-label[data-contained-checkbox-state='true']::after {
  opacity: 1;
  transform: scale(1) rotate(-45deg); }

.bx--checkbox:not(:checked) + .bx--checkbox-label::after {
  opacity: 0;
  transform: scale(0) rotate(-45deg); }

.bx--checkbox:focus + .bx--checkbox-label::before,
.bx--checkbox-label__focus::before {
  box-shadow: 0 0 0 3px #7cc7ff;
  outline: 1px solid transparent; }

.bx--checkbox:indeterminate + .bx--checkbox-label::after,
.bx--checkbox-label[data-contained-checkbox-state='mixed']::after {
  transform: scale(1) rotate(0deg);
  border-left: 0 solid #fff;
  border-bottom: 2px solid #fff;
  opacity: 1;
  width: 0.625rem;
  margin-top: -0.25rem;
  left: 0.25rem; }

.bx--checkbox:disabled + .bx--checkbox-label,
.bx--checkbox:disabled ~ .bx--checkbox-label-text,
.bx--checkbox-label[data-contained-checkbox-disabled='true'] {
  opacity: 0.5;
  cursor: not-allowed; }

.bx--checkbox-appearance {
  position: absolute;
  left: 0;
  top: calc(50% - 9px);
  display: inline-block;
  height: 1.125rem;
  width: 1.125rem;
  margin-right: 0.5rem;
  background-color: #fff;
  border: 2px solid #5a6872;
  min-width: 1.125rem; }

.bx--checkbox:checked + .bx--checkbox-label .bx--checkbox-appearance {
  top: -0.0625rem; }

.bx--checkbox:checked + .bx--checkbox-appearance,
.bx--checkbox:checked + .bx--checkbox-label .bx--checkbox-appearance {
  display: flex;
  align-items: baseline;
  background-color: #3d70b2;
  border-color: #3d70b2; }

.bx--checkbox:focus + .bx--checkbox-label .bx--checkbox-appearance,
.bx--checkbox:focus + .bx--checkbox-appearance {
  box-shadow: 0 0 0 3px #7cc7ff;
  outline: 1px solid transparent; }

.bx--checkbox:disabled + .bx--checkbox-appearance {
  opacity: 0.5;
  cursor: not-allowed; }

.bx--checkbox-checkmark {
  display: none;
  fill: #fff;
  width: 100%;
  height: 100%; }

.bx--checkbox:checked + .bx--checkbox-appearance .bx--checkbox-checkmark,
.bx--checkbox:checked + .bx--checkbox-label .bx--checkbox-appearance .bx--checkbox-checkmark {
  display: block; }

@-moz-document url-prefix() {
  .bx--checkbox:checked + .bx--checkbox-appearance .bx--checkbox-checkmark,
  .bx--checkbox:checked + .bx--checkbox-label .bx--checkbox-appearance .bx--checkbox-checkmark {
    stroke: #3d70b2; } }

.bx--checkbox-label.bx--skeleton {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: rgba(61, 112, 178, 0.1);
  width: 6.25rem;
  height: 1.125rem; }
  .bx--checkbox-label.bx--skeleton:hover, .bx--checkbox-label.bx--skeleton:focus, .bx--checkbox-label.bx--skeleton:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--checkbox-label.bx--skeleton:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    background: rgba(61, 112, 178, 0.1);
    animation: 3000ms ease-in-out skeleton infinite; }
  .bx--checkbox-label.bx--skeleton:after, .bx--checkbox-label.bx--skeleton:before {
    border: none; }

.bx--text-truncate--end {
  width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.bx--text-truncate--front {
  width: 100%;
  display: inline-block;
  direction: rtl;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.bx--text-truncate--end {
  width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.bx--text-truncate--front {
  width: 100%;
  display: inline-block;
  direction: rtl;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.bx--list-box {
  position: relative;
  width: 100%;
  height: 2.5rem;
  max-height: 2.5rem;
  background-color: #f4f7fb;
  border: none;
  box-shadow: 0 1px 0 0 #5a6872;
  order: 1;
  cursor: pointer;
  color: #152934; }

.bx--list-box--light {
  background-color: #fff; }

.bx--list-box--disabled {
  opacity: 0.5; }

.bx--list-box--disabled,
.bx--list-box--disabled .bx--list-box__field,
.bx--list-box--disabled .bx--list-box__menu-icon {
  cursor: not-allowed; }

.bx--list-box--disabled .bx--list-box__menu-item:hover,
.bx--list-box--disabled .bx--list-box__menu-item--highlighted {
  background-color: transparent;
  text-decoration: none; }

.bx--list-box.bx--list-box--inline {
  background-color: inherit;
  width: auto;
  height: 2rem;
  box-shadow: none; }

.bx--list-box--inline .bx--list-box__label {
  color: #3d70b2; }

.bx--list-box--inline .bx--list-box__field {
  padding: 0 0 0 0.625rem; }

.bx--list-box--inline .bx--list-box__menu-icon {
  position: static;
  padding: 0 0.625rem 0 0.75rem; }

.bx--list-box--inline > .bx--list-box__menu {
  top: 2rem;
  width: 18.75rem; }

.bx--list-box--inline > .bx--list-box__field[aria-expanded='true'] ~ .bx--list-box__menu {
  outline: 1px solid #3d70b2;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1); }

.bx--list-box--inline > .bx--list-box__field[aria-expanded='true'],
.bx--list-box.bx--list-box--inline > .bx--list-box__field:focus {
  outline: none;
  box-shadow: none; }

.bx--list-box__field {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0;
  display: inline-block;
  background: none;
  appearance: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  width: 100%;
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 100%;
  padding: 0 1rem;
  cursor: pointer; }
  .bx--list-box__field::-moz-focus-inner {
    border: 0; }

.bx--list-box__field:focus,
.bx--list-box__field[aria-expanded='true'] {
  outline: none;
  box-shadow: 0 2px 0 0 #3d70b2; }

.bx--list-box__field[disabled] {
  outline: none;
  opacity: 0.5; }

.bx--list-box__label {
  font-size: 0.875rem;
  color: #152934;
  font-weight: 600;
  user-select: none; }

.bx--list-box__menu-icon {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  padding: 0 1rem;
  transition: transform 200ms cubic-bezier(0.5, 0, 0.1, 1);
  cursor: pointer; }

.bx--list-box__menu-icon > svg {
  fill: #3d70b2;
  height: 100%; }

.bx--list-box__menu-icon--open {
  transform: rotate(180deg); }

.bx--list-box__selection {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 1.625rem;
  bottom: 0;
  height: 2.5rem;
  padding: 0 1rem;
  cursor: pointer;
  user-select: none;
  transition: background-color 200ms cubic-bezier(0.5, 0, 0.1, 1); }

.bx--list-box__selection > svg {
  fill: #5a6872;
  height: 100%; }

.bx--list-box__selection:focus {
  outline: 1px solid #3d70b2; }

.bx--list-box__selection--multi {
  font-size: 0.75rem;
  position: static;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background-color: #3d70b2;
  height: 1.125rem;
  color: white;
  line-height: 0;
  padding: 0.3125rem;
  margin-right: 0.625rem;
  border-radius: 0.8125rem; }

.bx--list-box__selection--multi > svg {
  fill: white;
  width: 0.5rem;
  height: 0.5rem;
  margin-left: 0.3125rem; }

.bx--list-box__selection--multi:focus,
.bx--list-box__selection--multi:hover {
  background-color: #30588c;
  outline: none; }

.bx--list-box__menu {
  box-shadow: 0px 3px 3px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 0;
  right: 0;
  top: 2.5rem;
  width: 100%;
  background-color: #fff;
  max-height: 7.5rem;
  overflow-y: auto;
  z-index: 7000; }

.bx--list-box__menu-item {
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  height: 2.5rem;
  padding: 0 1rem;
  cursor: pointer;
  user-select: none; }

.bx--list-box__menu-item:hover,
.bx--list-box__menu-item--highlighted {
  background-color: rgba(85, 150, 230, 0.1);
  outline: 1px solid transparent;
  text-decoration: underline;
  color: #152934; }

.bx--list-box__menu-item > .bx--form-item.bx--checkbox-wrapper {
  margin: 0; }

.bx--list-box input[role='combobox'] {
  background-color: inherit;
  font-weight: 600;
  outline-offset: 0;
  min-width: 0; }

.bx--list-box input[role='combobox']::placeholder {
  font-weight: 400; }

.bx--list-box--disabled input[role='combobox'] {
  opacity: 1; }

.bx--combo-box > .bx--list-box__field {
  padding: 0; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--radio-button-group {
  display: flex;
  align-items: center;
  margin-top: 0.5rem; }

.bx--radio-button {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  visibility: visible;
  white-space: nowrap; }

.bx--radio-button__label {
  font-size: 0.875rem;
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 1rem; }

.bx--radio-button__appearance {
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #5a6872;
  flex-shrink: 0;
  height: 1.125rem;
  width: 1.125rem;
  margin-right: 0.5rem; }

.bx--radio-button:checked + .bx--radio-button__label .bx--radio-button__appearance {
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: #3d70b2; }
  .bx--radio-button:checked + .bx--radio-button__label .bx--radio-button__appearance:before {
    content: '';
    display: inline-block;
    position: relative;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: #3d70b2; }

.bx--radio-button:disabled + .bx--radio-button__label {
  opacity: 0.5;
  cursor: not-allowed; }

.bx--radio-button:focus + .bx--radio-button__label .bx--radio-button__appearance {
  box-shadow: 0 0 0 3px #7cc7ff;
  outline: 1px solid transparent; }

.bx--radio-button__label.bx--skeleton {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: rgba(61, 112, 178, 0.1);
  width: 6.25rem;
  height: 1.125rem; }
  .bx--radio-button__label.bx--skeleton:hover, .bx--radio-button__label.bx--skeleton:focus, .bx--radio-button__label.bx--skeleton:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--radio-button__label.bx--skeleton:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    background: rgba(61, 112, 178, 0.1);
    animation: 3000ms ease-in-out skeleton infinite; }

.bx--radio-button__label.bx--skeleton .bx--radio-button__appearance {
  display: none; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--toggle {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  visibility: visible;
  white-space: nowrap; }

.bx--toggle__label {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  position: relative;
  display: flex;
  align-items: center;
  transition: 250ms cubic-bezier(0.5, 0, 0.1, 1);
  cursor: pointer;
  margin: 1rem 0; }

.bx--toggle__appearance {
  position: relative;
  width: 3rem; }
  .bx--toggle__appearance:before {
    position: absolute;
    display: block;
    content: '';
    width: 100%;
    height: 0.25rem;
    top: -2px;
    background-color: #8897a2;
    transition: 250ms cubic-bezier(0.5, 0, 0.1, 1);
    cursor: pointer; }
  .bx--toggle__appearance:after {
    box-sizing: border-box;
    position: absolute;
    display: block;
    border: 2px solid #8897a2;
    cursor: pointer;
    top: -12px;
    width: 1.5rem;
    height: 1.5rem;
    background-color: #fff;
    border-radius: 50%;
    content: '';
    transition: 250ms cubic-bezier(0.5, 0, 0.1, 1); }

.bx--toggle--small + .bx--toggle__label {
  width: 2rem; }

.bx--toggle--small + .bx--toggle__label .bx--toggle__appearance {
  width: 2rem;
  height: 1rem; }
  .bx--toggle--small + .bx--toggle__label .bx--toggle__appearance:before {
    box-sizing: border-box;
    height: 1rem;
    width: 2rem;
    border-radius: 0.9375rem;
    background-color: transparent;
    border: 2px solid #8897a2;
    top: 0; }
  .bx--toggle--small + .bx--toggle__label .bx--toggle__appearance:after {
    width: 0.625rem;
    height: 0.625rem;
    background-color: #8897a2;
    border: none;
    top: 3px;
    left: 3px; }

.bx--toggle__check {
  fill: #5a6872;
  position: absolute;
  left: 6px;
  top: 6px;
  z-index: 1;
  transition: 250ms cubic-bezier(0.5, 0, 0.1, 1);
  transform: scale(0.2); }

.bx--toggle--small:checked + .bx--toggle__label .bx--toggle__check {
  fill: #3d70b2;
  transform: scale(1) translateX(16px); }

.bx--toggle__text--left,
.bx--toggle__text--right {
  font-size: 0.875rem;
  position: relative; }

.bx--toggle__text--left {
  margin-right: 0.5rem; }

.bx--toggle__text--right {
  margin-left: 0.5rem; }

.bx--toggle:checked + .bx--toggle__label .bx--toggle__appearance:before {
  background-color: #3d70b2; }

.bx--toggle:checked + .bx--toggle__label .bx--toggle__appearance:after {
  transform: translateX(24px);
  background-color: #3d70b2;
  box-shadow: none;
  border-color: #3d70b2; }

.bx--toggle--small:checked + .bx--toggle__label .bx--toggle__appearance:before {
  background-color: #3d70b2;
  border-color: #3d70b2; }

.bx--toggle--small:checked + .bx--toggle__label .bx--toggle__appearance:after {
  background-color: #fff;
  border-color: #fff;
  margin-left: 0px;
  transform: translateX(17px); }

.bx--toggle:focus + .bx--toggle__label .bx--toggle__appearance:before {
  outline: 1px solid transparent; }

.bx--toggle:focus + .bx--toggle__label .bx--toggle__appearance:after {
  box-shadow: 0 0 0 3px #7cc7ff;
  outline: 1px solid transparent; }

.bx--toggle--small:focus + .bx--toggle__label .bx--toggle__appearance:before {
  box-shadow: 0 0 0 3px #7cc7ff;
  outline: 1px solid transparent; }

.bx--toggle--small:focus + .bx--toggle__label .bx--toggle__appearance:after {
  outline: none;
  box-shadow: none; }

.bx--toggle:disabled + .bx--toggle__label {
  cursor: not-allowed;
  opacity: 0.5; }

.bx--toggle:disabled + .bx--toggle__label .bx--toggle__appearance:before, .bx--toggle:disabled + .bx--toggle__label .bx--toggle__appearance:after {
  cursor: not-allowed;
  transition: 250ms cubic-bezier(0.5, 0, 0.1, 1); }

.bx--toggle.bx--skeleton + .bx--toggle__label {
  cursor: default; }

.bx--toggle.bx--skeleton + .bx--toggle__label > .bx--toggle__appearance:before, .bx--toggle.bx--skeleton + .bx--toggle__label > .bx--toggle__appearance:after {
  background-color: #3d70b2;
  border-color: #3d70b2;
  cursor: default; }

.bx--toggle.bx--skeleton + .bx--toggle__label > .bx--toggle__text--left,
.bx--toggle.bx--skeleton + .bx--toggle__label > .bx--toggle__text--right {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: rgba(61, 112, 178, 0.1);
  width: 1.25rem;
  height: 0.75rem; }
  .bx--toggle.bx--skeleton + .bx--toggle__label > .bx--toggle__text--left:hover, .bx--toggle.bx--skeleton + .bx--toggle__label > .bx--toggle__text--left:focus, .bx--toggle.bx--skeleton + .bx--toggle__label > .bx--toggle__text--left:active,
  .bx--toggle.bx--skeleton + .bx--toggle__label > .bx--toggle__text--right:hover,
  .bx--toggle.bx--skeleton + .bx--toggle__label > .bx--toggle__text--right:focus,
  .bx--toggle.bx--skeleton + .bx--toggle__label > .bx--toggle__text--right:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--toggle.bx--skeleton + .bx--toggle__label > .bx--toggle__text--left:before,
  .bx--toggle.bx--skeleton + .bx--toggle__label > .bx--toggle__text--right:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    background: rgba(61, 112, 178, 0.1);
    animation: 3000ms ease-in-out skeleton infinite; }

.bx--toggle.bx--skeleton + .bx--toggle__label > .bx--toggle__appearance:before {
  background: transparent;
  border-color: #5a6872; }

.bx--toggle.bx--skeleton + .bx--toggle__label > .bx--toggle__appearance:after {
  background-color: #5a6872;
  border: none; }

.bx--text-truncate--end {
  width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.bx--text-truncate--front {
  width: 100%;
  display: inline-block;
  direction: rtl;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--text-truncate--end {
  width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.bx--text-truncate--front {
  width: 100%;
  display: inline-block;
  direction: rtl;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.bx--search {
  display: flex;
  position: relative;
  width: 100%; }

.bx--search .bx--label {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  visibility: visible;
  white-space: nowrap; }

.bx--search-input {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  appearance: none;
  border: none;
  background-color: #f4f7fb;
  color: #152934;
  font-weight: 600;
  padding: 0 2.5rem;
  text-overflow: ellipsis;
  width: 100%;
  order: 1; }
  .bx--search-input:focus {
    outline: none; }
  .bx--search-input:focus ~ .bx--search-magnifier {
    fill: #3d70b2; }
  .bx--search-input::placeholder {
    color: #5a6872;
    font-weight: 400; }
  .bx--search-input::-ms-clear {
    display: none; }

.bx--search--light .bx--search-input {
  background: #fff; }

.bx--search--sm .bx--search-input {
  font-size: 0.875rem;
  height: 2rem; }

.bx--search--lg .bx--search-input {
  font-size: 0.875rem;
  height: 2.5rem; }

.bx--search-magnifier,
.bx--search-close {
  position: absolute;
  height: 1rem;
  width: 1rem;
  top: calc(50% - 0.5rem); }

.bx--search-magnifier {
  left: 0.75rem;
  fill: #5a6872;
  z-index: 2; }

.bx--search-close {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0;
  display: inline-block;
  background: none;
  appearance: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  transition: opacity 250ms;
  fill: #5a6872;
  cursor: pointer;
  visibility: visible;
  opacity: 1; }
  .bx--search-close::-moz-focus-inner {
    border: 0; }

.bx--search-close:focus {
  outline: 1px solid #3d70b2;
  outline-offset: 2px; }

.bx--search-close {
  right: 0.75rem; }

.bx--search--lg .bx--search-close {
  right: 0.75rem; }

.bx--search-button {
  border: 0;
  transition: 250ms;
  height: 2.5rem;
  width: 2.5rem;
  min-width: 2.5rem;
  margin-left: 0.25rem;
  background-color: #fff;
  position: relative;
  padding: 0;
  outline: 1px solid transparent;
  order: 2; }

.bx--search-button svg {
  position: relative;
  top: -1px;
  box-sizing: border-box;
  vertical-align: middle;
  transition: 250ms;
  fill: #30588c;
  height: 1rem;
  width: 1rem; }

.bx--search-button:hover,
.bx--search-button:focus {
  cursor: pointer;
  background-color: #3d70b2;
  outline: 1px solid transparent; }

.bx--search-button:hover svg,
.bx--search-button:focus svg {
  fill: #fff; }

.bx--search-close--hidden {
  visibility: hidden;
  opacity: 0; }

.bx--search-view--hidden {
  display: none; }

.bx--search--lg.bx--skeleton .bx--search-input,
.bx--search--sm.bx--skeleton .bx--search-input {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: rgba(61, 112, 178, 0.1);
  width: 100%; }
  .bx--search--lg.bx--skeleton .bx--search-input:hover, .bx--search--lg.bx--skeleton .bx--search-input:focus, .bx--search--lg.bx--skeleton .bx--search-input:active,
  .bx--search--sm.bx--skeleton .bx--search-input:hover,
  .bx--search--sm.bx--skeleton .bx--search-input:focus,
  .bx--search--sm.bx--skeleton .bx--search-input:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--search--lg.bx--skeleton .bx--search-input:before,
  .bx--search--sm.bx--skeleton .bx--search-input:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    background: rgba(61, 112, 178, 0.1);
    animation: 3000ms ease-in-out skeleton infinite; }
  .bx--search--lg.bx--skeleton .bx--search-input::-webkit-input-placeholder,
  .bx--search--sm.bx--skeleton .bx--search-input::-webkit-input-placeholder {
    color: transparent; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--select {
  position: relative;
  display: flex;
  flex-direction: column; }

.bx--select-input {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  font-size: 0.875rem;
  height: 2.5rem;
  appearance: none;
  display: block;
  width: 100%;
  padding: 0 2.5rem 0 1rem;
  color: #152934;
  background-color: #f4f7fb;
  border: none;
  box-shadow: 0 1px 0 0 #5a6872;
  order: 2;
  border-radius: 0;
  cursor: pointer; }
  .bx--select-input::-ms-expand {
    display: none; }
  .bx--select-input ~ .bx--label {
    order: 1; }
  .bx--select-input:focus {
    outline: none;
    box-shadow: 0 2px 0 0 #3d70b2; }
  .bx--select-input[data-invalid], .bx--select-input[data-invalid]:focus {
    box-shadow: 0 2px 0 0 #e0182d; }
  .bx--select-input:focus ~ .bx--label {
    color: #3d70b2; }
  .bx--select-input[data-invalid]:focus ~ .bx--label {
    color: #e0182d; }
  .bx--select-input:disabled {
    opacity: 0.5;
    cursor: not-allowed; }
  .bx--select-input ~ .bx--form-requirement {
    order: 3;
    color: #e0182d;
    font-weight: 400;
    margin-top: 0.25rem; }
    .bx--select-input ~ .bx--form-requirement::before {
      display: none; }

.bx--select--light .bx--select-input {
  background: #fff; }

.bx--select__arrow {
  fill: #3d70b2;
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  width: 0.625rem;
  height: 0.3125rem;
  pointer-events: none; }

[data-invalid] ~ .bx--select__arrow {
  bottom: 2.25rem; }

.bx--select-optgroup,
.bx--select-option {
  color: #152934; }

.bx--select-option[disabled] {
  opacity: 0.5;
  cursor: not-allowed; }

@-moz-document url-prefix() {
  .bx--select-option {
    background-color: #fff;
    color: #152934; }
  .bx--select-optgroup {
    color: #152934; } }

.bx--select--inline {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .bx--select--inline .bx--label {
    white-space: nowrap;
    margin: 0 0.5rem 0 0;
    font-weight: 400; }
  .bx--select--inline .bx--select-input {
    background-color: transparent;
    color: #3d70b2;
    font-weight: 600;
    box-shadow: none; }
    .bx--select--inline .bx--select-input:focus {
      outline: 1px solid #3d70b2; }
    .bx--select--inline .bx--select-input:disabled ~ * {
      opacity: 0.5;
      cursor: not-allowed; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--text-input {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  font-size: 0.875rem;
  display: block;
  width: 100%;
  height: 2.5rem;
  min-width: 18.75rem;
  padding: 0 1rem;
  color: #152934;
  /* background-color: #f4f7fb; */
  background-color: #cfdbe659;
  margin-bottom: 25px; 
  border: none;
  box-shadow: 0 1px 0 0 #5a6872;
  order: 2; }
  .bx--text-input ~ .bx--label {
    order: 1; }
  .bx--text-input::-webkit-input-placeholder {
    color: #cdd1d4; }
  .bx--text-input:focus {
    outline: none;
    box-shadow: 0 2px 0 0 #3d70b2; }
  .bx--text-input[data-invalid], .bx--text-input[data-invalid]:focus {
    box-shadow: 0 2px 0 0 #e0182d; }
  .bx--text-input:focus ~ .bx--label {
    color: #3d70b2; }
  .bx--text-input[data-invalid]:focus + .bx--label {
    color: #e0182d; }
  .bx--text-input:disabled {
    opacity: 0.5;
    cursor: not-allowed; }
  .bx--text-input:disabled:hover {
    border: none; }
  .bx--text-input ~ .bx--form-requirement {
    order: 3;
    color: #e0182d;
    font-weight: 400;
    margin-top: 0.25rem; }
    .bx--text-input ~ .bx--form-requirement::before {
      display: none; }

.bx--text-input--light {
  background-color: #fff; }

.bx--text-input.bx--skeleton {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: rgba(61, 112, 178, 0.1);
  width: 100%; }
  .bx--text-input.bx--skeleton:hover, .bx--text-input.bx--skeleton:focus, .bx--text-input.bx--skeleton:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--text-input.bx--skeleton:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    background: rgba(61, 112, 178, 0.1);
    animation: 3000ms ease-in-out skeleton infinite; }
  .bx--text-input.bx--skeleton::-webkit-input-placeholder {
    color: transparent; }

.bx--text-input.bx--skeleton {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: rgba(61, 112, 178, 0.1);
  width: 100%; }
  .bx--text-input.bx--skeleton:hover, .bx--text-input.bx--skeleton:focus, .bx--text-input.bx--skeleton:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--text-input.bx--skeleton:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    background: rgba(61, 112, 178, 0.1);
    animation: 3000ms ease-in-out skeleton infinite; }
  .bx--text-input.bx--skeleton::-webkit-input-placeholder {
    color: transparent; }

.bx--text-input.bx--skeleton {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: rgba(61, 112, 178, 0.1);
  width: 100%; }
  .bx--text-input.bx--skeleton:hover, .bx--text-input.bx--skeleton:focus, .bx--text-input.bx--skeleton:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--text-input.bx--skeleton:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    background: rgba(61, 112, 178, 0.1);
    animation: 3000ms ease-in-out skeleton infinite; }
  .bx--text-input.bx--skeleton::-webkit-input-placeholder {
    color: transparent; }

.bx--text-input.bx--skeleton {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: rgba(61, 112, 178, 0.1);
  width: 100%; }
  .bx--text-input.bx--skeleton:hover, .bx--text-input.bx--skeleton:focus, .bx--text-input.bx--skeleton:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--text-input.bx--skeleton:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    background: rgba(61, 112, 178, 0.1);
    animation: 3000ms ease-in-out skeleton infinite; }
  .bx--text-input.bx--skeleton::-webkit-input-placeholder {
    color: transparent; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--text-area {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  font-size: 0.875rem;
  width: 100%;
  min-width: 10rem;
  padding: 1rem;
  color: #152934;
  /* background-color: #f4f7fb; */
  background: #cfdbe659;
  border: none;
  box-shadow: 0 1px 0 0 #5a6872;
  order: 2;
  resize: vertical; }
  .bx--text-area ~ .bx--label {
    order: 1; }
  .bx--text-area:focus {
    outline: none;
    box-shadow: 0 2px 0 0 #3d70b2; }
  .bx--text-area::-webkit-input-placeholder {
    color: #cdd1d4; }
  .bx--text-area:disabled {
    opacity: 0.5;
    cursor: not-allowed; }
  .bx--text-area:disabled:hover {
    border: 1px solid transparent; }
  .bx--text-area[data-invalid], .bx--text-area[data-invalid]:focus {
    box-shadow: 0 2px 0 0 #e0182d; }
  .bx--text-area:focus ~ .bx--label {
    color: #3d70b2; }
  .bx--text-area[data-invalid]:focus + .bx--label {
    color: #e0182d; }
  .bx--text-area ~ .bx--form-requirement {
    order: 3;
    color: #e0182d;
    font-weight: 400;
    margin-top: 0.25rem; }
    .bx--text-area ~ .bx--form-requirement::before {
      display: none; }

.bx--text-area--light {
  background: #fff; }

bx--text-area.bx--skeleton {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: rgba(61, 112, 178, 0.1);
  height: 6.25rem; }
  bx--text-area.bx--skeleton:hover, bx--text-area.bx--skeleton:focus, bx--text-area.bx--skeleton:active {
    border: none;
    outline: none;
    cursor: default; }
  bx--text-area.bx--skeleton:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    background: rgba(61, 112, 178, 0.1);
    animation: 3000ms ease-in-out skeleton infinite; }
  bx--text-area.bx--skeleton::-webkit-input-placeholder {
    color: transparent; }

bx--text-area.bx--skeleton {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: rgba(61, 112, 178, 0.1);
  height: 6.25rem; }
  bx--text-area.bx--skeleton:hover, bx--text-area.bx--skeleton:focus, bx--text-area.bx--skeleton:active {
    border: none;
    outline: none;
    cursor: default; }
  bx--text-area.bx--skeleton:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    background: rgba(61, 112, 178, 0.1);
    animation: 3000ms ease-in-out skeleton infinite; }
  bx--text-area.bx--skeleton::-webkit-input-placeholder {
    color: transparent; }

bx--text-area.bx--skeleton {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: rgba(61, 112, 178, 0.1);
  height: 6.25rem; }
  bx--text-area.bx--skeleton:hover, bx--text-area.bx--skeleton:focus, bx--text-area.bx--skeleton:active {
    border: none;
    outline: none;
    cursor: default; }
  bx--text-area.bx--skeleton:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    background: rgba(61, 112, 178, 0.1);
    animation: 3000ms ease-in-out skeleton infinite; }
  bx--text-area.bx--skeleton::-webkit-input-placeholder {
    color: transparent; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--number {
  display: flex;
  flex-direction: column;
  position: relative; }

.bx--number input[type='number'] {
  font-size: 0.875rem;
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  box-sizing: border-box;
  display: inline-flex;
  width: 100%;
  min-width: 9.375rem;
  padding-left: 1rem;
  padding-right: 2rem;
  font-weight: 300;
  height: 2.5rem;
  color: #152934;
  background-color: #f4f7fb;
  border: none;
  box-shadow: 0 1px 0 0 #5a6872;
  order: 2;
  border-radius: 0; }
  .bx--number input[type='number'] ~ .bx--label {
    order: 1; }
  .bx--number input[type='number']:focus {
    outline: none;
    box-shadow: 0 2px 0 0 #3d70b2; }
  .bx--number input[type='number']:focus ~ .bx--label {
    color: #3d70b2; }
  .bx--number input[type='number']:disabled {
    opacity: 0.5;
    cursor: not-allowed; }
  .bx--number input[type='number']:disabled ~ .bx--number__controls {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none; }
  .bx--number input[type='number'] ~ .bx--form-requirement {
    order: 3;
    color: #e0182d;
    font-weight: 400;
    margin-top: 0.25rem;
    overflow: visible; }
    .bx--number input[type='number'] ~ .bx--form-requirement::before {
      display: none; }

.bx--number input[type='number'] {
  appearance: textfield; }
  .bx--number input[type='number']::-ms-clear {
    display: none; }
  .bx--number input[type='number']::-webkit-inner-spin-button {
    appearance: none; }

.bx--number__controls {
  position: absolute;
  display: block;
  left: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: auto;
  right: 0.5rem;
  bottom: 0.625rem; }

.bx--number__control-btn {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0;
  display: inline-block;
  background: none;
  appearance: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 1.25rem;
  height: 0.625rem; }
  .bx--number__control-btn::-moz-focus-inner {
    border: 0; }
  .bx--number__control-btn:focus {
    outline: 1px solid #3d70b2; }
  .bx--number__control-btn:hover {
    cursor: pointer; }
  .bx--number__control-btn:hover svg {
    fill: #30588c; }

.bx--number__controls svg {
  fill: #3d70b2; }
  .bx--number__controls svg:hover {
    cursor: pointer;
    fill: #30588c; }

.bx--number[data-invalid] .bx--form-requirement {
  display: inline-block;
  max-height: 12.5rem; }

.bx--number[data-invalid] input[type='number'],
.bx--number[data-invalid] input[type='number']:focus {
  outline: none;
  box-shadow: 0 2px 0px 0px #e0182d; }

.bx--number[data-invalid] input[type='number']:focus ~ .bx--label {
  color: #e0182d; }

.bx--number[data-invalid] .bx--number__controls {
  bottom: 2rem; }

.bx--number--light input[type='number'] {
  background: #fff; }

.bx--number.bx--skeleton {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: rgba(61, 112, 178, 0.1);
  width: 100%;
  height: 2.5rem; }
  .bx--number.bx--skeleton:hover, .bx--number.bx--skeleton:focus, .bx--number.bx--skeleton:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--number.bx--skeleton:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    background: rgba(61, 112, 178, 0.1);
    animation: 3000ms ease-in-out skeleton infinite; }
  .bx--number.bx--skeleton input[type='number'] {
    display: none; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--text-truncate--end {
  width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.bx--text-truncate--front {
  width: 100%;
  display: inline-block;
  direction: rtl;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--link {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  font-size: 0.875rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
  color: #3d70b2;
  text-decoration: underline;
  transition: 250ms; }
  .bx--link:visited {
    color: #3d70b2; }
  .bx--link:active, .bx--link:hover, .bx--link:focus {
    color: #294c79; }
  .bx--link:focus {
    outline: 1px solid #3d70b2; }
  .bx--link[aria-disabled='true'] {
    opacity: 0.5;
    pointer-events: none; }

.bx--text-truncate--end {
  width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.bx--text-truncate--front {
  width: 100%;
  display: inline-block;
  direction: rtl;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.bx--list--nested,
.bx--list--unordered,
.bx--list--ordered {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  font-size: 0.875rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-left: 2rem;
  line-height: 1.5; }

.bx--list--unordered,
.bx--list--ordered {
  padding: 1rem; }

.bx--list__item {
  font-weight: 600;
  padding-left: 0.25rem; }

.bx--list--unordered > .bx--list__item {
  list-style-type: disc; }

.bx--list--ordered > .bx--list__item {
  list-style-type: decimal; }

.bx--list--nested {
  margin-left: 0.5rem; }

.bx--list--nested > .bx--list__item {
  list-style-type: none;
  font-weight: 400; }

.bx--list--nested > .bx--list__item:before {
  content: '-';
  padding-right: 0.25rem; }

.bx--text-truncate--end {
  width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.bx--text-truncate--front {
  width: 100%;
  display: inline-block;
  direction: rtl;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--text-truncate--end {
  width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.bx--text-truncate--front {
  width: 100%;
  display: inline-block;
  direction: rtl;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.bx--overflow-menu {
  position: relative;
  width: 1.25rem;
  height: 2.375rem;
  cursor: pointer; }
  .bx--overflow-menu:focus {
    outline: 1px solid transparent;
    box-shadow: 0 0 0 1px #3d70b2; }

.bx--overflow-menu__icon {
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  fill: #5a6872; }
  .bx--overflow-menu__icon:hover, .bx--overflow-menu__icon:focus {
    fill: #3d70b2; }

.bx--overflow-menu-options {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  display: none;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  z-index: 10000;
  background-color: #fff;
  border: 1px solid #dfe3e6;
  width: 11.25rem;
  list-style: none;
  margin-top: 0.25rem;
  padding: 0.25rem 0 0.5rem;
  left: -20px; }

.bx--overflow-menu-options--open {
  display: flex; }

.bx--overflow-menu-options:before {
  content: '';
  position: absolute;
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  z-index: -1;
  transform: rotate(45deg);
  background-color: #fff;
  border-top: 1px solid #dfe3e6;
  border-left: 1px solid #dfe3e6;
  top: -5px;
  left: 24px; }

.bx--overflow-menu-options__option {
  display: flex;
  background-color: transparent;
  align-items: center;
  width: 100%;
  padding: 0; }

.bx--overflow-menu--divider {
  border-top: 1px solid #dfe3e6; }

.bx--overflow-menu-options__btn {
  font-size: 0.875rem;
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  font-weight: 400;
  width: 100%;
  height: 100%;
  border: none;
  display: inline-block;
  background-color: transparent;
  text-align: left;
  padding: 0.5rem 1rem;
  cursor: pointer; }
  .bx--overflow-menu-options__btn:focus {
    outline: 1px solid transparent;
    text-decoration: underline;
    background-color: rgba(85, 150, 230, 0.1);
    text-decoration: underline; }
  .bx--overflow-menu-options__option--danger .bx--overflow-menu-options__btn:focus {
    outline: 1px solid transparent;
    text-decoration: underline; }

.bx--overflow-menu-options__option:hover {
  background-color: rgba(85, 150, 230, 0.1); }

.bx--overflow-menu-options__option:hover .bx--overflow-menu-options__btn {
  text-decoration: none;
  background-color: rgba(85, 150, 230, 0.1);
  text-decoration: none; }

.bx--overflow-menu-options__option--danger {
  border-top: 1px solid #dfe3e6; }

.bx--overflow-menu-options__option--danger .bx--overflow-menu-options__btn:hover, .bx--overflow-menu-options__option--danger .bx--overflow-menu-options__btn:focus {
  color: #fff;
  background-color: #e0182d; }

.bx--overflow-menu-options__option--disabled:hover {
  background-color: #fff; }

.bx--overflow-menu-options__option--disabled .bx--overflow-menu-options__btn {
  color: #152934;
  cursor: not-allowed;
  opacity: 0.5; }

.bx--overflow-menu-options__option--disabled:hover .bx--overflow-menu-options__btn {
  color: #152934;
  opacity: 0.5;
  background-color: #fff; }
  .bx--overflow-menu-options__option--disabled:hover .bx--overflow-menu-options__btn:active, .bx--overflow-menu-options__option--disabled:hover .bx--overflow-menu-options__btn:focus {
    background-color: #fff;
    pointer-events: none; }

.bx--overflow-menu--flip {
  left: -140px; }
  .bx--overflow-menu--flip:before {
    left: 145px; }

.bx--responsive-table-container {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  overflow-x: auto;
  overflow-y: hidden;
  width: 99.9%; }

.bx--responsive-table {
  background-color: #fff;
  width: 100%;
  min-width: 500px;
  border-collapse: collapse;
  border-spacing: 0;
  border: 2px solid #dfe3e6; }
  .bx--responsive-table td {
    font-size: 0.875rem;
    padding: 0 0.375rem;
    vertical-align: middle; }
  .bx--responsive-table td p {
    font-size: 0.875rem; }
  .bx--responsive-table th {
    font-size: 0.75rem;
    padding: 0.5625rem 0.375rem;
    vertical-align: middle;
    font-weight: 600; }
    .bx--responsive-table th:focus {
      outline: 1px solid transparent; }
      .bx--responsive-table th:focus span {
        outline: 1px solid #3d70b2; }
  .bx--responsive-table--tall td,
  .bx--responsive-table--tall th {
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem; }
  .bx--responsive-table--static-size {
    border-collapse: collapse;
    width: auto;
    border: 2px solid #dfe3e6; }
    .bx--responsive-table--static-size tr td:first-child,
    .bx--responsive-table--static-size tr th:first-child {
      padding-left: 1.5rem; }

.bx--responsive-table--tall td,
.bx--responsive-table--tall th {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem; }

.bx--table-row {
  height: 2rem;
  padding-left: 1rem; }

.bx--table-head .bx--table-row {
  border-bottom: 1px solid #3d70b2;
  height: 2rem; }

.bx--table-header {
  font-size: 0.75rem;
  font-weight: 600;
  text-align: left;
  text-transform: uppercase;
  height: 2.5rem; }

.bx--table-body > .bx--parent-row,
.bx--table-body > .bx--parent-row {
  background-color: #fff; }
  .bx--table-body > .bx--parent-row + .bx--expandable-row,
  .bx--table-body > .bx--parent-row + .bx--expandable-row {
    background-color: #fff; }
  .bx--table-body > .bx--parent-row--even,
  .bx--table-body > .bx--parent-row--even {
    background-color: #f4f7fb; }
    .bx--table-body > .bx--parent-row--even + .bx--expandable-row,
    .bx--table-body > .bx--parent-row--even + .bx--expandable-row {
      background-color: #f4f7fb; }

.bx--table-body .bx--table-row {
  border: 1px solid transparent; }
  .bx--table-body .bx--table-row:first-child:hover, .bx--table-body .bx--table-row:first-child:focus {
    border-left: 2px solid #5596e6;
    outline: 1px solid #5596e6; }
  .bx--table-body .bx--table-row:not(:first-child):hover, .bx--table-body .bx--table-row:not(:first-child):focus {
    border-left: 2px solid #5596e6;
    outline: 1px solid #5596e6; }

.bx--expandable-row > td {
  border-left: 4px solid #3d70b2;
  padding: 2rem; }

.bx--expandable-row--hidden {
  visibility: hidden; }

.bx--table-expand {
  padding-left: 0.5rem;
  padding-right: 0.75rem;
  text-align: center;
  width: 1.25rem;
  cursor: pointer; }
  .bx--table-expand:focus {
    outline: 1px solid transparent; }
    .bx--table-expand:focus svg {
      outline: 1px solid #3d70b2; }

.bx--table-expand__svg {
  fill: #5a6872;
  transform: rotate(0deg);
  transition: transform 200ms cubic-bezier(0.5, 0, 0.1, 1);
  width: 12px;
  height: 12px;
  margin-left: 0.4375rem;
  margin-right: 0.125rem; }
  @media all and (min--moz-device-pixel-ratio: 0) and (min-resolution: 30dpcm) {
    .bx--table-expand__svg {
      margin-top: 2px; } }

.bx--table-expand[data-previous-value='collapsed'] .bx--table-expand__svg {
  transform: rotate(90deg);
  transition: transform 200ms cubic-bezier(0.5, 0, 0.1, 1); }

[data-event='sort'] {
  cursor: pointer; }

.bx--table-sort__svg {
  fill: #5a6872;
  width: 8px;
  height: 8px;
  transform: rotate(0deg);
  transition: transform 200ms cubic-bezier(0.5, 0, 0.1, 1); }

.bx--table-sort--ascending .bx--table-sort__svg {
  transform: rotate(180deg);
  transition: transform 200ms cubic-bezier(0.5, 0, 0.1, 1); }

.bx--table-select {
  width: 1.875rem;
  text-align: center;
  padding-left: 0;
  padding-right: 0; }
  .bx--table-select .bx--checkbox-label {
    display: inline-flex;
    margin: 0; }
    @media all and (min--moz-device-pixel-ratio: 0) and (min-resolution: 30dpcm) {
      .bx--table-select .bx--checkbox-label {
        margin-top: 2px; } }
  .bx--table-select .bx--checkbox-appearance {
    margin: 0; }

.bx--table-overflow {
  width: 2.5rem;
  text-align: center; }
  .bx--table-overflow .bx--overflow-menu {
    padding: 0; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--table-toolbar {
  display: flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 100%;
  position: relative; }
  .bx--table-toolbar .bx--search-input {
    position: relative; }
    .bx--table-toolbar .bx--search-input:focus {
      box-shadow: inset 0px 0px 0px 1px #3d70b2;
      outline: 0; }

.bx--toolbar-content {
  display: flex;
  margin-left: auto; }

.bx--toolbar-action {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0;
  display: inline-block;
  background: none;
  appearance: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  width: 100%;
  cursor: pointer;
  padding-left: 0.75rem;
  padding-right: 0.75rem; }
  .bx--toolbar-action::-moz-focus-inner {
    border: 0; }
  .bx--toolbar-action:hover > .bx--toolbar-action__icon {
    fill: #3d70b2; }
  .bx--toolbar-action:focus {
    outline: 1px solid #3d70b2; }
    .bx--toolbar-action:focus > .bx--toolbar-action__icon {
      fill: #3d70b2; }
  .bx--toolbar-action:active > .bx--toolbar-action__icon {
    fill: #3d70b2; }
  .bx--toolbar-action:last-of-type {
    padding-right: 0; }
  .bx--toolbar-action ~ .bx--btn {
    margin-left: 0.75rem;
    margin-right: 0.75rem; }

.bx--toolbar-action__icon {
  height: 1rem;
  width: auto;
  max-width: 16px;
  fill: #5a6872;
  transition: 250ms; }

.bx--batch-actions {
  display: flex;
  align-items: center;
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  width: 100%;
  height: 100%;
  z-index: 6000;
  background-color: transparent;
  transition: opacity 200ms cubic-bezier(0.5, 0, 0.1, 1), background-color 200ms cubic-bezier(0.5, 0, 0.1, 1);
  pointer-events: none;
  visibility: hidden; }
  .bx--batch-actions:focus {
    outline: 1px solid #3d70b2; }
  .bx--batch-actions .bx--btn {
    color: #fff; }
  .bx--batch-actions .bx--btn__icon {
    fill: #fff; }
  .bx--batch-actions .bx--btn--ghost:hover, .bx--batch-actions .bx--btn--ghost:focus {
    background-color: #fff;
    color: #3d70b2; }
    .bx--batch-actions .bx--btn--ghost:hover .bx--btn__icon, .bx--batch-actions .bx--btn--ghost:focus .bx--btn__icon {
      fill: #3d70b2; }
  .bx--batch-actions .bx--btn--ghost:focus {
    border: 2px solid #3d70b2;
    outline: 2px solid #f4f7fb; }

.bx--batch-actions--active {
  visibility: visible;
  background-color: #3d70b2;
  pointer-events: all;
  transition: opacity 200ms cubic-bezier(0.5, 0, 0.1, 1), background-color 200ms cubic-bezier(0.5, 0, 0.1, 1);
  opacity: 1; }

.bx--action-list {
  margin-left: -0.5rem; }

.bx--action-icons {
  margin-left: auto; }
  .bx--action-icons svg {
    padding: 0 0.75rem;
    fill: #fff;
    height: 1rem;
    width: auto; }

.bx--batch-summary {
  margin-left: auto;
  display: flex;
  align-items: center;
  color: #fff; }

.bx--batch-summary__para {
  font-size: 0.875rem;
  margin-right: 1.5rem; }

.bx--batch-summary__cancel {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0;
  display: inline-block;
  background: none;
  appearance: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  font-size: 0.875rem;
  color: #fff;
  border-bottom: 1px solid transparent;
  font-weight: 600;
  cursor: pointer;
  transition: border 250ms cubic-bezier(0.5, 0, 0.1, 1); }
  .bx--batch-summary__cancel::-moz-focus-inner {
    border: 0; }
  .bx--batch-summary__cancel:hover, .bx--batch-summary__cancel:focus {
    border-bottom: 1px solid #fff; }

.bx--batch-actions ~ .bx--toolbar-search-container,
.bx--batch-actions ~ .bx--toolbar-content {
  opacity: 1;
  transition: opacity 250ms; }

.bx--batch-actions--active ~ .bx--toolbar-search-container,
.bx--batch-actions--active ~ .bx--toolbar-content {
  opacity: 0; }

.bx--data-table-v2-container {
  width: 100%;
  min-width: 31.25rem;
  overflow-x: auto;
  padding-top: 0.125rem; }

.bx--data-table-v2 {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border-bottom: 1px solid #dfe3e6; }
  .bx--data-table-v2 thead {
    font-size: 0.875rem;
    background-color: #f4f7fb;
    font-weight: 700; }
  .bx--data-table-v2 tbody {
    font-size: 0.875rem;
    background-color: #fff; }
  .bx--data-table-v2 tr {
    height: 3rem; }
    .bx--data-table-v2 tr:hover td {
      background-color: rgba(85, 150, 230, 0.1);
      border-top: 1px solid #3d70b2;
      border-bottom: 1px solid #3d70b2; }
      .bx--data-table-v2 tr:hover td:first-of-type {
        border-left: 1px solid #3d70b2; }
      .bx--data-table-v2 tr:hover td:last-of-type {
        border-right: 1px solid #3d70b2; }
    .bx--data-table-v2 tr:hover .bx--overflow-menu {
      opacity: 1; }
  .bx--data-table-v2 th {
    border-top: 1px solid #dfe3e6; }
  .bx--data-table-v2 th,
  .bx--data-table-v2 td {
    border-top: 1px solid #dfe3e6;
    padding-left: 0.75rem;
    vertical-align: middle;
    text-align: left; }
    .bx--data-table-v2 th:first-of-type,
    .bx--data-table-v2 td:first-of-type {
      padding-left: 1.5rem;
      border-left: 1px solid #dfe3e6; }
    .bx--data-table-v2 th:last-of-type,
    .bx--data-table-v2 td:last-of-type {
      padding-right: 1.5rem;
      border-right: 1px solid #dfe3e6; }
  .bx--data-table-v2 .bx--checkbox-label {
    padding-left: 1.75rem; }
  .bx--data-table-v2 .bx--overflow-menu {
    opacity: 0; }
    .bx--data-table-v2 .bx--overflow-menu:focus {
      outline: 0;
      opacity: 1;
      box-shadow: none; }
      .bx--data-table-v2 .bx--overflow-menu:focus .bx--overflow-menu__icon {
        box-shadow: inset 0px 0px 0px 1px #3d70b2; }
  .bx--data-table-v2 .bx--overflow-menu__icon {
    transform: rotate(90deg); }

.bx--data-table-v2-header {
  margin-bottom: 0.5rem; }

.bx--data-table-v2--zebra tbody tr:nth-child(even) {
  background-color: #f4f7fb; }

.bx--data-table-v2--no-border tr:last-of-type td {
  border-bottom-color: transparent; }

.bx--data-table-v2--no-border th:first-of-type,
.bx--data-table-v2--no-border td:first-of-type {
  border-left-color: transparent; }

.bx--data-table-v2--no-border th:last-of-type,
.bx--data-table-v2--no-border td:last-of-type {
  border-right-color: #dfe3e6; }

.bx--data-table-v2--compact tbody tr {
  height: 1.5rem; }

.bx--data-table-v2--short tbody tr {
  height: 2rem; }

.bx--data-table-v2--tall tbody tr {
  height: 4rem; }

.bx--data-table-v2--static {
  width: auto; }

.bx--data-table-v2--zebra tbody tr.bx--data-table-v2--selected,
tbody tr.bx--data-table-v2--selected {
  background-color: rgba(85, 150, 230, 0.1); }

tr.bx--expandable-row-v2.bx--expandable-row--hidden-v2 {
  display: none; }

tr.bx--expandable-row-v2 > td:first-of-type {
  position: relative; }
  tr.bx--expandable-row-v2 > td:first-of-type:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 2px;
    background-color: #3d70b2; }

tr.bx--expandable-row-v2 + tr[data-child-row] td {
  border-top: 0;
  padding-bottom: 0.5rem; }

tr.bx--expandable-row-v2:hover > td {
  background-color: rgba(85, 150, 230, 0.1); }

tr.bx--expandable-row-v2:hover > td:first-of-type {
  border-left: 1px solid transparent; }

tr.bx--expandable-row-v2:hover > td:last-of-type {
  border-right: 1px solid #3d70b2; }

tr.bx--expandable-row-v2:hover[data-parent-row] > td {
  border-bottom: 0; }

tr.bx--expandable-row-v2:hover + tr[data-child-row] > td {
  background-color: rgba(85, 150, 230, 0.1);
  border-bottom: 1px solid #3d70b2;
  border-right: 1px solid #3d70b2; }

tr.bx--expandable-row--hover-v2 > td {
  background-color: rgba(85, 150, 230, 0.1);
  border-top: 1px solid #3d70b2; }

tr.bx--expandable-row--hover-v2 > td:last-of-type {
  border-right: 1px solid #3d70b2; }

.bx--table-expand-v2[data-previous-value='collapsed'] .bx--table-expand-v2__svg {
  transform: rotate(90deg);
  transition: transform 200ms cubic-bezier(0.5, 0, 0.1, 1); }

.bx--table-expand-v2__button {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0;
  display: inline-block;
  background: none;
  appearance: none;
  border: 0;
  padding: 0;
  cursor: pointer; }
  .bx--table-expand-v2__button::-moz-focus-inner {
    border: 0; }
  .bx--table-expand-v2__button:focus {
    outline: 1px solid transparent; }
    .bx--table-expand-v2__button:focus svg {
      box-shadow: inset 0px 0px 0px 1px #3d70b2; }

.bx--table-expand-v2__svg {
  fill: #5a6872;
  transform: rotate(0deg);
  transition: transform 200ms cubic-bezier(0.5, 0, 0.1, 1);
  height: 16px;
  width: auto;
  max-width: 16px;
  padding: 0.125rem; }

.bx--table-sort-v2--ascending .bx--table-sort-v2__icon {
  transform: rotate(180deg);
  transition: transform 200ms cubic-bezier(0.5, 0, 0.1, 1); }

.bx--table-sort-v2--active .bx--table-sort-v2__icon {
  opacity: 1; }

.bx--data-table-v2 th:hover .bx--table-sort-v2__icon {
  opacity: 1; }

.bx--table-sort-v2 {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0;
  display: inline-block;
  background: none;
  appearance: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  position: relative;
  font: inherit;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%; }
  .bx--table-sort-v2::-moz-focus-inner {
    border: 0; }
  .bx--table-sort-v2:focus {
    outline: 0; }
    .bx--table-sort-v2:focus svg {
      opacity: 1;
      outline: 1px solid #3d70b2;
      fill: #3d70b2;
      outline-offset: -0.5px; }

.bx--table-sort-v2__icon {
  position: relative;
  left: 2px;
  transition: transform 200ms cubic-bezier(0.5, 0, 0.1, 1);
  transform: rotate(0);
  opacity: 0;
  fill: #5a6872;
  height: 0.5625rem;
  padding: 0.125rem;
  width: auto;
  min-width: 14px; }

.bx--inline-edit-label {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .bx--inline-edit-label:hover .bx--inline-edit-label__icon {
    opacity: 1; }

.bx--inline-edit-label--inactive {
  display: none; }

.bx--inline-edit-label__action {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0;
  display: inline-block;
  background: none;
  appearance: none;
  border: 0;
  padding: 0;
  cursor: pointer; }
  .bx--inline-edit-label__action::-moz-focus-inner {
    border: 0; }
  .bx--inline-edit-label__action:hover {
    cursor: pointer; }
  .bx--inline-edit-label__action:focus {
    outline: 1px solid #3d70b2;
    padding: 0.125rem; }
    .bx--inline-edit-label__action:focus .bx--inline-edit-label__icon {
      width: auto;
      opacity: 1; }

.bx--inline-edit-label__icon {
  fill: #5a6872;
  opacity: 0; }

.bx--inline-edit-input {
  display: none; }

.bx--inline-edit-input--active {
  display: block;
  margin-left: -0.75rem; }
  .bx--inline-edit-input--active input {
    padding-left: 0.75rem; }

.bx--data-table-v2--short input {
  height: 2rem; }

.bx--data-table-v2--short select {
  padding: 0.45rem 2.75rem 0.45rem 1rem; }

.bx--data-table-v2--short .bx--select__arrow {
  top: 0.875rem; }

.bx--data-table-v2.bx--skeleton th {
  border-bottom: 1px solid #3d70b2; }
  .bx--data-table-v2.bx--skeleton th:nth-child(3n + 1) {
    width: 10%; }
  .bx--data-table-v2.bx--skeleton th:nth-child(3n + 2) {
    width: 30%; }
  .bx--data-table-v2.bx--skeleton th:nth-child(3n + 3) {
    width: 15%; }

.bx--data-table-v2.bx--skeleton th span,
.bx--data-table-v2.bx--skeleton td span {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: rgba(61, 112, 178, 0.1);
  width: 75%;
  height: 1rem;
  display: block; }
  .bx--data-table-v2.bx--skeleton th span:hover, .bx--data-table-v2.bx--skeleton th span:focus, .bx--data-table-v2.bx--skeleton th span:active,
  .bx--data-table-v2.bx--skeleton td span:hover,
  .bx--data-table-v2.bx--skeleton td span:focus,
  .bx--data-table-v2.bx--skeleton td span:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--data-table-v2.bx--skeleton th span:before,
  .bx--data-table-v2.bx--skeleton td span:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    background: rgba(61, 112, 178, 0.1);
    animation: 3000ms ease-in-out skeleton infinite; }

.bx--data-table-v2.bx--skeleton tr:hover td {
  border-color: #8897a2;
  background: transparent; }
  .bx--data-table-v2.bx--skeleton tr:hover td:first-of-type, .bx--data-table-v2.bx--skeleton tr:hover td:last-of-type {
    border-color: #8897a2; }

.bx--data-table-v2.bx--skeleton .bx--table-sort-v2 {
  pointer-events: none; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--text-truncate--end {
  width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.bx--text-truncate--front {
  width: 100%;
  display: inline-block;
  direction: rtl;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.bx--structured-list--selection .bx--structured-list-td,
.bx--structured-list--selection .bx--structured-list-th {
  padding-left: 1rem;
  padding-right: 1rem; }
  .bx--structured-list--selection .bx--structured-list-td:first-child,
  .bx--structured-list--selection .bx--structured-list-th:first-child {
    padding-left: 1rem;
    padding-right: 0.5rem; }
  .bx--structured-list--selection .bx--structured-list-td:last-child,
  .bx--structured-list--selection .bx--structured-list-th:last-child {
    padding-right: 2rem; }

[data-structured-list] .bx--structured-list-td,
[data-structured-list] .bx--structured-list-th {
  padding-left: 1rem;
  padding-right: 1rem; }
  [data-structured-list] .bx--structured-list-td:first-child,
  [data-structured-list] .bx--structured-list-th:first-child {
    padding-left: 1rem;
    padding-right: 0.5rem; }
  [data-structured-list] .bx--structured-list-td:last-child,
  [data-structured-list] .bx--structured-list-th:last-child {
    padding-right: 2rem; }

.bx--structured-list-input {
  display: none; }

.bx--structured-list {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  display: table;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  min-width: 500px;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 5rem;
  background-color: transparent; }
  .bx--structured-list.bx--structured-list--border {
    border: 1px solid #dfe3e6;
    background-color: #fff; }
  .bx--structured-list.bx--structured-list--condensed .bx--structured-list-td,
  .bx--structured-list.bx--structured-list--condensed .bx--structured-list-th {
    padding: 0.5rem;
    padding-left: 0; }

.bx--structured-list-row {
  display: table-row;
  border-bottom: 1px solid #dfe3e6;
  transition: 250ms cubic-bezier(0.5, 0, 0.1, 1); }
  .bx--structured-list--selection .bx--structured-list-row:hover:not(.bx--structured-list-row--header-row) {
    background-color: rgba(85, 150, 230, 0.1);
    cursor: pointer; }
  [data-structured-list] .bx--structured-list-row:hover:not(.bx--structured-list-row--header-row) {
    background-color: rgba(85, 150, 230, 0.1);
    cursor: pointer; }
  .bx--structured-list-row.bx--structured-list-row--selected {
    background-color: rgba(85, 150, 230, 0.1); }
  .bx--structured-list-row.bx--structured-list-row--header-row {
    border-bottom: 2px solid #3d70b2;
    cursor: inherit; }
  .bx--structured-list-row:focus:not(.bx--structured-list-row--header-row) {
    outline: 1px solid #3d70b2; }

.bx--structured-list-thead {
  display: table-header-group;
  vertical-align: middle; }

.bx--structured-list-th {
  padding-left: 0;
  padding-right: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 0.75rem;
  display: table-cell;
  font-weight: 600;
  height: 2.5rem;
  text-align: left;
  text-transform: none;
  vertical-align: bottom; }
  .bx--structured-list-th:last-child {
    padding-right: 0; }

.bx--structured-list-tbody {
  display: table-row-group;
  vertical-align: middle; }

.bx--structured-list-td {
  font-size: 0.875rem;
  line-height: 1.5;
  padding-left: 0;
  padding-right: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: relative;
  display: table-cell; }
  .bx--structured-list-td:last-child {
    padding-right: 2rem; }

.bx--structured-list-content--nowrap {
  white-space: nowrap; }

.bx--structured-list-svg {
  display: inline-block;
  fill: transparent;
  vertical-align: middle;
  transition: 250ms cubic-bezier(0.5, 0, 0.1, 1); }
  .bx--structured-list-row:hover .bx--structured-list-svg {
    fill: rgba(85, 150, 230, 0.1); }
  .bx--structured-list-input:checked + .bx--structured-list-row .bx--structured-list-svg,
  .bx--structured-list-input:checked + .bx--structured-list-td .bx--structured-list-svg {
    fill: #5596e6; }

.bx--structured-list.bx--skeleton .bx--structured-list-th:first-child {
  width: 8%; }

.bx--structured-list.bx--skeleton .bx--structured-list-th:nth-child(3n + 2) {
  width: 30%; }

.bx--structured-list.bx--skeleton .bx--structured-list-th:nth-child(3n + 3) {
  width: 15%; }

.bx--structured-list.bx--skeleton .bx--structured-list-th span {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: rgba(61, 112, 178, 0.1);
  width: 75%;
  height: 1rem;
  display: block; }
  .bx--structured-list.bx--skeleton .bx--structured-list-th span:hover, .bx--structured-list.bx--skeleton .bx--structured-list-th span:focus, .bx--structured-list.bx--skeleton .bx--structured-list-th span:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--structured-list.bx--skeleton .bx--structured-list-th span:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    background: rgba(61, 112, 178, 0.1);
    animation: 3000ms ease-in-out skeleton infinite; }

.bx--structured-list.bx--structured-list--border.bx--skeleton .bx--structured-list-th:first-child {
  width: 5%; }
  .bx--structured-list.bx--structured-list--border.bx--skeleton .bx--structured-list-th:first-child span {
    display: none; }

.bx--structured-list-content {
  font-size: 0.875rem; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--snippet code {
  font-family: "ibm-plex-mono", "Menlo", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", Courier, monospace; }

.bx--snippet--inline {
  position: relative;
  display: inline;
  padding: 0;
  background: transparent;
  font-size: inherit;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: #f4f7fb;
  font-size: 85%;
  cursor: pointer; }
  .bx--snippet--inline:hover {
    background-color: #cfdced; }
  .bx--snippet--inline:focus {
    outline: 1px solid #3d70b2;
    outline: none;
    border: 1px solid #3d70b2; }

.bx--snippet--inline code {
  padding: 0.0625rem 0.5rem; }

.bx--snippet--inline.bx--snippet--light {
  background-color: #fff; }
  .bx--snippet--inline.bx--snippet--light:hover {
    background-color: rgba(85, 150, 230, 0.1); }

.bx--snippet--single {
  font-size: 0.75rem;
  line-height: 1.45;
  background: #fff;
  border: 1px solid #dfe3e6;
  font-family: "ibm-plex-mono", "Menlo", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", Courier, monospace;
  position: relative;
  max-width: 37.5rem;
  width: 100%;
  height: 3.5rem;
  padding: 0 2.5rem 0 1rem; }

.bx--snippet--single .bx--snippet-container {
  display: flex;
  align-items: center;
  height: 130%;
  overflow-x: scroll;
  position: relative;
  padding-bottom: 1rem; }

.bx--snippet--single pre {
  white-space: nowrap; }

.bx--snippet--multi {
  font-size: 0.75rem;
  line-height: 1.45;
  background: #fff;
  border: 1px solid #dfe3e6;
  font-family: "ibm-plex-mono", "Menlo", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", Courier, monospace;
  position: relative;
  max-width: 37.5rem;
  width: 100%;
  padding: 1rem 3rem 1rem 1.5rem; }

.bx--snippet--multi .bx--snippet-container {
  overflow: hidden;
  position: relative;
  max-height: 15.875rem;
  min-height: 3.5rem;
  transition: all 250ms cubic-bezier(0.5, 0, 0.1, 1); }

.bx--snippet--expand.bx--snippet--multi .bx--snippet-container {
  max-height: 93.75rem; }

.bx--snippet--multi .bx--snippet-container pre {
  white-space: pre-wrap; }

.bx--snippet__icon {
  fill: #3d70b2;
  height: 1.5rem;
  width: 1.125rem;
  transition: all 250ms cubic-bezier(0.5, 0, 0.1, 1); }
  .bx--snippet__icon:hover {
    fill: #30588c; }

.bx--snippet-button {
  cursor: pointer;
  position: absolute;
  top: 0.675rem;
  right: 0.5rem;
  border: none;
  background-color: transparent;
  outline: none;
  padding: 0;
  height: 2rem;
  width: 2rem;
  overflow: visible; }
  .bx--snippet-button:focus {
    outline: 1px solid #3d70b2; }

.bx--snippet .bx--btn--copy__feedback {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  z-index: 8000;
  font-weight: 400;
  left: inherit;
  top: 0.75rem;
  right: 1.25rem; }

.bx--snippet--code.bx--skeleton {
  height: 6.125rem; }

.bx--snippet--terminal.bx--skeleton {
  height: 3.5rem; }

.bx--snippet.bx--skeleton .bx--snippet-container {
  height: 100%; }

.bx--snippet.bx--skeleton code {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: rgba(61, 112, 178, 0.1);
  width: 100%;
  height: 1rem;
  display: block; }
  .bx--snippet.bx--skeleton code:hover, .bx--snippet.bx--skeleton code:focus, .bx--snippet.bx--skeleton code:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--snippet.bx--skeleton code:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    background: rgba(61, 112, 178, 0.1);
    animation: 3000ms ease-in-out skeleton infinite; }

.bx--snippet-button .bx--btn--copy__feedback {
  top: 1.5625rem;
  left: 1rem;
  right: auto; }

.bx--snippet--inline .bx--btn--copy__feedback {
  right: auto;
  left: 50%; }

.bx--snippet-btn--expand {
  position: absolute;
  right: 0;
  bottom: 0; }

.bx--snippet-btn--expand--hide.bx--snippet-btn--expand {
  display: none; }

.bx--snippet-btn--expand .bx--icon-chevron--down {
  fill: #3d70b2;
  margin-left: 0.25rem;
  transform: rotate(0deg);
  transition: 250ms; }

.bx--snippet-btn--expand:hover .bx--icon-chevron--down,
.bx--snippet-btn--expand:focus .bx--icon-chevron--down {
  fill: #fff; }

.bx--snippet--expand .bx--snippet-btn--expand .bx--icon-chevron--down {
  transform: rotate(180deg); }

bx--snippet--multi.bx--skeleton {
  height: 6.125rem; }

.bx--snippet--single.bx--skeleton {
  height: 3.5rem; }

.bx--snippet.bx--skeleton .bx--snippet-container {
  height: 100%; }

.bx--snippet.bx--skeleton span {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: rgba(61, 112, 178, 0.1);
  width: 100%;
  height: 1rem;
  display: block;
  margin-top: 0.5rem; }
  .bx--snippet.bx--skeleton span:hover, .bx--snippet.bx--skeleton span:focus, .bx--snippet.bx--skeleton span:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--snippet.bx--skeleton span:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    background: rgba(61, 112, 178, 0.1);
    animation: 3000ms ease-in-out skeleton infinite; }
  .bx--snippet.bx--skeleton span:first-child {
    margin: 0; }
  .bx--snippet.bx--skeleton span:nth-child(2) {
    width: 85%; }
  .bx--snippet.bx--skeleton span:nth-child(3) {
    width: 95%; }

.bx--snippet--single.bx--skeleton .bx--snippet-container {
  padding-bottom: 0; }

/*
    Deprecated class names will be removed in v10.x
  */
.bx--snippet--terminal {
  font-size: 0.75rem;
  line-height: 1.45;
  background: #fff;
  border: 1px solid #dfe3e6;
  font-family: "ibm-plex-mono", "Menlo", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", Courier, monospace;
  position: relative;
  max-width: 37.5rem;
  width: 100%;
  height: 3.5rem;
  padding: 0 2.5rem 0 1rem; }

.bx--snippet--terminal .bx--snippet-container {
  display: flex;
  align-items: center;
  height: 130%;
  white-space: nowrap;
  overflow-x: scroll;
  position: relative;
  padding-bottom: 1rem; }

.bx--snippet--code {
  font-size: 0.75rem;
  line-height: 1.45;
  background: #fff;
  border: 1px solid #dfe3e6;
  font-family: "ibm-plex-mono", "Menlo", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", Courier, monospace;
  position: relative;
  max-width: 37.5rem;
  width: 100%;
  padding: 1rem 3rem 1rem 1.5rem; }

.bx--snippet--code .bx--snippet-container {
  overflow: hidden;
  position: relative;
  max-height: 15.875rem;
  min-height: 3.5rem;
  transition: all 250ms cubic-bezier(0.5, 0, 0.1, 1); }

.bx--snippet--expand.bx--snippet--code .bx--snippet-container {
  max-height: 93.75rem; }

.bx--snippet--code .bx--snippet-container pre {
  white-space: pre-wrap; }

.bx--snippet--inline .bx--btn--copy__feedback {
  right: auto; }

.bx--snippet--code.bx--skeleton {
  height: 6.125rem; }

.bx--snippet--terminal.bx--skeleton {
  height: 3.5rem; }

.bx--snippet.bx--skeleton .bx--snippet-container {
  height: 100%; }

.bx--snippet.bx--skeleton code {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: rgba(61, 112, 178, 0.1);
  width: 100%;
  height: 1rem;
  display: block; }
  .bx--snippet.bx--skeleton code:hover, .bx--snippet.bx--skeleton code:focus, .bx--snippet.bx--skeleton code:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--snippet.bx--skeleton code:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    background: rgba(61, 112, 178, 0.1);
    animation: 3000ms ease-in-out skeleton infinite; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--text-truncate--end {
  width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.bx--text-truncate--front {
  width: 100%;
  display: inline-block;
  direction: rtl;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--content-switcher {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  display: flex;
  height: 2.5rem; }

.bx--content-switcher-btn {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.875rem;
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  background-color: transparent;
  display: flex;
  align-items: center;
  padding: 0 2rem;
  margin: 0;
  text-decoration: none;
  border: 1px solid #3d70b2;
  color: #3d70b2; }
  .bx--content-switcher-btn:focus {
    outline: 1px solid transparent;
    background-color: #30588c;
    z-index: 2;
    border-color: #30588c;
    text-decoration: underline;
    color: #fff; }
  .bx--content-switcher-btn:hover {
    cursor: pointer; }
  .bx--content-switcher-btn:hover, .bx--content-switcher-btn:active {
    background-color: #30588c;
    border-color: #30588c;
    color: #fff; }

.bx--content-switcher__icon {
  margin-right: 0.5rem;
  fill: currentColor; }
  .bx--content-switcher__icon * {
    fill: currentColor; }

.bx--content-switcher-btn:not(:last-of-type) {
  border-right: none; }

.bx--content-switcher-btn:not(:first-of-type) {
  border-left: 1px solid #3d70b2; }
  .bx--content-switcher-btn:not(:first-of-type):hover {
    border-left-color: #30588c; }

.bx--content-switcher-btn:first-of-type {
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px; }
  .bx--content-switcher-btn:first-of-type:hover {
    border-color: #30588c; }
  .bx--content-switcher-btn:first-of-type:focus {
    z-index: 0; }

.bx--content-switcher-btn:last-of-type {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px; }
  .bx--content-switcher-btn:last-of-type:hover {
    border-color: #30588c; }
  .bx--content-switcher-btn:last-of-type:focus {
    z-index: 0; }

.bx--content-switcher-btn.bx--content-switcher--selected {
  background-color: #3d70b2;
  color: #fff;
  font-weight: 400;
  outline: 1px solid transparent; }
  .bx--content-switcher-btn.bx--content-switcher--selected:hover, .bx--content-switcher-btn.bx--content-switcher--selected:focus {
    border-color: #30588c;
    background-color: #30588c; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.flatpickr-calendar {
  background: transparent;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  visibility: hidden;
  text-align: center;
  padding: 0;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 315px;
  box-sizing: border-box;
  touch-action: manipulation;
  background: #fff;
  box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08); }

.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  opacity: 1;
  visibility: visible;
  overflow: visible;
  max-height: 640px; }

.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999; }

.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1); }

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px; }

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px); }

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block; }

.flatpickr-calendar.hasWeeks {
  width: auto; }

.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0; }

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #e6e6e6; }

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto; }

.flatpickr-calendar:before,
.flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  left: 22px; }

.flatpickr-calendar.rightMost:before,
.flatpickr-calendar.rightMost:after {
  left: auto;
  right: 22px; }

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px; }

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px; }

.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  bottom: 100%; }

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #e6e6e6; }

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff; }

.flatpickr-calendar.arrowBottom:before,
.flatpickr-calendar.arrowBottom:after {
  top: 100%; }

.flatpickr-calendar.arrowBottom:before {
  border-top-color: #e6e6e6; }

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff; }

.flatpickr-calendar:focus {
  outline: 0; }

.flatpickr-wrapper {
  position: relative;
  display: inline-block; }

.flatpickr-month {
  background: transparent;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
  height: 28px;
  line-height: 1;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden; }

.flatpickr-prev-month,
.flatpickr-next-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0px;
  line-height: 16px;
  height: 28px;
  padding: 10px calc(3.57% - 1.5px);
  z-index: 3; }

.flatpickr-prev-month i,
.flatpickr-next-month i {
  position: relative; }

.flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-next-month.flatpickr-prev-month {
  /*
        /*rtl:begin:ignore*/
  /*
        */
  left: 0;
  /*
        /*rtl:end:ignore*/
  /*
        */ }

/*
        /*rtl:begin:ignore*/
/*
        /*rtl:end:ignore*/
.flatpickr-prev-month.flatpickr-next-month,
.flatpickr-next-month.flatpickr-next-month {
  /*
        /*rtl:begin:ignore*/
  /*
        */
  right: 0;
  /*
        /*rtl:end:ignore*/
  /*
        */ }

/*
        /*rtl:begin:ignore*/
/*
        /*rtl:end:ignore*/
.flatpickr-prev-month:hover,
.flatpickr-next-month:hover {
  color: #959ea9; }

.flatpickr-prev-month:hover svg,
.flatpickr-next-month:hover svg {
  fill: #f64747; }

.flatpickr-prev-month svg,
.flatpickr-next-month svg {
  width: 14px; }

.flatpickr-prev-month svg path,
.flatpickr-next-month svg path {
  transition: fill 0.1s;
  fill: inherit; }

.numInputWrapper {
  position: relative;
  height: auto; }

.numInputWrapper input,
.numInputWrapper span {
  display: inline-block; }

.numInputWrapper input {
  width: 100%; }

.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(57, 57, 57, 0.05);
  box-sizing: border-box; }

.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1); }

.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2); }

.numInputWrapper span:after {
  display: block;
  content: '';
  position: absolute;
  top: 33%; }

.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0; }

.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(57, 57, 57, 0.6); }

.numInputWrapper span.arrowDown {
  top: 50%; }

.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(57, 57, 57, 0.6); }

.numInputWrapper span svg {
  width: inherit;
  height: auto; }

.numInputWrapper span svg path {
  fill: rgba(0, 0, 0, 0.5); }

.numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05); }

.numInputWrapper:hover span {
  opacity: 1; }

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 6.16px 0 0 0;
  line-height: 1;
  height: 28px;
  display: inline-block;
  text-align: center;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px); }

.flatpickr-current-month.slideLeft {
  -webkit-transform: translate3d(-100%, 0px, 0px);
  transform: translate3d(-100%, 0px, 0px);
  -webkit-animation: fpFadeOut 400ms ease, fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeOut 400ms ease, fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1); }

.flatpickr-current-month.slideLeftNew {
  -webkit-transform: translate3d(100%, 0px, 0px);
  transform: translate3d(100%, 0px, 0px);
  -webkit-animation: fpFadeIn 400ms ease, fpSlideLeftNew 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeIn 400ms ease, fpSlideLeftNew 400ms cubic-bezier(0.23, 1, 0.32, 1); }

.flatpickr-current-month.slideRight {
  -webkit-transform: translate3d(100%, 0px, 0px);
  transform: translate3d(100%, 0px, 0px);
  -webkit-animation: fpFadeOut 400ms ease, fpSlideRight 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeOut 400ms ease, fpSlideRight 400ms cubic-bezier(0.23, 1, 0.32, 1); }

.flatpickr-current-month.slideRightNew {
  -webkit-transform: translate3d(0, 0, 0px);
  transform: translate3d(0, 0, 0px);
  -webkit-animation: fpFadeIn 400ms ease, fpSlideRightNew 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeIn 400ms ease, fpSlideRightNew 400ms cubic-bezier(0.23, 1, 0.32, 1); }

.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0; }

.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05); }

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch\0;
  display: inline-block; }

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(0, 0, 0, 0.9); }

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(0, 0, 0, 0.9); }

.flatpickr-current-month input.cur-year {
  background: transparent;
  box-sizing: border-box;
  color: inherit;
  cursor: default;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: baseline; }

.flatpickr-current-month input.cur-year:focus {
  outline: 0; }

.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(0, 0, 0, 0.5);
  background: transparent;
  pointer-events: none; }

.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 315px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 28px; }

span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: transparent;
  color: rgba(0, 0, 0, 0.54);
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-weight: bolder; }

.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0 0; }

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 315px; }

.flatpickr-days:focus {
  outline: 0; }

.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 315px;
  min-width: 315px;
  max-width: 315px;
  box-sizing: border-box;
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
  opacity: 1; }

.flatpickr-calendar.animate .dayContainer.slideLeft {
  -webkit-animation: fpFadeOut 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeOut 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1); }

.flatpickr-calendar.animate .dayContainer.slideLeft,
.flatpickr-calendar.animate .dayContainer.slideLeftNew {
  -webkit-transform: translate3d(-100%, 0px, 0px);
  transform: translate3d(-100%, 0px, 0px); }

.flatpickr-calendar.animate .dayContainer.slideLeftNew {
  -webkit-animation: fpFadeIn 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeIn 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1); }

.flatpickr-calendar.animate .dayContainer.slideRight {
  -webkit-animation: fpFadeOut 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideRight 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeOut 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideRight 400ms cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transform: translate3d(100%, 0px, 0px);
  transform: translate3d(100%, 0px, 0px); }

.flatpickr-calendar.animate .dayContainer.slideRightNew {
  -webkit-animation: fpFadeIn 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideRightNew 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeIn 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideRightNew 400ms cubic-bezier(0.23, 1, 0.32, 1); }

.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  -webkit-flex-basis: 14.2857143%;
  -ms-flex-preferred-size: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 0;
  display: inline-block;
  position: relative;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center; }

.flatpickr-day.week.selected {
  border-radius: 0;
  box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7; }

.flatpickr-weekwrapper {
  display: inline-block;
  float: left; }

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  box-shadow: 1px 0 0 #e6e6e6; }

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px; }

.flatpickr-weekwrapper span.flatpickr-day {
  display: block;
  width: 100%;
  max-width: none; }

.flatpickr-innerContainer {
  display: block;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  box-sizing: border-box;
  overflow: hidden; }

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  box-sizing: border-box; }

.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  box-sizing: border-box;
  overflow: hidden;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }

.flatpickr-time:after {
  content: '';
  display: table;
  clear: both; }

.flatpickr-time .numInputWrapper {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 40%;
  height: 40px;
  float: left; }

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #393939; }

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #393939; }

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%; }

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%; }

.flatpickr-time input {
  background: transparent;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  cursor: pointer;
  color: #393939;
  font-size: 14px;
  position: relative;
  box-sizing: border-box; }

.flatpickr-time input.flatpickr-hour {
  font-weight: bold; }

.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: 400; }

.flatpickr-time input:focus {
  outline: 0;
  border: 0; }

.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  display: inline-block;
  float: left;
  line-height: inherit;
  color: #393939;
  font-weight: bold;
  width: 2%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center; }

.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400; }

.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time .flatpickr-am-pm:focus {
  background: #f0f0f0; }

.flatpickr-input[readonly] {
  cursor: pointer; }

@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@-webkit-keyframes fpSlideLeft {
  from {
    -webkit-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px); }
  to {
    -webkit-transform: translate3d(-100%, 0px, 0px);
    transform: translate3d(-100%, 0px, 0px); } }

@keyframes fpSlideLeft {
  from {
    -webkit-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px); }
  to {
    -webkit-transform: translate3d(-100%, 0px, 0px);
    transform: translate3d(-100%, 0px, 0px); } }

@-webkit-keyframes fpSlideLeftNew {
  from {
    -webkit-transform: translate3d(100%, 0px, 0px);
    transform: translate3d(100%, 0px, 0px); }
  to {
    -webkit-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px); } }

@keyframes fpSlideLeftNew {
  from {
    -webkit-transform: translate3d(100%, 0px, 0px);
    transform: translate3d(100%, 0px, 0px); }
  to {
    -webkit-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px); } }

@-webkit-keyframes fpSlideRight {
  from {
    -webkit-transform: translate3d(0, 0, 0px);
    transform: translate3d(0, 0, 0px); }
  to {
    -webkit-transform: translate3d(100%, 0px, 0px);
    transform: translate3d(100%, 0px, 0px); } }

@keyframes fpSlideRight {
  from {
    -webkit-transform: translate3d(0, 0, 0px);
    transform: translate3d(0, 0, 0px); }
  to {
    -webkit-transform: translate3d(100%, 0px, 0px);
    transform: translate3d(100%, 0px, 0px); } }

@-webkit-keyframes fpSlideRightNew {
  from {
    -webkit-transform: translate3d(-100%, 0, 0px);
    transform: translate3d(-100%, 0, 0px); }
  to {
    -webkit-transform: translate3d(0, 0, 0px);
    transform: translate3d(0, 0, 0px); } }

@keyframes fpSlideRightNew {
  from {
    -webkit-transform: translate3d(-100%, 0, 0px);
    transform: translate3d(-100%, 0, 0px); }
  to {
    -webkit-transform: translate3d(0, 0, 0px);
    transform: translate3d(0, 0, 0px); } }

@-webkit-keyframes fpFadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fpFadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@-webkit-keyframes fpFadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fpFadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.bx--date-picker {
  display: flex;
  align-items: flex-start; }

.bx--date-picker--light .bx--date-picker__input {
  background: #fff; 
}

.bx--date-picker ~ .bx--label {
  order: 1; }

.bx--date-picker-container {
  position: relative;
  display: flex;
  flex-direction: column; }

.bx--date-picker.bx--date-picker--simple .bx--date-picker__input {
  width: 7.125rem; }

.bx--date-picker.bx--date-picker--simple.bx--date-picker--short .bx--date-picker__input {
  width: 5.7rem; }

.bx--date-picker.bx--date-picker--single .bx--date-picker__input {
  width: 9rem; }

.bx--date-picker__input {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  font-size: 0.875rem;
  display: block;
  position: relative;
  height: 2.5rem;
  max-width: 9rem;
  padding: 0 1rem;
  /* background-color: #f4f7fb; */
  background: #cfdbe659;
  border: none;
  box-shadow: 0 1px 0 0 #5a6872;
  order: 2;
  color: #152934; }
  .bx--date-picker__input:focus, .bx--date-picker__input.bx--focused {
    outline: none;
    box-shadow: 0 2px 0 0 #3d70b2; }
  .bx--date-picker__input:focus ~ .bx--label {
    color: #3d70b2; }
  .bx--date-picker__input[data-invalid], .bx--date-picker__input[data-invalid]:focus {
    box-shadow: 0 2px 0 0 #e0182d; }
  .bx--date-picker__input[data-invalid]:focus + .bx--label {
    color: #e0182d; }
  .bx--date-picker__input ~ .bx--form-requirement {
    order: 3;
    color: #e0182d;
    font-weight: 400;
    margin-top: 0.25rem;
    overflow: visible; }
    .bx--date-picker__input ~ .bx--form-requirement::before {
      display: none; }
  .bx--date-picker__input:disabled {
    opacity: 0.5;
    cursor: not-allowed; }
  .bx--date-picker__input:disabled:hover {
    border: 1px solid transparent; }
  .bx--date-picker__input::-webkit-input-placeholder {
    color: #cdd1d4; }

.bx--date-picker__icon {
  position: absolute;
  /* top: 2.25rem; */
  left: 1rem;
  fill: #3d70b2;
  cursor: pointer;
  z-index: 1; }
  .bx--date-picker__icon:hover {
    fill: #30588c; }

.bx--date-picker__icon + .bx--date-picker__input {
  padding-left: 3rem; }

.bx--date-picker--range {
  display: flex;
  position: relative; }

.bx--date-picker--range > .bx--date-picker-container:first-child {
  margin-right: 0.5rem; }

.bx--date-picker--range .bx--date-picker__icon {
  right: -1.75rem;
  left: auto; }

.bx--date-picker--range .bx--date-picker__input {
  width: 7.075rem; }

.bx--date-picker__calendar,
.flatpickr-calendar.open {
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 1rem 0.25rem;
  width: 17.8125rem !important;
  height: 14.375rem;
  border-radius: 0;
  border: none;
  overflow: hidden;
  margin-top: 1px; }
  .bx--date-picker__calendar:before, .bx--date-picker__calendar:after,
  .flatpickr-calendar.open:before,
  .flatpickr-calendar.open:after {
    display: none; }
  .bx--date-picker__calendar:focus,
  .flatpickr-calendar.open:focus {
    outline: 1px solid #3d70b2; }

.bx--date-picker__month,
.flatpickr-month {
  width: 100%;
  margin-bottom: 0.25rem; }

.bx--date-picker__month .flatpickr-prev-month,
.bx--date-picker__month .flatpickr-next-month,
.flatpickr-month .flatpickr-prev-month,
.flatpickr-month .flatpickr-next-month {
  padding: 0;
  line-height: 1.25rem;
  fill: #152934; }
  .bx--date-picker__month .flatpickr-prev-month:hover svg,
  .bx--date-picker__month .flatpickr-next-month:hover svg,
  .flatpickr-month .flatpickr-prev-month:hover svg,
  .flatpickr-month .flatpickr-next-month:hover svg {
    fill: #3d70b2; }

.bx--date-picker__month .flatpickr-current-month,
.flatpickr-month .flatpickr-current-month {
  font-size: 0.75rem;
  text-transform: uppercase;
  padding: 0; }

.bx--date-picker__month .flatpickr-current-month svg,
.flatpickr-month .flatpickr-current-month svg {
  fill: #152934; }

.bx--date-picker__month .flatpickr-current-month .cur-month,
.flatpickr-month .flatpickr-current-month .cur-month {
  margin-right: 0.25rem;
  color: #152934; }

.numInputWrapper,
.flatpickr-current-month .numInputWrapper {
  min-width: 2.375rem;
  width: 2.375rem; }

.bx--date-picker__month .numInputWrapper .numInput,
.flatpickr-month .numInputWrapper .numInput {
  font-weight: 600;
  color: #152934;
  background-color: #f4f7fb;
  border: none;
  border-radius: 0;
  min-width: 2.375rem;
  width: 2.375rem;
  padding: 0.25rem; }
  .bx--date-picker__month .numInputWrapper .numInput:focus,
  .flatpickr-month .numInputWrapper .numInput:focus {
    outline: 1px solid #3d70b2; }

.bx--date-picker__month .numInputWrapper span.arrowUp,
.bx--date-picker__month .numInputWrapper span.arrowDown,
.flatpickr-month .numInputWrapper span.arrowUp,
.flatpickr-month .numInputWrapper span.arrowDown {
  left: 2.6rem;
  border: none;
  width: 0.75rem; }
  .bx--date-picker__month .numInputWrapper span.arrowUp:hover,
  .bx--date-picker__month .numInputWrapper span.arrowDown:hover,
  .flatpickr-month .numInputWrapper span.arrowUp:hover,
  .flatpickr-month .numInputWrapper span.arrowDown:hover {
    background: none; }
    .bx--date-picker__month .numInputWrapper span.arrowUp:hover:after,
    .bx--date-picker__month .numInputWrapper span.arrowDown:hover:after,
    .flatpickr-month .numInputWrapper span.arrowUp:hover:after,
    .flatpickr-month .numInputWrapper span.arrowDown:hover:after {
      border-bottom-color: #5596e6;
      border-top-color: #5596e6; }
  .bx--date-picker__month .numInputWrapper span.arrowUp:after,
  .bx--date-picker__month .numInputWrapper span.arrowDown:after,
  .flatpickr-month .numInputWrapper span.arrowUp:after,
  .flatpickr-month .numInputWrapper span.arrowDown:after {
    border-bottom-color: #3d70b2;
    border-top-color: #3d70b2; }

.bx--date-picker__month .numInputWrapper span.arrowUp,
.flatpickr-month .numInputWrapper span.arrowUp {
  top: 1px; }

.bx--date-picker__month .numInputWrapper span.arrowDown,
.flatpickr-month .numInputWrapper span.arrowDown {
  top: 9px; }

span.bx--date-picker__weekday,
span.flatpickr-weekday {
  font-size: 0.75rem;
  font-weight: 600;
  color: #152934; }

.bx--date-picker__day,
.flatpickr-day {
  font-size: 0.75rem;
  height: 1.5625rem;
  width: 1.8rem;
  line-height: 1.5625rem;
  flex-basis: 1.8rem;
  margin: 0.03125rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #152934;
  border-radius: 0;
  border: 2px solid transparent; }
  .bx--date-picker__day:hover,
  .flatpickr-day:hover {
    background: rgba(85, 150, 230, 0.1); }
  .bx--date-picker__day:focus,
  .flatpickr-day:focus {
    outline: none;
    background: #dfe3e6; }

.bx--date-picker__day.today,
.flatpickr-day.today {
  position: relative; }
  .bx--date-picker__day.today::after,
  .flatpickr-day.today::after {
    content: '';
    position: absolute;
    display: block;
    top: 90%;
    left: 50%;
    transform: translateX(-50%);
    height: 3px;
    width: 3px;
    border-radius: 50%;
    background: #3d70b2; }

.bx--date-picker__day.today.no-border,
.flatpickr-day.today.no-border {
  border: none; }

.bx--date-picker__day.today.selected {
  border: 2px solid #3d70b2; }
  .bx--date-picker__day.today.selected::after {
    display: none; }

.bx--date-picker__day.disabled,
.flatpickr-day.disabled {
  cursor: not-allowed;
  color: #5a6872; }
  .bx--date-picker__day.disabled:hover,
  .flatpickr-day.disabled:hover {
    background: transparent; }

.bx--date-picker__day.inRange,
.flatpickr-day.inRange {
  background: #f4f7fb;
  box-shadow: -6px 0 0 #f4f7fb, 6px 0 0 #f4f7fb; }

.bx--date-picker__day.selected,
.flatpickr-day.selected {
  border: 2px solid #3d70b2;
  background: #fff; }

.bx--date-picker__day.startRange.selected,
.flatpickr-day.startRange.selected {
  box-shadow: none;
  z-index: 2; }

.bx--date-picker__day.endRange.inRange,
.flatpickr-day.endRange.inRange {
  background: #fff;
  color: #152934;
  border: 2px solid #3d70b2;
  z-index: 3;
  box-shadow: none; }

.bx--date-picker__day.endRange.inRange.selected,
.flatpickr-day.endRange.inRange.selected {
  box-shadow: none;
  border: 2px solid #3d70b2;
  background: #fff; }

.bx--date-picker__day.startRange.inRange:not(.selected),
.flatpickr-day.startRange.inRange:not(.selected) {
  box-shadow: none;
  background: #fff;
  border: 2px solid #3d70b2;
  z-index: 3; }

.bx--date-picker__days,
.dayContainer {
  width: 14.0625rem;
  min-width: 14.0625rem;
  max-width: 14.0625rem;
  height: 8.25rem; }

.flatpickr-innerContainer,
.flatpickr-rContainer {
  width: 14.0625rem;
  height: 10.5rem; }

.bx--date-picker__weekdays,
.flatpickr-weekdays,
.flatpickr-weekdaycontainer {
  width: 14.0625rem;
  margin-bottom: 0.25rem; }

.flatpickr-weekdaycontainer {
  display: flex; }

.flatpickr-months {
  display: flex;
  width: 100%;
  position: relative; }

.flatpickr-prev-month,
.flatpickr-next-month {
  padding-top: 5px; }

.bx--date-picker.bx--skeleton input,
.bx--date-picker__input.bx--skeleton {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: rgba(61, 112, 178, 0.1);
  width: 100%; }
  .bx--date-picker.bx--skeleton input:hover, .bx--date-picker.bx--skeleton input:focus, .bx--date-picker.bx--skeleton input:active,
  .bx--date-picker__input.bx--skeleton:hover,
  .bx--date-picker__input.bx--skeleton:focus,
  .bx--date-picker__input.bx--skeleton:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--date-picker.bx--skeleton input:before,
  .bx--date-picker__input.bx--skeleton:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    background: rgba(61, 112, 178, 0.1);
    animation: 3000ms ease-in-out skeleton infinite; }
  .bx--date-picker.bx--skeleton input::-webkit-input-placeholder,
  .bx--date-picker__input.bx--skeleton::-webkit-input-placeholder {
    color: transparent; }

.bx--date-picker.bx--skeleton .bx--label {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: rgba(61, 112, 178, 0.1);
  width: 4.6875rem;
  height: 0.875rem; }
  .bx--date-picker.bx--skeleton .bx--label:hover, .bx--date-picker.bx--skeleton .bx--label:focus, .bx--date-picker.bx--skeleton .bx--label:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--date-picker.bx--skeleton .bx--label:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    background: rgba(61, 112, 178, 0.1);
    animation: 3000ms ease-in-out skeleton infinite; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--text-truncate--end {
  width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.bx--text-truncate--front {
  width: 100%;
  display: inline-block;
  direction: rtl;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.bx--dropdown {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  font-size: 0.875rem;
  position: relative;
  list-style: none;
  display: block;
  background-color: #f4f7fb;
  border: none;
  box-shadow: 0 1px 0 0 #5a6872;
  order: 1;
  width: 100%;
  height: 2.5rem;
  cursor: pointer;
  color: #152934; }
  .bx--dropdown:focus {
    outline: none;
    box-shadow: 0 2px 0 0 #3d70b2; }
  .bx--dropdown.bx--dropdown--open:focus {
    outline: 1px solid transparent;
    box-shadow: none; }
  .bx--dropdown .bx--dropdown--open .bx--dropdown-list {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1); }

.bx--dropdown--light {
  background-color: #fff; }

.bx--dropdown--up .bx--dropdown-list {
  bottom: 2.5rem; }

.bx--dropdown__arrow {
  fill: #3d70b2;
  position: absolute;
  right: 1rem;
  top: 1.175rem;
  width: 0.625rem;
  height: 0.3125rem;
  pointer-events: none;
  transition: transform 300ms cubic-bezier(0.5, 0, 0.1, 1);
  transform-origin: 50% 45%; }

.bx--dropdown[data-value=''] .bx--dropdown-text {
  color: #152934; }

.bx--dropdown-text {
  height: 2.5rem;
  padding-top: 0.8125rem;
  padding-bottom: 0.8125rem;
  padding-left: 1rem;
  padding-right: 2.5rem;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid transparent; }

.bx--dropdown-list {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  font-size: 0.875rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: 100%;
  list-style: none;
  position: absolute;
  z-index: 7000;
  max-height: 0;
  transition: max-height 300ms cubic-bezier(0, 0, 0.25, 1);
  overflow: hidden; }

.bx--dropdown-item {
  transition: opacity 300ms cubic-bezier(0, 0, 0.25, 1);
  opacity: 0; }

.bx--dropdown-link {
  display: block;
  color: currentColor;
  text-decoration: none;
  font-weight: normal;
  padding: 1rem 1.5rem 1rem 1rem;
  text-overflow: ellipsis;
  overflow: hidden; }
  .bx--dropdown-link:hover, .bx--dropdown-link:focus {
    background-color: rgba(85, 150, 230, 0.1);
    outline: 1px solid transparent;
    text-decoration: underline;
    color: #152934; }

.bx--dropdown--selected {
  display: none; }

.bx--dropdown--open .bx--dropdown__arrow {
  transform: rotate(-180deg); }

.bx--dropdown--open .bx--dropdown-list {
  max-height: 15rem; }
  .bx--dropdown--open .bx--dropdown-list:hover {
    overflow: auto; }

.bx--dropdown--open .bx--dropdown-item {
  opacity: 1; }

.bx--dropdown--disabled {
  opacity: 0.5;
  cursor: not-allowed; }
  .bx--dropdown--disabled:focus {
    outline: none; }

.bx--dropdown--inline {
  background-color: transparent;
  box-shadow: none; }
  .bx--dropdown--inline:focus {
    outline: none;
    box-shadow: none; }
  .bx--dropdown--inline:focus .bx--dropdown-text {
    outline: 1px solid #3d70b2; }
  .bx--dropdown--inline[data-value=''] .bx--dropdown-text {
    color: #3d70b2; }
  .bx--dropdown--inline .bx--dropdown-text {
    display: inline-block;
    padding-right: 1.5rem;
    overflow: visible;
    color: #3d70b2; }
  .bx--dropdown--inline .bx--dropdown-text:hover {
    background-color: #f4f7fb; }
  .bx--dropdown--inline.bx--dropdown--open:focus {
    box-shadow: none; }
  .bx--dropdown--inline.bx--dropdown--open:focus .bx--dropdown-list {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1); }
  .bx--dropdown--inline.bx--dropdown--open:focus .bx--dropdown-text {
    outline: none; }
  .bx--dropdown--inline .bx--dropdown__arrow {
    position: relative;
    top: -2px;
    left: 8px;
    right: 0;
    bottom: 0; }
  .bx--dropdown--inline .bx--dropdown-link {
    font-weight: normal; }
  .bx--dropdown--inline .bx--dropdown-link:hover {
    background-color: #f4f7fb;
    color: #152934; }

.bx--dropdown-v2.bx--skeleton {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: rgba(61, 112, 178, 0.1); }
  .bx--dropdown-v2.bx--skeleton:hover, .bx--dropdown-v2.bx--skeleton:focus, .bx--dropdown-v2.bx--skeleton:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--dropdown-v2.bx--skeleton:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    background: rgba(61, 112, 178, 0.1);
    animation: 3000ms ease-in-out skeleton infinite; }

@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes rotate-end-p1 {
  100% {
    transform: rotate(360deg); } }

@keyframes rotate-end-p2 {
  100% {
    transform: rotate(-360deg); } }

/* Stroke animations */
@keyframes init-stroke {
  0% {
    stroke-dashoffset: 240; }
  100% {
    stroke-dashoffset: 40; } }

@keyframes stroke-end {
  0% {
    stroke-dashoffset: 40; }
  100% {
    stroke-dashoffset: 240; } }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--text-truncate--end {
  width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.bx--text-truncate--front {
  width: 100%;
  display: inline-block;
  direction: rtl;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--modal {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
  content: '';
  opacity: 0;
  background-color: rgba(223, 227, 230, 0.5);
  transition: opacity 200ms, z-index 0s 200ms, visibility 0s 200ms;
  visibility: hidden; }
  .bx--modal.is-visible {
    z-index: 9000;
    opacity: 1;
    transition: opacity 200ms;
    visibility: visible; }

.bx--modal--danger .bx--modal-container {
  border-top-color: #e0182d; }

.bx--modal-container {
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-top: #3d70b2 4px solid;
  min-width: 100%;
  max-height: 100%;
  height: 100%;
  padding: 2rem 3% 0rem 3%; }
  @media (min-width: 600px) {
    .bx--modal-container {
      height: auto;
      min-width: 500px;
      max-width: 75%;
      max-height: 90%;
      padding: 2.5rem 3rem 0 3rem; } }
  @media (min-width: 1024px) {
    .bx--modal-container {
      max-width: 50%;
      max-height: 80%; } }

.bx--modal-header {
  margin-bottom: 1.5rem; }

.bx--modal-header__label {
  font-size: 0.75rem;
  letter-spacing: 0;
  color: #152934;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0.5rem; }

.bx--modal-header__heading {
  font-size: 1.75rem;
  font-weight: 300;
  color: #5a6872; }

.bx--modal-content {
  overflow-y: auto;
  margin-bottom: 3rem; }

.bx--modal-footer {
  margin-top: auto;
  text-align: right;
  background-color: #f4f7fb;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  padding: 2rem 2rem; }
  @media (min-width: 600px) {
    .bx--modal-footer {
      margin-left: -3rem;
      margin-right: -3rem;
      padding: 2rem 3rem; } }

.bx--modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0;
  overflow: hidden;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0.25rem 0.25rem 0.125rem; }
  .bx--modal-close:focus {
    outline: 1px solid #3d70b2; }

.bx--modal-close__icon {
  fill: #5a6872; }

.bx--text-truncate--end {
  width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.bx--text-truncate--front {
  width: 100%;
  display: inline-block;
  direction: rtl;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.bx--text-truncate--end {
  width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.bx--text-truncate--front {
  width: 100%;
  display: inline-block;
  direction: rtl;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.bx--multi-select.bx--combo-box > .bx--list-box__field {
  padding: 0 1rem; }

.bx--multi-select.bx--combo-box input[role='combobox'] {
  padding: 0;
  outline: none; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--inline-notification {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.875rem;
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  padding: 0.75rem 1rem;
  min-height: 2.5rem;
  color: #5a6872;
  margin-top: 1rem;
  margin-bottom: 1rem; }

.bx--inline-notification--error {
  border: 1px solid #e0182d;
  border-left: 6px solid #e0182d; }
  .bx--inline-notification--error .bx--inline-notification__icon {
    fill: #e0182d; }

.bx--inline-notification--success {
  border: 1px solid #5aa700;
  border-left: 6px solid #5aa700; }
  .bx--inline-notification--success .bx--inline-notification__icon {
    fill: #5aa700; }

.bx--inline-notification--info {
  border: 1px solid #5aaafa;
  border-left: 6px solid #5aaafa; }
  .bx--inline-notification--info .bx--inline-notification__icon {
    fill: #5aaafa; }

.bx--inline-notification--warning {
  border: 1px solid #efc100;
  border-left: 6px solid #efc100; }
  .bx--inline-notification--warning .bx--inline-notification__icon {
    fill: #efc100; }

.bx--inline-notification__details {
  display: flex;
  align-items: center; }

.bx--inline-notification__icon {
  height: 16px;
  width: 16px;
  min-width: 22px; }

.bx--inline-notification__text-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0 1rem; }
  @media (max-width: 640px) {
    .bx--inline-notification__text-wrapper {
      flex-direction: column;
      width: 100%; } }

.bx--inline-notification__title {
  font-size: 0.875rem;
  font-weight: 600;
  margin: 0 0.25rem 0 0;
  line-height: 1.125; }

.bx--inline-notification__subtitle {
  font-size: 0.875rem;
  line-height: 1.125; }

.bx--inline-notification__close-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  height: 16px;
  width: 12px;
  position: relative; }
  .bx--inline-notification__close-button:focus {
    outline: 1px solid #3d70b2; }

.bx--inline-notification__close-icon {
  height: 10px;
  width: 10px;
  fill: #5a6872;
  position: absolute;
  top: 3px;
  right: 1px; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--toast-notification {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  width: 16.875rem;
  padding: 1rem 1rem 0.5rem 1rem;
  background-color: #fff;
  color: #152934;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 1rem; }
  .bx--toast-notification:first-child {
    margin-top: 1rem; }

.bx--toast-notification--error {
  border-left: 6px solid #e0182d; }

.bx--toast-notification--success {
  border-left: 6px solid #5aa700; }

.bx--toast-notification--info {
  border-left: 6px solid #5aaafa; }

.bx--toast-notification--warning {
  border-left: 6px solid #efc100; }

.bx--toast-notification__close-button {
  background-color: #fff;
  border: none;
  cursor: pointer;
  margin: 0;
  width: 12px;
  height: 12px;
  position: relative; }
  .bx--toast-notification__close-button:focus {
    outline: 1px solid #3d70b2; }

.bx--toast-notification__icon {
  height: 10px;
  width: 10px;
  fill: #5a6872;
  position: relative;
  top: 1px;
  right: 1px; 
}

.bx--toast-notification__title {
  font-size: 0.875rem;
  letter-spacing: 0;
  font-weight: 600;
  line-height: 1;
  padding-bottom: 0.125rem; }

.bx--toast-notification__subtitle {
  font-size: 0.75rem;
  color: #5a6872;
  margin-top: 0;
  margin-bottom: 1rem;
  line-height: 1.2; }

.bx--toast-notification__caption {
  font-size: 0.75rem;
  color: #5a6872;
  line-height: 1; }

.bx--toast-notification--error .bx--inline-notification__icon, .bx--toast-notification--error .bx--toast-notification__icon {
  fill: var(--cds-inverse-support-01,#fa4d56);
}
  
.bx--toast-notification__icon {
  flex-shrink: 0;
  margin-right: .875rem;
  margin-top: .875rem;
}

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--tooltip--icon {
  display: flex;
  align-items: center; }

.bx--tooltip__label {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  color: #152934;
  font-weight: normal; }

.bx--tooltip__trigger {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0;
  display: inline-block;
  background: none;
  appearance: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin-left: 0.5rem;
  cursor: pointer;
  font-size: 1rem; }
  .bx--tooltip__trigger::-moz-focus-inner {
    border: 0; }
  .bx--tooltip__trigger:focus {
    outline: 1px solid #3d70b2;
    fill: #30588c; }
  .bx--tooltip__trigger path,
  .bx--tooltip__trigger polygon,
  .bx--tooltip__trigger circle {
    fill: #3d70b2; }
  .bx--tooltip__trigger:hover, .bx--tooltip__trigger:focus {
    color: #152934; }
    .bx--tooltip__trigger:hover path,
    .bx--tooltip__trigger:hover polygon,
    .bx--tooltip__trigger:hover circle, .bx--tooltip__trigger:focus path,
    .bx--tooltip__trigger:focus polygon,
    .bx--tooltip__trigger:focus circle {
      fill: #30588c; }

.bx--tooltip__label--bold {
  font-weight: 600; }

.bx--tooltip {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  display: none;
  max-width: 15rem;
  background: #fff;
  padding: 1rem;
  border: 1px solid #dfe3e6;
  border-radius: 0.25rem;
  z-index: 10000;
  word-wrap: break-word; }
  .bx--tooltip p {
    font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
    font-size: 0.875rem; }
  .bx--tooltip div {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 1rem; }
  .bx--tooltip .bx--tooltip__caret {
    position: absolute;
    background: #fff;
    left: 0;
    top: -0.3125rem;
    right: 0;
    transform: rotate(-135deg);
    width: 0.6rem;
    height: 0.6rem;
    border-right: 1px solid #dfe3e6;
    border-bottom: 1px solid #dfe3e6;
    margin: 0 auto;
    content: ''; }
  .bx--tooltip[data-floating-menu-direction='left'] .bx--tooltip__caret {
    left: auto;
    top: 50%;
    right: -0.3125rem;
    transform: rotate(-45deg) translate(50%, -50%); }
  .bx--tooltip[data-floating-menu-direction='top'] .bx--tooltip__caret {
    top: auto;
    bottom: -0.375rem;
    transform: rotate(45deg); }
  .bx--tooltip[data-floating-menu-direction='right'] .bx--tooltip__caret {
    left: -0.3125rem;
    top: 50%;
    right: auto;
    transform: rotate(135deg) translate(-50%, 50%); }

.bx--tooltip--shown {
  display: block; }

.bx--tooltip--definition {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  font-size: 1.125rem;
  position: relative; }
  .bx--tooltip--definition .bx--tooltip__trigger {
    display: inline-flex;
    position: relative;
    border-bottom: 2px dotted #8897a2; }
    .bx--tooltip--definition .bx--tooltip__trigger:hover {
      border-bottom: 2px dotted #30588c;
      cursor: pointer; }
      .bx--tooltip--definition .bx--tooltip__trigger:hover + .bx--tooltip--definition__top,
      .bx--tooltip--definition .bx--tooltip__trigger:hover + .bx--tooltip--definition__bottom {
        display: block; }
    .bx--tooltip--definition .bx--tooltip__trigger:focus {
      outline: 1px solid #3d70b2; }
      .bx--tooltip--definition .bx--tooltip__trigger:focus + .bx--tooltip--definition__top,
      .bx--tooltip--definition .bx--tooltip__trigger:focus + .bx--tooltip--definition__bottom {
        display: block; }

.bx--tooltip--definition__bottom,
.bx--tooltip--definition__top {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 1;
  display: none;
  background: #272d33;
  max-width: 11rem;
  margin-top: 0.75rem;
  padding: 0.5rem;
  border-radius: 0.25rem;
  pointer-events: none;
  cursor: pointer; }
  .bx--tooltip--definition__bottom p,
  .bx--tooltip--definition__top p {
    font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
    font-size: 0.75rem;
    color: #fff; }
  .bx--tooltip--definition__bottom .bx--tooltip__caret,
  .bx--tooltip--definition__top .bx--tooltip__caret {
    position: absolute;
    right: 0;
    left: 0;
    width: 0.6rem;
    height: 0.6rem;
    background: #272d33;
    margin-left: 1.5rem; }

.bx--tooltip--definition__bottom .bx--tooltip__caret {
  top: -0.2rem;
  transform: rotate(-135deg); }

.bx--tooltip--definition__top {
  transform: translateY(-100%);
  margin-top: -2rem; }
  .bx--tooltip--definition__top .bx--tooltip__caret {
    bottom: -0.2rem;
    transform: rotate(45deg); }

.bx--tooltip--icon__top,
.bx--tooltip--icon__bottom {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  overflow: visible; }
  .bx--tooltip--icon__top path,
  .bx--tooltip--icon__bottom path {
    fill: #3d70b2; }
  .bx--tooltip--icon__top:before, .bx--tooltip--icon__top:after,
  .bx--tooltip--icon__bottom:before,
  .bx--tooltip--icon__bottom:after {
    font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
    position: absolute;
    display: none;
    background-color: #272d33; }
  .bx--tooltip--icon__top:before,
  .bx--tooltip--icon__bottom:before {
    font-size: 0.75rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    max-width: 11rem;
    margin-left: 50%;
    padding: 0.25rem;
    border-radius: 4px;
    color: #fff;
    font-weight: 400;
    content: attr(title);
    transform: translateX(-50%);
    white-space: nowrap;
    pointer-events: none; }
  .bx--tooltip--icon__top:after,
  .bx--tooltip--icon__bottom:after {
    right: 0;
    left: 0;
    width: 0.6rem;
    height: 0.6rem;
    margin: 0 auto;
    content: '';
    margin-top: 1px; }
  .bx--tooltip--icon__top:hover path, .bx--tooltip--icon__top:focus path,
  .bx--tooltip--icon__bottom:hover path,
  .bx--tooltip--icon__bottom:focus path {
    fill: #30588c; }
  .bx--tooltip--icon__top:hover:before, .bx--tooltip--icon__top:hover:after, .bx--tooltip--icon__top:focus:before, .bx--tooltip--icon__top:focus:after,
  .bx--tooltip--icon__bottom:hover:before,
  .bx--tooltip--icon__bottom:hover:after,
  .bx--tooltip--icon__bottom:focus:before,
  .bx--tooltip--icon__bottom:focus:after {
    position: absolute;
    display: block; }
  .bx--tooltip--icon__top:focus,
  .bx--tooltip--icon__bottom:focus {
    outline: 1px solid transparent; }
    .bx--tooltip--icon__top:focus svg,
    .bx--tooltip--icon__bottom:focus svg {
      outline: 1px solid #3d70b2; }

.bx--tooltip--icon__top:before {
  bottom: 1.8rem; }

.bx--tooltip--icon__top:after {
  bottom: 1.5rem;
  transform: rotate(45deg); }

.bx--tooltip--icon__bottom:before {
  top: 1.8rem; }

.bx--tooltip--icon__bottom:after {
  top: 1.5rem;
  transform: rotate(-135deg); }

.bx--tooltip--icon .bx--tooltip__trigger svg {
  margin-left: 0; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--text-truncate--end {
  width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.bx--text-truncate--front {
  width: 100%;
  display: inline-block;
  direction: rtl;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--tabs {
  font-size: 0.875rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  color: #152934;
  font-weight: 600;
  height: auto;
  width: 100%;
  position: relative; }
  @media screen and (min-width: 768px) {
    .bx--tabs {
      background: none;
      min-height: 3.0625rem; } }

.bx--tabs-trigger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  height: 2.5rem;
  cursor: pointer;
  background-color: #fff; }
  .bx--tabs-trigger svg {
    width: 0.75rem;
    height: 0.75rem;
    fill: #3d70b2; }
  .bx--tabs-trigger:focus {
    outline: 1px solid #3d70b2; }
  @media screen and (min-width: 768px) {
    .bx--tabs-trigger {
      display: none; } }

.bx--tabs-trigger-text {
  text-decoration: none;
  font-weight: 600;
  color: #152934; }
  .bx--tabs-trigger-text:focus {
    outline: 1px solid #3d70b2; }

.bx--tabs__nav {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  z-index: 7000;
  background: #fff; }
  @media screen and (min-width: 768px) {
    .bx--tabs__nav {
      font-size: 1rem;
      flex-direction: row;
      margin-right: 1rem;
      margin-left: 1rem;
      background: none;
      box-shadow: none;
      z-index: auto; } }
  @media screen and (min-width: 1200px) {
    .bx--tabs__nav {
      margin-left: 0; } }

.bx--tabs__nav--hidden {
  display: none; }
  @media screen and (min-width: 768px) {
    .bx--tabs__nav--hidden {
      display: flex; } }

.bx--tabs__nav-item {
  font-size: 0.875rem;
  background-color: #fff;
  padding: 0;
  cursor: pointer; }
  .bx--tabs__nav-item:hover, .bx--tabs__nav-item:focus {
    background-color: rgba(85, 150, 230, 0.1); }
    @media screen and (min-width: 768px) {
      .bx--tabs__nav-item:hover, .bx--tabs__nav-item:focus {
        outline: 1px solid transparent;
        background: transparent; } }
  @media screen and (min-width: 768px) {
    .bx--tabs__nav-item {
      background: transparent;
      padding: 0.75rem 0 0.75rem; }
      .bx--tabs__nav-item + .bx--tabs__nav-item {
        margin-left: 3rem; } }

.bx--tabs__nav-item--selected {
  border: none; }
  @media screen and (min-width: 768px) {
    .bx--tabs__nav-item--selected {
      border-bottom: 2px solid #3d70b2; }
      .bx--tabs__nav-item--selected .bx--tabs__nav-link {
        color: #3d70b2; }
        .bx--tabs__nav-item--selected .bx--tabs__nav-link:focus {
          color: #3d70b2; } }

.bx--tabs__nav-item:hover .bx--tabs__nav-link {
  color: #152934; }
  @media screen and (min-width: 768px) {
    .bx--tabs__nav-item:hover .bx--tabs__nav-link {
      color: #3d70b2; } }

.bx--tabs__nav-link {
  display: inline-block;
  color: #152934;
  text-decoration: none;
  padding: 1rem;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis; }
  .bx--tabs__nav-link:focus {
    outline: 1px solid transparent;
    background-color: #3d70b2;
    color: #fff; }
  @media screen and (min-width: 768px) {
    .bx--tabs__nav-link {
      padding: 0 0.125rem;
      width: auto; }
      .bx--tabs__nav-link:hover {
        color: #3d70b2; }
      .bx--tabs__nav-link:focus {
        background-color: transparent;
        color: #152934;
        outline: 1px solid transparent;
        box-shadow: 0 0 0 1px #3d70b2; } }

.bx--tabs.bx--skeleton {
  pointer-events: none;
  cursor: default; }

.bx--tabs.bx--skeleton .bx--tabs__nav-link {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: rgba(61, 112, 178, 0.1);
  width: 4.6875rem;
  height: 0.75rem; }
  .bx--tabs.bx--skeleton .bx--tabs__nav-link:hover, .bx--tabs.bx--skeleton .bx--tabs__nav-link:focus, .bx--tabs.bx--skeleton .bx--tabs__nav-link:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--tabs.bx--skeleton .bx--tabs__nav-link:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    background: rgba(61, 112, 178, 0.1);
    animation: 3000ms ease-in-out skeleton infinite; }

.bx--tabs.bx--skeleton .bx--tabs-trigger {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: rgba(61, 112, 178, 0.1);
  width: 6.25rem; }
  .bx--tabs.bx--skeleton .bx--tabs-trigger:hover, .bx--tabs.bx--skeleton .bx--tabs-trigger:focus, .bx--tabs.bx--skeleton .bx--tabs-trigger:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--tabs.bx--skeleton .bx--tabs-trigger:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    background: rgba(61, 112, 178, 0.1);
    animation: 3000ms ease-in-out skeleton infinite; }

.bx--tabs.bx--skeleton .bx--tabs-trigger svg {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  visibility: visible;
  white-space: nowrap; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--tag {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  font-size: 0.75rem;
  display: inline-flex;
  align-items: center;
  padding: 0 0.5rem;
  height: 1.25rem;
  margin: 0.125rem;
  border-radius: 0.9375rem; }
  .bx--tag:not(:first-child) {
    margin-left: 0; }

.bx--tag--ibm {
  background-color: #c0e6ff;
  color: #325c80; }

.bx--tag--beta {
  background-color: #dfe3e6;
  color: #394b54; }

.bx--tag--third-party {
  background-color: #a7fae6;
  color: #006d5d; }

.bx--tag--local,
.bx--tag--dedicated,
.bx--tag--custom {
  background-color: #eed2ff;
  color: #734098; }

.bx--tag--experimental {
  background-color: #ffd4a0;
  color: #a53725; }

.bx--tag--community {
  background-color: #c8f08f;
  color: #2d660a; }

.bx--tag--private {
  background-color: #fde876;
  color: #735f00; }

.bx--tag.bx--skeleton {
  background-color: #dfe3e6;
  color: #394b54;
  width: 3.75rem; }
  .bx--tag.bx--skeleton:after {
    position: relative;
    border: none;
    padding: 0;
    box-shadow: none;
    pointer-events: none;
    background: rgba(61, 112, 178, 0.1);
    content: '';
    height: 0.375rem;
    width: 100%; }
    .bx--tag.bx--skeleton:after:hover, .bx--tag.bx--skeleton:after:focus, .bx--tag.bx--skeleton:after:active {
      border: none;
      outline: none;
      cursor: default; }
    .bx--tag.bx--skeleton:after:before {
      content: '';
      width: 0%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0.3;
      background: rgba(61, 112, 178, 0.1);
      animation: 3000ms ease-in-out skeleton infinite; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--text-truncate--end {
  width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.bx--text-truncate--front {
  width: 100%;
  display: inline-block;
  direction: rtl;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--left-nav__trigger {
  width: 3.75rem;
  height: 3.125rem;
  position: relative;
  transition: 250ms cubic-bezier(0.5, 0, 0.1, 1);
  cursor: pointer;
  z-index: 9999; }
  .bx--left-nav__trigger:focus {
    outline: 0; }
  .bx--left-nav__trigger:hover, .bx--left-nav__trigger:focus {
    background-color: #5aaafa; }
    .bx--left-nav__trigger:hover span,
    .bx--left-nav__trigger:hover span:before,
    .bx--left-nav__trigger:hover span:after, .bx--left-nav__trigger:focus span,
    .bx--left-nav__trigger:focus span:before,
    .bx--left-nav__trigger:focus span:after {
      background: #fff; }

.bx--left-nav__trigger--btn {
  position: absolute;
  top: 1.5rem;
  left: 1.25rem;
  width: 100%;
  transition: 250ms cubic-bezier(0.5, 0, 0.1, 1);
  display: flex; }
  .bx--left-nav__trigger--btn span,
  .bx--left-nav__trigger--btn span:before,
  .bx--left-nav__trigger--btn span:after {
    height: 0.125rem;
    width: 1.25rem;
    cursor: pointer;
    position: absolute;
    display: block;
    content: '';
    transition: all 250ms ease; }
  .bx--left-nav__trigger--btn span:before {
    top: -0.375rem; }
  .bx--left-nav__trigger--btn span:after {
    bottom: -0.375rem; }
  .bx--left-nav__trigger--active .bx--left-nav__trigger--btn {
    left: 1.125rem; }
    .bx--left-nav__trigger--active .bx--left-nav__trigger--btn span:before,
    .bx--left-nav__trigger--active .bx--left-nav__trigger--btn span:after {
      top: 0;
      width: 1.59125rem; }
    .bx--left-nav__trigger--active .bx--left-nav__trigger--btn span:before {
      transform: rotate(45deg); }
    .bx--left-nav__trigger--active .bx--left-nav__trigger--btn span:after {
      transform: rotate(-45deg); }

.bx--left-nav__trigger--apps span,
.bx--left-nav__trigger--apps span:before,
.bx--left-nav__trigger--apps span:after {
  background: #00b4a0; }

.bx--left-nav__trigger--apps:focus {
  outline: 0; }

.bx--left-nav__trigger--apps:hover, .bx--left-nav__trigger--apps:focus {
  background-color: #008571; }
  .bx--left-nav__trigger--apps:hover span,
  .bx--left-nav__trigger--apps:hover span:before,
  .bx--left-nav__trigger--apps:hover span:after, .bx--left-nav__trigger--apps:focus span,
  .bx--left-nav__trigger--apps:focus span:before,
  .bx--left-nav__trigger--apps:focus span:after {
    background: #fff; }

.bx--left-nav__trigger--apps.bx--left-nav__trigger--active span {
  background: transparent; }

.bx--left-nav__trigger--apps.bx--left-nav__trigger--active span:before,
.bx--left-nav__trigger--apps.bx--left-nav__trigger--active span:after {
  background: #008571; }

.bx--left-nav__trigger--apps.bx--left-nav__trigger--active:hover, .bx--left-nav__trigger--apps.bx--left-nav__trigger--active:focus {
  background-color: #008571; }
  .bx--left-nav__trigger--apps.bx--left-nav__trigger--active:hover span:before,
  .bx--left-nav__trigger--apps.bx--left-nav__trigger--active:hover span:after, .bx--left-nav__trigger--apps.bx--left-nav__trigger--active:focus span:before,
  .bx--left-nav__trigger--apps.bx--left-nav__trigger--active:focus span:after {
    background: #fff; }

.bx--left-nav__trigger--services span,
.bx--left-nav__trigger--services span:before,
.bx--left-nav__trigger--services span:after {
  background: #ba8ff7; }

.bx--left-nav__trigger--services:focus {
  outline: 0; }

.bx--left-nav__trigger--services:hover, .bx--left-nav__trigger--services:focus {
  background-color: #734098; }
  .bx--left-nav__trigger--services:hover span,
  .bx--left-nav__trigger--services:hover span:before,
  .bx--left-nav__trigger--services:hover span:after, .bx--left-nav__trigger--services:focus span,
  .bx--left-nav__trigger--services:focus span:before,
  .bx--left-nav__trigger--services:focus span:after {
    background: #fff; }

.bx--left-nav__trigger--services.bx--left-nav__trigger--active span {
  background: transparent; }

.bx--left-nav__trigger--services.bx--left-nav__trigger--active span:before,
.bx--left-nav__trigger--services.bx--left-nav__trigger--active span:after {
  background: #734098; }

.bx--left-nav__trigger--services.bx--left-nav__trigger--active:hover, .bx--left-nav__trigger--services.bx--left-nav__trigger--active:focus {
  background-color: #734098; }
  .bx--left-nav__trigger--services.bx--left-nav__trigger--active:hover span:before,
  .bx--left-nav__trigger--services.bx--left-nav__trigger--active:hover span:after, .bx--left-nav__trigger--services.bx--left-nav__trigger--active:focus span:before,
  .bx--left-nav__trigger--services.bx--left-nav__trigger--active:focus span:after {
    background: #fff; }

.bx--left-nav__trigger--infrastructure span,
.bx--left-nav__trigger--infrastructure span:before,
.bx--left-nav__trigger--infrastructure span:after {
  background: #5aaafa; }

.bx--left-nav__trigger--infrastructure:focus {
  outline: 0; }

.bx--left-nav__trigger--infrastructure:hover, .bx--left-nav__trigger--infrastructure:focus {
  background-color: #3d70b2; }
  .bx--left-nav__trigger--infrastructure:hover span,
  .bx--left-nav__trigger--infrastructure:hover span:before,
  .bx--left-nav__trigger--infrastructure:hover span:after, .bx--left-nav__trigger--infrastructure:focus span,
  .bx--left-nav__trigger--infrastructure:focus span:before,
  .bx--left-nav__trigger--infrastructure:focus span:after {
    background: #fff; }

.bx--left-nav__trigger--infrastructure.bx--left-nav__trigger--active span {
  background: transparent; }

.bx--left-nav__trigger--infrastructure.bx--left-nav__trigger--active span:before,
.bx--left-nav__trigger--infrastructure.bx--left-nav__trigger--active span:after {
  background: #3d70b2; }

.bx--left-nav__trigger--infrastructure.bx--left-nav__trigger--active:hover, .bx--left-nav__trigger--infrastructure.bx--left-nav__trigger--active:focus {
  background-color: #3d70b2; }
  .bx--left-nav__trigger--infrastructure.bx--left-nav__trigger--active:hover span:before,
  .bx--left-nav__trigger--infrastructure.bx--left-nav__trigger--active:hover span:after, .bx--left-nav__trigger--infrastructure.bx--left-nav__trigger--active:focus span:before,
  .bx--left-nav__trigger--infrastructure.bx--left-nav__trigger--active:focus span:after {
    background: #fff; }

.bx--global-header {
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.1);
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  width: 100%;
  position: fixed;
  top: 2.25rem;
  left: 0;
  display: flex;
  justify-content: space-between;
  height: 3.125rem;
  line-height: 1.5;
  background-color: #152934;
  z-index: 9000; }

.bx--global-header__title {
  display: flex;
  align-items: center; }

.bx--global-header__title--logo {
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  text-decoration: none;
  color: #fff;
  position: relative; }
  .bx--global-header__title--logo:hover .bx--logo__text, .bx--global-header__title--logo:focus .bx--logo__text {
    color: #7cc7ff; }

.bx--global-header__title--current-page {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: inherit;
  color: #5aaafa; }
  .bx--unified-header--apps .bx--global-header__title--current-page {
    color: #00b4a0; }
  .bx--unified-header--infrastructure .bx--global-header__title--current-page {
    color: #5aaafa; }
  .bx--unified-header--services .bx--global-header__title--current-page {
    color: #ba8ff7; }

.bx--global-header .bx--logo__image {
  cursor: pointer;
  position: relative;
  margin-right: 1.125rem; }

.bx--global-header .bx--logo__text {
  font-size: 1.125rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  cursor: pointer;
  text-decoration: none;
  color: #fff; }
  .bx--global-header .bx--logo__text--bold {
    font-weight: 600; }

.bx--global-header__left-container {
  display: flex;
  align-items: center; }

.bx--global-header__right-container {
  display: flex;
  align-items: center;
  padding-right: 1%;
  background-color: #152934; }

.bx--global-header__menu {
  list-style: none;
  width: 100%;
  display: flex;
  justify-content: flex-end; }

.bx--global-header__menu__item {
  width: 100%;
  flex: 0 1 100px; }
  .bx--global-header__menu__item:focus {
    outline: 0;
    background-color: #2d3f49;
    color: #5aaafa; }
  .bx--global-header__menu__item:last-child .bx--dropdown-list {
    right: 0; }

.bx--global-header__menu__item--link {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.875rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.125rem;
  text-decoration: none;
  background-color: #152934;
  color: #fff;
  padding: 0 1rem; }
  .bx--global-header__menu__item--link:hover {
    color: #5aaafa; }
  .bx--global-header__menu__item--link:focus {
    outline: 0;
    background-color: #2d3f49;
    color: #5aaafa; }

.bx--global-header__menu__item .bx--dropdown {
  height: 3.125rem;
  background-color: transparent; }
  .bx--global-header__menu__item .bx--dropdown:focus {
    outline: 0;
    background-color: #2d3f49; }
    .bx--global-header__menu__item .bx--dropdown:focus .bx--dropdown__menu-text {
      color: #5aaafa; }
    .bx--global-header__menu__item .bx--dropdown:focus .bx--dropdown__menu-text,
    .bx--global-header__menu__item .bx--dropdown:focus .bx--dropdown__list {
      outline: 0; }

.bx--global-header__menu .bx--dropdown-text {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
  padding-top: 0;
  padding-right: 1rem;
  padding-bottom: 0;
  align-items: center;
  height: 100%;
  z-index: 1000;
  color: #fff; }
  .bx--global-header__menu .bx--dropdown-text:hover {
    color: #5aaafa; }

.bx--global-header__menu .bx--dropdown-list {
  transition: 250ms all cubic-bezier(0.5, 0, 0.1, 1);
  transform: translateY(-150%);
  opacity: 0;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  width: auto;
  z-index: -1;
  padding-bottom: 0.5rem; }

.bx--global-header__menu .bx--dropdown-item {
  min-width: 200px;
  width: 100%; }

.bx--global-header__menu .bx--dropdown-link {
  padding: 0.5rem 0.75rem;
  color: #fff; }
  .bx--global-header__menu .bx--dropdown-link:hover {
    color: #152934;
    background-color: #5aaafa; }

.bx--global-header__menu .bx--dropdown--open .bx--dropdown-text {
  background-color: #2d3f49;
  color: #5aaafa; }

.bx--global-header__menu .bx--dropdown--open .bx--dropdown-list {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  background-color: #2d3f49; }

.bx--top-nav {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  display: flex;
  justify-content: space-between;
  background-color: #0f212e;
  width: 100%;
  height: 2.25rem;
  padding: 0 1rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9500; }

.bx--top-nav__left-container,
.bx--top-nav__right-container {
  list-style: none;
  display: flex;
  align-items: center;
  min-width: 50%; }

.bx--top-nav__left-container .bx--dropdown,
.bx--top-nav__right-container .bx--dropdown {
  font-size: 0.75rem;
  list-style: none;
  background-color: #0f212e;
  color: #fff;
  height: 100%;
  display: flex;
  align-items: center; }

.bx--top-nav__left-container .bx--dropdown__arrow,
.bx--top-nav__right-container .bx--dropdown__arrow {
  fill: #fff;
  right: 0.75rem;
  width: 0.75rem;
  height: 1.5rem; }

.bx--top-nav__left-container .bx--dropdown-text,
.bx--top-nav__right-container .bx--dropdown-text {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 12.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 600; }

.bx--top-nav__left-container .bx--dropdown-list,
.bx--top-nav__right-container .bx--dropdown-list {
  width: auto;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #2d3f49;
  transition: 250ms all cubic-bezier(0.5, 0, 0.1, 1);
  display: flex;
  flex-direction: column;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-50%);
  z-index: -1; }

.bx--top-nav__left-container .bx--dropdown-item,
.bx--top-nav__right-container .bx--dropdown-item {
  min-width: 10.625rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }
  .bx--top-nav__left-container .bx--dropdown-item:hover,
  .bx--top-nav__right-container .bx--dropdown-item:hover {
    background-color: #5aaafa;
    color: #152934; }

.bx--top-nav__left-container .bx--dropdown-link,
.bx--top-nav__right-container .bx--dropdown-link {
  color: #fff; }

.bx--top-nav__left-container .bx--dropdown--open,
.bx--top-nav__right-container .bx--dropdown--open {
  background-color: #2d3f49; }

.bx--top-nav__left-container .bx--dropdown--open .bx--dropdown-text,
.bx--top-nav__right-container .bx--dropdown--open .bx--dropdown-text {
  color: #5aaafa; }

.bx--top-nav__left-container .bx--dropdown--open .bx--dropdown__arrow,
.bx--top-nav__right-container .bx--dropdown--open .bx--dropdown__arrow {
  fill: #5aaafa; }

.bx--top-nav__left-container .bx--dropdown--open .bx--dropdown-list,
.bx--top-nav__right-container .bx--dropdown--open .bx--dropdown-list {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  background-color: #2d3f49; }

.bx--top-nav__left-container__link {
  font-size: 0.6875rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: flex-start;
  background-color: #0f212e;
  align-items: flex-start;
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  margin-right: 1rem; }
  .bx--top-nav__left-container__link:hover, .bx--top-nav__left-container__link:focus {
    color: #5aaafa; }
    .bx--top-nav__left-container__link:hover .bx--top-nav__left-container__link--icon, .bx--top-nav__left-container__link:focus .bx--top-nav__left-container__link--icon {
      fill: #5aaafa; }

.bx--top-nav__left-container__link--icon {
  width: 0.675rem;
  height: 0.7rem;
  fill: #fff;
  margin-right: 0.3rem; }

.bx--top-nav__left-container__item:focus {
  outline: 0; }

.bx--top-nav__left-container .bx--dropdown:focus, .bx--top-nav__left-container .bx--dropdown:hover {
  outline: 0; }
  .bx--top-nav__left-container .bx--dropdown:focus .bx--dropdown__arrow use, .bx--top-nav__left-container .bx--dropdown:hover .bx--dropdown__arrow use {
    fill: #5aaafa; }
  .bx--top-nav__left-container .bx--dropdown:focus .bx--dropdown-text, .bx--top-nav__left-container .bx--dropdown:hover .bx--dropdown-text {
    background-color: #2d3f49;
    color: #5aaafa; }

.bx--top-nav__left-container .bx--dropdown-list {
  padding-bottom: 0.5rem; }

.bx--top-nav__left-container .bx--dropdown__arrow {
  top: 0.3rem;
  left: 0.75rem;
  opacity: 0;
  transform: rotate(0);
  animation: 250ms cubic-bezier(0.64, 0.57, 0.67, 1.53) 650ms 1 normal forwards fade; }

.bx--top-nav__left-container .bx--dropdown-text {
  animation: 250ms cubic-bezier(0.6, 0.22, 0.38, 2.03) 1 normal forwards pop-in;
  opacity: 0;
  transform: scale(0.5);
  padding: 0.725rem 1rem 0.725rem 2rem; }

.bx--top-nav__left-container .bx--dropdown-link {
  padding: 0.6rem 0.75rem; }
  .bx--top-nav__left-container .bx--dropdown-link:hover, .bx--top-nav__left-container .bx--dropdown-link:focus {
    background-color: #5aaafa;
    color: #152934; }

.bx--top-nav__right-container {
  justify-content: flex-end; }

.bx--top-nav__right-container__item[data-trial] .bx--dropdown-text,
.bx--top-nav__right-container__item[data-credit] .bx--dropdown-text {
  padding: 0.75rem 2rem 0.75rem 1rem;
  color: #fff; }

.bx--top-nav__right-container__item[data-trial] .bx--dropdown-list,
.bx--top-nav__right-container__item[data-credit] .bx--dropdown-list {
  left: initial;
  outline: 0;
  right: 0;
  min-width: 20.3125rem;
  transform: translateY(-10%); }

.bx--top-nav__right-container__item[data-trial] .bx--dropdown__arrow,
.bx--top-nav__right-container__item[data-credit] .bx--dropdown__arrow {
  top: 0.5rem; }

.bx--top-nav__right-container__item[data-trial] .bx--dropdown--open .bx--dropdown-list,
.bx--top-nav__right-container__item[data-credit] .bx--dropdown--open .bx--dropdown-list {
  transform: translateY(0); }

.bx--top-nav__right-container__item[data-trial] .bx--dropdown--open .bx--dropdown-text,
.bx--top-nav__right-container__item[data-credit] .bx--dropdown--open .bx--dropdown-text {
  color: #5aaafa; }

.bx--top-nav__right-container__item[data-trial] .bx--dropdown:hover,
.bx--top-nav__right-container__item[data-trial] .bx--dropdown:focus,
.bx--top-nav__right-container__item[data-credit] .bx--dropdown:hover,
.bx--top-nav__right-container__item[data-credit] .bx--dropdown:focus {
  outline: 0;
  background-color: #2d3f49; }
  .bx--top-nav__right-container__item[data-trial] .bx--dropdown:hover .bx--dropdown-text,
  .bx--top-nav__right-container__item[data-trial] .bx--dropdown:focus .bx--dropdown-text,
  .bx--top-nav__right-container__item[data-credit] .bx--dropdown:hover .bx--dropdown-text,
  .bx--top-nav__right-container__item[data-credit] .bx--dropdown:focus .bx--dropdown-text {
    color: #5aaafa; }
  .bx--top-nav__right-container__item[data-trial] .bx--dropdown:hover .bx--dropdown__arrow use,
  .bx--top-nav__right-container__item[data-trial] .bx--dropdown:focus .bx--dropdown__arrow use,
  .bx--top-nav__right-container__item[data-credit] .bx--dropdown:hover .bx--dropdown__arrow use,
  .bx--top-nav__right-container__item[data-credit] .bx--dropdown:focus .bx--dropdown__arrow use {
    fill: #5aaafa; }

.bx--top-nav__right-container__item[data-trial] .bx--dropdown__trial-content,
.bx--top-nav__right-container__item[data-trial] .bx--dropdown__credit-content,
.bx--top-nav__right-container__item[data-credit] .bx--dropdown__trial-content,
.bx--top-nav__right-container__item[data-credit] .bx--dropdown__credit-content {
  cursor: auto;
  display: flex;
  flex-direction: column;
  padding: 1rem; }
  .bx--top-nav__right-container__item[data-trial] .bx--dropdown__trial-content:focus, .bx--top-nav__right-container__item[data-trial] .bx--dropdown__trial-content:hover,
  .bx--top-nav__right-container__item[data-trial] .bx--dropdown__credit-content:focus,
  .bx--top-nav__right-container__item[data-trial] .bx--dropdown__credit-content:hover,
  .bx--top-nav__right-container__item[data-credit] .bx--dropdown__trial-content:focus,
  .bx--top-nav__right-container__item[data-credit] .bx--dropdown__trial-content:hover,
  .bx--top-nav__right-container__item[data-credit] .bx--dropdown__credit-content:focus,
  .bx--top-nav__right-container__item[data-credit] .bx--dropdown__credit-content:hover {
    outline: 0;
    background-color: initial;
    color: #fff; }

.bx--top-nav__right-container__item[data-trial] .bx--dropdown__trial-content--desc,
.bx--top-nav__right-container__item[data-trial] .bx--dropdown__credit-content--desc,
.bx--top-nav__right-container__item[data-credit] .bx--dropdown__trial-content--desc,
.bx--top-nav__right-container__item[data-credit] .bx--dropdown__credit-content--desc {
  margin-bottom: 1rem; }

.bx--top-nav__right-container__item[data-trial] .bx--dropdown__trial-content .bx--link,
.bx--top-nav__right-container__item[data-trial] .bx--dropdown__credit-content .bx--link,
.bx--top-nav__right-container__item[data-credit] .bx--dropdown__trial-content .bx--link,
.bx--top-nav__right-container__item[data-credit] .bx--dropdown__credit-content .bx--link {
  font-size: 0.6875rem;
  color: #5aaafa;
  margin-top: 1rem;
  text-align: center; }

.bx--top-nav__right-container__item[data-trial] .bx--dropdown__trial-content--desc,
.bx--top-nav__right-container__item[data-credit] .bx--dropdown__trial-content--desc {
  font-size: 0.75rem; }

.bx--top-nav__right-container__item[data-trial] .bx--dropdown__credit-content div .bx--dropdown__credit-content--heading,
.bx--top-nav__right-container__item[data-credit] .bx--dropdown__credit-content div .bx--dropdown__credit-content--heading {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.75rem;
  font-weight: 600; }

.bx--top-nav__right-container__item[data-trial] .bx--dropdown__credit-content div .bx--dropdown__credit-content--desc,
.bx--top-nav__right-container__item[data-credit] .bx--dropdown__credit-content div .bx--dropdown__credit-content--desc {
  font-size: 0.875rem; }

.bx--top-nav__right-container__item[data-trial] .bx--dropdown__credit-content div:last-child .bx--dropdown__credit-content--desc,
.bx--top-nav__right-container__item[data-credit] .bx--dropdown__credit-content div:last-child .bx--dropdown__credit-content--desc {
  margin-bottom: 0; }

.bx--top-nav__right-container__item:last-child .bx--dropdown:focus, .bx--top-nav__right-container__item:last-child .bx--dropdown:hover {
  background-color: #2d3f49;
  outline: 0; }

.bx--top-nav__right-container__item:last-child .bx--dropdown__arrow {
  transform-origin: 50% 40%; }
  .bx--top-nav__right-container__item:last-child .bx--dropdown__arrow use {
    fill: #5aaafa; }

.bx--top-nav__right-container__item:last-child .bx--dropdown-text--profile-image {
  height: 2.25rem;
  width: 2.25rem;
  min-height: 2.25rem;
  min-width: 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0rem 1rem; }

.bx--top-nav__right-container__item:last-child .bx--dropdown-text--profile-image .profile-image {
  display: flex;
  align-items: center;
  border-radius: 50%;
  overflow: hidden; }
  .bx--top-nav__right-container__item:last-child .bx--dropdown-text--profile-image .profile-image svg,
  .bx--top-nav__right-container__item:last-child .bx--dropdown-text--profile-image .profile-image img {
    fill: #fff;
    max-height: 1.5rem;
    max-width: 1.5rem; }

.bx--top-nav__right-container__item:last-child .bx--dropdown-list {
  right: 0;
  left: inherit;
  padding: 1rem;
  min-width: 225px; }

.bx--top-nav__right-container__item:last-child .bx--dropdown-item {
  display: flex;
  justify-content: space-between; }
  .bx--top-nav__right-container__item:last-child .bx--dropdown-item svg {
    min-height: 3rem;
    min-width: 3rem; }
  .bx--top-nav__right-container__item:last-child .bx--dropdown-item:hover {
    background-color: inherit;
    color: inherit; }

.bx--top-nav__right-container__item[data-credit] .bx--dropdown-list {
  min-width: 0;
  width: 100%; }

.bx--dropdown__profile-dropdown--picture {
  max-width: 3rem;
  max-height: 3rem;
  border-radius: 50%;
  width: 100%;
  fill: #fff; }

.bx--dropdown__profile-dropdown--information {
  margin-left: 1rem; }
  .bx--dropdown__profile-dropdown--information p {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 0.875rem;
    font-weight: 600; }

.bx--dropdown__profile-dropdown__container {
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  .bx--dropdown__profile-dropdown__container a {
    font-size: 0.75rem;
    color: #5aaafa; }
    .bx--dropdown__profile-dropdown__container a:hover {
      color: #7cc7ff; }
  .bx--dropdown__profile-dropdown__container p {
    padding: 0 0.5rem; }

@keyframes pop-in {
  100% {
    opacity: 1;
    transform: scale(1); } }

@keyframes fade {
  100% {
    opacity: 1; } }

.bx--top-nav__left-container--item:nth-child(1) .bx--dropdown-text {
  animation-delay: 200ms; }

.bx--top-nav__left-container--item:nth-child(2) .bx--dropdown-text {
  animation-delay: 300ms; }

.bx--top-nav__left-container--item:nth-child(3) .bx--dropdown-text {
  animation-delay: 400ms; }

.bx--global-header__left-nav {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  z-index: 9000;
  width: 15.625rem;
  top: 2.25rem;
  left: 0;
  height: 100%;
  transform: translate3d(-100%, 0, 0);
  transition: 300ms cubic-bezier(0.5, 0, 0.1, 1);
  box-shadow: none;
  visibility: hidden; }

.bx--global-header__left-nav.bx--left-nav--active {
  transform: translate3d(0, 0, 0);
  box-shadow: 0px 1px 15px 2px rgba(0, 0, 0, 0.1);
  visibility: visible; }

.bx--left-nav {
  display: block;
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 0 0 4rem 0;
  overflow-y: auto;
  overflow-x: hidden; }

.bx--left-nav__close-row {
  height: 3.125rem;
  width: 100%;
  background-color: #fff; }

.bx--left-nav__header {
  align-items: center;
  height: 3.125rem;
  cursor: pointer;
  position: relative;
  display: flex;
  background-color: #0f212e;
  justify-content: space-between;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1.125rem 1.25rem; }
  .bx--left-nav__header[data-left-nav-current-section='apps'] {
    background-color: #008571; }
  .bx--left-nav__header[data-left-nav-current-section='infrastructure'] {
    background-color: #3d70b2; }
  .bx--left-nav__header[data-left-nav-current-section='services'] {
    background-color: #734098; }

.bx--left-nav__header--title {
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  font-weight: 600;
  margin-right: auto; }

.bx--left-nav__header--taxonomy-icon {
  width: 1.5rem;
  height: 1.5rem;
  fill: #fff;
  margin-right: 1rem; }

.bx--left-nav__header--close-icon {
  width: 0.875rem;
  height: 0.875rem;
  display: block;
  fill: #fff; }

.bx--left-nav__sections {
  list-style: none;
  padding: 0 0 1rem;
  width: 100%;
  z-index: 8000;
  position: relative;
  border-bottom: 1px solid #8897a2;
  background-color: #fff; }

.bx--left-nav__section {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 250ms cubic-bezier(0.5, 0, 0.1, 1);
  background-color: #fff; }
  .bx--left-nav__section:hover[data-left-nav-section='apps'] {
    background-color: #008571; }
  .bx--left-nav__section:hover[data-left-nav-section='services'] {
    background-color: #734098; }
  .bx--left-nav__section:hover[data-left-nav-section='infrastructure'] {
    background-color: #4178be; }
  .bx--left-nav__section:hover .bx--left-nav__section--link {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 600;
    color: #fff; }
  .bx--left-nav__section:hover .bx--left-nav__section--taxonomy-icon {
    fill: #fff; }

.bx--left-nav__section--active, .bx--left-nav__section--transition {
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  position: relative;
  display: flex;
  background-color: #fff;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0.8rem 0; }
  .bx--left-nav__section--active[data-left-nav-section='apps'], .bx--left-nav__section--transition[data-left-nav-section='apps'] {
    background-color: #008571; }
  .bx--left-nav__section--active[data-left-nav-section='infrastructure'], .bx--left-nav__section--transition[data-left-nav-section='infrastructure'] {
    background-color: #3d70b2; }
  .bx--left-nav__section--active[data-left-nav-section='services'], .bx--left-nav__section--transition[data-left-nav-section='services'] {
    background-color: #734098; }

.bx--left-nav__section--transition {
  position: absolute;
  z-index: 9999;
  transition: all 0.3s cubic-bezier(0, 0, 0.25, 1); }
  .bx--left-nav__section--transition--50 {
    transform: translateY(100px); }
  .bx--left-nav__section--transition--100 {
    transform: translateY(150px); }
  .bx--left-nav__section--transition--0 {
    transform: translateY(50px); }
  .bx--left-nav__section--transition .bx--left-nav__section--taxonomy-icon {
    fill: #fff; }

.bx--left-nav__section--taxonomy-icon {
  width: 1.5rem;
  height: 1.5rem;
  fill: #152934;
  margin-right: 1rem; }

.bx--left-nav__section--anchor {
  display: flex;
  width: 100%;
  padding: 0 1.25rem;
  align-items: center;
  text-decoration: none; }

.bx--left-nav__section--link {
  font-size: 1rem;
  display: flex;
  align-items: center;
  color: #152934;
  height: 3.125rem; }

.bx--left-nav__main-nav {
  list-style: none;
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;
  margin-top: 0;
  transition: all 250ms cubic-bezier(0, 0, 0.25, 1);
  padding-top: 1rem;
  background-color: #fff; }

.bx--left-nav__main-nav--hidden {
  display: none; }

.bx--left-nav__main-nav--top {
  margin-top: -100vh; }

.bx--main-nav__parent-item {
  opacity: 1;
  transition: opacity 200ms cubic-bezier(0.5, 0, 0.1, 1);
  cursor: pointer;
  width: 100%;
  padding: 0;
  margin-bottom: 0.25rem;
  background-color: #fff; }

.bx--main-nav__parent-item--fade {
  opacity: 0; }

.bx--main-nav__parent-item--hidden {
  display: none; }

.bx--main-nav__parent-item--expanded .bx--parent-item__link--down-icon svg {
  transform: rotate(180deg); }

.bx--parent-item__link {
  font-size: 0.875rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  padding: 0.5rem 1.25rem;
  transition: background-color 250ms cubic-bezier(0.5, 0, 0.1, 1);
  color: #152934; }
  .bx--parent-item__link .bx--parent-item__link--taxonomy-icon {
    width: 1.5rem;
    height: 1.5rem;
    transition: 250ms cubic-bezier(0.5, 0, 0.1, 1);
    margin-right: 1rem;
    fill: #152934; }
  .bx--parent-item__link .bx--parent-item__link--down-icon {
    display: flex;
    margin-left: auto; }
    .bx--parent-item__link .bx--parent-item__link--down-icon svg {
      width: 0.625rem;
      height: 0.625rem;
      transition: 250ms cubic-bezier(0.5, 0, 0.1, 1);
      fill: #152934; }
  .bx--left-nav__main-nav[data-left-nav-list='apps'] .bx--parent-item__link:hover {
    color: #008571; }
    .bx--left-nav__main-nav[data-left-nav-list='apps'] .bx--parent-item__link:hover .bx--parent-item__link--taxonomy-icon,
    .bx--left-nav__main-nav[data-left-nav-list='apps'] .bx--parent-item__link:hover .bx--parent-item__link--down-icon svg {
      fill: #008571; }
  .bx--left-nav__main-nav[data-left-nav-list='services'] .bx--parent-item__link:hover {
    color: #734098; }
    .bx--left-nav__main-nav[data-left-nav-list='services'] .bx--parent-item__link:hover .bx--parent-item__link--taxonomy-icon,
    .bx--left-nav__main-nav[data-left-nav-list='services'] .bx--parent-item__link:hover .bx--parent-item__link--down-icon svg {
      fill: #734098; }
  .bx--left-nav__main-nav[data-left-nav-list='infrastructure'] .bx--parent-item__link:hover {
    color: #3d70b2; }
    .bx--left-nav__main-nav[data-left-nav-list='infrastructure'] .bx--parent-item__link:hover .bx--parent-item__link--taxonomy-icon,
    .bx--left-nav__main-nav[data-left-nav-list='infrastructure'] .bx--parent-item__link:hover .bx--parent-item__link--down-icon svg {
      fill: #3d70b2; }

.bx--main-nav__parent-item.bx--active-list-item .bx--parent-item__link {
  color: #fff; }
  .bx--left-nav__main-nav[data-left-nav-list='apps'] .bx--main-nav__parent-item.bx--active-list-item .bx--parent-item__link {
    background-color: #008571; }
    .bx--left-nav__main-nav[data-left-nav-list='apps'] .bx--main-nav__parent-item.bx--active-list-item .bx--parent-item__link:hover {
      color: #fff; }
      .bx--left-nav__main-nav[data-left-nav-list='apps'] .bx--main-nav__parent-item.bx--active-list-item .bx--parent-item__link:hover .bx--parent-item__link--taxonomy-icon,
      .bx--left-nav__main-nav[data-left-nav-list='apps'] .bx--main-nav__parent-item.bx--active-list-item .bx--parent-item__link:hover .bx--parent-item__link--down-icon svg {
        fill: #fff; }
  .bx--left-nav__main-nav[data-left-nav-list='services'] .bx--main-nav__parent-item.bx--active-list-item .bx--parent-item__link {
    background-color: #734098; }
    .bx--left-nav__main-nav[data-left-nav-list='services'] .bx--main-nav__parent-item.bx--active-list-item .bx--parent-item__link:hover {
      color: #fff; }
      .bx--left-nav__main-nav[data-left-nav-list='services'] .bx--main-nav__parent-item.bx--active-list-item .bx--parent-item__link:hover .bx--parent-item__link--taxonomy-icon,
      .bx--left-nav__main-nav[data-left-nav-list='services'] .bx--main-nav__parent-item.bx--active-list-item .bx--parent-item__link:hover .bx--parent-item__link--down-icon svg {
        fill: #fff; }
  .bx--left-nav__main-nav[data-left-nav-list='infrastructure'] .bx--main-nav__parent-item.bx--active-list-item .bx--parent-item__link {
    background-color: #3d70b2; }
    .bx--left-nav__main-nav[data-left-nav-list='infrastructure'] .bx--main-nav__parent-item.bx--active-list-item .bx--parent-item__link:hover {
      color: #fff; }
      .bx--left-nav__main-nav[data-left-nav-list='infrastructure'] .bx--main-nav__parent-item.bx--active-list-item .bx--parent-item__link:hover .bx--parent-item__link--taxonomy-icon,
      .bx--left-nav__main-nav[data-left-nav-list='infrastructure'] .bx--main-nav__parent-item.bx--active-list-item .bx--parent-item__link:hover .bx--parent-item__link--down-icon svg {
        fill: #fff; }

.bx--main-nav__parent-item.bx--active-list-item .bx--parent-item__link--taxonomy-icon {
  fill: #fff; }

.bx--main-nav__parent-item.bx--active-list-item .bx--parent-item__link--down-icon svg {
  fill: #fff; }

.bx--main-nav__parent-item.bx--active-list-item:hover {
  color: #fff; }

.bx--main-nav__nested-list {
  display: flex;
  flex-direction: column;
  margin-top: 0;
  list-style: none;
  max-height: 0;
  transition: 300ms cubic-bezier(0.5, 0, 0.1, 1);
  padding: 0;
  opacity: 0;
  overflow: hidden;
  margin-bottom: 0; }

.bx--main-nav__parent-item--expanded .bx--main-nav__nested-list {
  max-height: 20rem;
  transition: 300ms cubic-bezier(0.5, 0, 0.1, 1);
  opacity: 1;
  margin-top: 0.5rem;
  overflow: visible; }
  .bx--main-nav__parent-item--expanded .bx--main-nav__nested-list .bx--nested-list__item {
    opacity: 1; }

.bx--nested-list__item {
  width: 100%;
  position: static;
  margin-bottom: 0.25rem;
  padding: 0;
  transition: 250ms;
  opacity: 0; }

.bx--nested-list__item--link {
  font-size: 0.875rem;
  color: #152934;
  padding: 0.5rem 1.35rem 0.5rem 2rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  position: relative;
  margin-right: auto; }
  .bx--left-nav__main-nav[data-left-nav-list='apps'] .bx--nested-list__item--link:hover {
    color: #008571; }
    .bx--left-nav__main-nav[data-left-nav-list='apps'] .bx--nested-list__item--link:hover .bx--left-nav-list__item-link--taxonomy-icon {
      fill: #008571; }
  .bx--left-nav__main-nav[data-left-nav-list='services'] .bx--nested-list__item--link:hover {
    color: #734098; }
    .bx--left-nav__main-nav[data-left-nav-list='services'] .bx--nested-list__item--link:hover .bx--left-nav-list__item-link--taxonomy-icon {
      fill: #734098; }
  .bx--left-nav__main-nav[data-left-nav-list='infrastructure'] .bx--nested-list__item--link:hover {
    color: #3d70b2; }
    .bx--left-nav__main-nav[data-left-nav-list='infrastructure'] .bx--nested-list__item--link:hover .bx--left-nav-list__item-link--taxonomy-icon {
      fill: #3d70b2; }

.bx--nested-list__item--icon svg {
  width: 0.625rem;
  height: 0.625rem;
  fill: #152934; }

.bx--left-nav__main-nav[data-left-nav-list='apps'] .bx--nested-list__item.bx--active-list-item .bx--nested-list__item--link {
  background-color: #008571; }
  .bx--left-nav__main-nav[data-left-nav-list='apps'] .bx--nested-list__item.bx--active-list-item .bx--nested-list__item--link:hover {
    color: #fff; }
    .bx--left-nav__main-nav[data-left-nav-list='apps'] .bx--nested-list__item.bx--active-list-item .bx--nested-list__item--link:hover .bx--parent-item__link--taxonomy-icon {
      fill: #fff; }

.bx--left-nav__main-nav[data-left-nav-list='services'] .bx--nested-list__item.bx--active-list-item .bx--nested-list__item--link {
  background-color: #734098; }
  .bx--left-nav__main-nav[data-left-nav-list='services'] .bx--nested-list__item.bx--active-list-item .bx--nested-list__item--link:hover {
    color: #fff; }
    .bx--left-nav__main-nav[data-left-nav-list='services'] .bx--nested-list__item.bx--active-list-item .bx--nested-list__item--link:hover .bx--parent-item__link--taxonomy-icon {
      fill: #fff; }

.bx--left-nav__main-nav[data-left-nav-list='infrastructure'] .bx--nested-list__item.bx--active-list-item .bx--nested-list__item--link {
  background-color: #3d70b2; }
  .bx--left-nav__main-nav[data-left-nav-list='infrastructure'] .bx--nested-list__item.bx--active-list-item .bx--nested-list__item--link:hover {
    color: #fff; }
    .bx--left-nav__main-nav[data-left-nav-list='infrastructure'] .bx--nested-list__item.bx--active-list-item .bx--nested-list__item--link:hover .bx--parent-item__link--taxonomy-icon {
      fill: #fff; }

.bx--nested-list__item.bx--active-list-item .bx--nested-list__item--icon svg {
  fill: #152934; }

.bx--nested-list__flyout-menu {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  display: none;
  min-width: auto;
  white-space: nowrap;
  outline: none;
  position: absolute;
  z-index: 9999;
  color: #fff; }
  .bx--nested-list__flyout-menu:before {
    content: '';
    display: block;
    position: absolute;
    width: 120%;
    height: 120%;
    top: -3rem;
    left: 0;
    padding: 2rem;
    z-index: -1; }

.bx--nested-list__flyout-menu--displayed {
  display: block;
  border-left: 1px solid #8897a2; }

.bx--flyout-menu__item--link {
  font-size: 0.875rem;
  padding: 0 1.75rem 0 1rem;
  color: #152934;
  text-decoration: none; }

.bx--flyout-menu__item {
  background-color: #fff;
  margin: 0;
  height: 2.3125rem;
  display: flex;
  align-items: center; }
  .bx--left-nav__main-nav[data-left-nav-list='apps'] .bx--flyout-menu__item:hover .bx--flyout-menu__item--link {
    color: #008571; }
  .bx--left-nav__main-nav[data-left-nav-list='services'] .bx--flyout-menu__item:hover .bx--flyout-menu__item--link {
    color: #734098; }
  .bx--left-nav__main-nav[data-left-nav-list='infrastructure'] .bx--flyout-menu__item:hover .bx--flyout-menu__item--link {
    color: #3d70b2; }

.bx--left-nav__main-nav[data-left-nav-list='apps'] .bx--flyout-menu__item.bx--active-list-item {
  background-color: #008571; }
  .bx--left-nav__main-nav[data-left-nav-list='apps'] .bx--flyout-menu__item.bx--active-list-item .bx--flyout-menu__item--link {
    color: #fff; }
    .bx--left-nav__main-nav[data-left-nav-list='apps'] .bx--flyout-menu__item.bx--active-list-item .bx--flyout-menu__item--link:hover {
      color: #fff; }

.bx--left-nav__main-nav[data-left-nav-list='services'] .bx--flyout-menu__item.bx--active-list-item {
  background-color: #734098; }
  .bx--left-nav__main-nav[data-left-nav-list='services'] .bx--flyout-menu__item.bx--active-list-item .bx--flyout-menu__item--link {
    color: #fff; }
    .bx--left-nav__main-nav[data-left-nav-list='services'] .bx--flyout-menu__item.bx--active-list-item .bx--flyout-menu__item--link:hover {
      color: #fff; }

.bx--left-nav__main-nav[data-left-nav-list='infrastructure'] .bx--flyout-menu__item.bx--active-list-item {
  background-color: #3d70b2; }
  .bx--left-nav__main-nav[data-left-nav-list='infrastructure'] .bx--flyout-menu__item.bx--active-list-item .bx--flyout-menu__item--link {
    color: #fff; }
    .bx--left-nav__main-nav[data-left-nav-list='infrastructure'] .bx--flyout-menu__item.bx--active-list-item .bx--flyout-menu__item--link:hover {
      color: #fff; }

.bx--account-switcher {
  list-style: none;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 21.875rem; }

.bx--account-switcher__linked-icon {
  fill: #fff;
  height: 1rem;
  width: 1rem;
  margin: 0 0.5rem;
  transform: rotate(45deg); }

.bx--account-switcher__toggle {
  z-index: 9000;
  height: 2.25rem;
  width: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0 1rem;
  background-color: #0f212e;
  cursor: pointer; }
  .bx--account-switcher__toggle:focus, .bx--account-switcher__toggle:hover {
    outline: 0;
    background-color: #2d3f49; }
    .bx--account-switcher__toggle:focus .bx--account-switcher__toggle--text, .bx--account-switcher__toggle:hover .bx--account-switcher__toggle--text {
      color: #5aaafa; }
    .bx--account-switcher__toggle:focus svg, .bx--account-switcher__toggle:hover svg {
      fill: #5aaafa; }

.bx--account-switcher__toggle--hidden {
  opacity: 0;
  visibility: hidden; }

.bx--account-switcher__toggle--text {
  font-size: 0.6875rem;
  letter-spacing: 0;
  overflow: hidden;
  display: inline-flex;
  white-space: nowrap;
  padding-right: 0.5rem;
  font-weight: 300; }

.bx--account-switcher__toggle--text[data-switcher-account-sl],
.bx--account-switcher__toggle--text[data-switcher-account] {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600; }

.bx--account-switcher__toggle--text[data-switcher-account] {
  display: inline-flex;
  align-items: center; }

.bx--account-switcher__toggle--text[data-switcher-account] .bx--account-switcher__linked-icon {
  margin-right: 0; }

.bx--account-switcher__toggle--text > [data-dropdown-account-linked] {
  margin-left: 0.5rem;
  margin-right: 0; }

.bx--account-switcher__toggle--text:last-child {
  padding-right: 0; }

.bx--account-switcher__menu {
  list-style: none; }

.bx--account-switcher__menu__container {
  box-shadow: 6px 6px 6px 0 rgba(0, 0, 0, 0.1);
  transition: 250ms all cubic-bezier(0.5, 0, 0.1, 1);
  position: absolute;
  width: auto;
  min-width: 21.875rem;
  height: auto;
  background-color: #2d3f49;
  visibility: hidden;
  opacity: 0;
  top: 100%;
  right: 0;
  transform: translateY(-10%);
  color: #fff;
  z-index: 8000; }

.bx--account-switcher--open {
  color: #5aaafa; }
  .bx--account-switcher--open .bx--account-switcher__toggle {
    color: #5aaafa;
    background-color: #2d3f49; }
  .bx--account-switcher--open .bx--account-switcher__linked-icon {
    fill: #5aaafa; }
  .bx--account-switcher--open .bx--account-switcher__menu__container {
    visibility: visible;
    max-height: 125rem;
    opacity: 1;
    transform: translateY(0); }

.bx--account-switcher__menu__item {
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem; }
  .bx--account-switcher__menu__item:first-child {
    border-bottom: 1px solid #42535c; }
  .bx--account-switcher__menu__item:nth-child(n + 2) {
    padding: 1rem 1rem 0; }
  .bx--account-switcher__menu__item:last-child {
    display: flex;
    margin-left: auto;
    justify-content: flex-start;
    max-width: 75%;
    padding: 0.75rem 0 1rem 1.5rem; }
    .bx--account-switcher__menu__item:last-child a {
      font-size: 0.6875rem;
      padding-right: 1rem;
      color: #5aaafa; }
      .bx--account-switcher__menu__item:last-child a:visited {
        color: #5aaafa; }
      .bx--account-switcher__menu__item:last-child a:hover {
        color: #5596e6; }

.bx--account-switcher__menu__item--title {
  font-size: 0.875rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
  height: 44px;
  min-width: 100px;
  display: flex;
  align-items: center;
  flex: 1; }

.bx--account-switcher__menu__item .bx--dropdown {
  font-size: 0.875rem;
  flex: 3;
  background-color: #42535c;
  display: block;
  flex-direction: column;
  min-width: 200px; }

.bx--account-switcher__menu__item .bx--dropdown[data-value=''] .bx--dropdown-text {
  color: #fff; }

.bx--account-switcher__menu__item .bx--dropdown--scroll {
  max-height: 180px;
  overflow-y: auto; }
  .bx--account-switcher__menu__item .bx--dropdown--scroll::-webkit-scrollbar {
    background: #394b54;
    width: 0.5rem;
    height: 0.5rem; }
  .bx--account-switcher__menu__item .bx--dropdown--scroll::-webkit-scrollbar-thumb {
    background-color: #8c9ba5;
    border-radius: 10px; }
  .bx--account-switcher__menu__item .bx--dropdown--scroll::-webkit-scrollbar-thumb:hover {
    background-color: #dfe6eb; }

.bx--account-switcher__menu__item .bx--dropdown li {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  max-width: 400px; }

.bx--account-switcher__menu__item .bx--dropdown__arrow {
  fill: #5aaafa; }

.bx--account-switcher__menu__item .bx--dropdown-text {
  padding-right: 2rem;
  align-self: flex-start;
  background-color: #42535c; }
  .bx--account-switcher__menu__item .bx--dropdown-text .bx--account-switcher__linked-icon {
    fill: #fff;
    vertical-align: middle; }

.bx--account-switcher__menu__item .bx--dropdown-list {
  position: relative;
  transform: translateY(-10px);
  max-height: 0;
  height: auto;
  width: 100%;
  top: 0; }

.bx--account-switcher__menu__item .bx--dropdown-item {
  background-color: #2d3f49; }
  .bx--account-switcher__menu__item .bx--dropdown-item > .bx--dropdown-link:hover,
  .bx--account-switcher__menu__item .bx--dropdown-item .bx--dropdown-link:focus {
    background-color: #5aaafa;
    color: #152934; }
    .bx--account-switcher__menu__item .bx--dropdown-item > .bx--dropdown-link:hover svg,
    .bx--account-switcher__menu__item .bx--dropdown-item .bx--dropdown-link:focus svg {
      fill: #152934; }

.bx--account-switcher__menu__item .bx--dropdown-link {
  height: 100%;
  text-overflow: ellipsis;
  overflow: hidden; }
  .bx--account-switcher__menu__item .bx--dropdown-link span {
    pointer-events: none; }
  .bx--account-switcher__menu__item .bx--dropdown-link svg {
    fill: #fff;
    pointer-events: none;
    vertical-align: middle; }

.bx--account-switcher__menu__item .bx--dropdown--open .bx--dropdown-text {
  color: #fff; }

.bx--account-switcher__menu__item .bx--dropdown--open .bx--dropdown-list {
  display: flex;
  flex-direction: column;
  max-height: 2000px;
  z-index: 10; }

.bx--account-switcher__menu__item .bx--dropdown--open .bx--dropdown-item {
  background-color: #394b54; }

.bx--unified-header {
  position: fixed;
  z-index: 6000; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--text-truncate--end {
  width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.bx--text-truncate--front {
  width: 100%;
  display: inline-block;
  direction: rtl;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--data-table-v2-container + .bx--pagination {
  border-top: 0; }

.bx--pagination {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  width: 100%;
  background-color: #fff;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  border: 1px solid #dfe3e6; }
  .bx--pagination .bx--form-item {
    flex: auto; }

.bx--pagination__left {
  display: flex;
  align-items: center; }

.bx--pagination__right {
  display: flex;
  align-items: center;
  margin-left: auto; }

.bx--pagination__text {
  font-size: 0.75rem;
  color: #5a6872;
  display: none; }
  @media (min-width: 530px) {
    .bx--pagination__text {
      display: block; } }

.bx--pagination__button-icon {
  height: 0.75rem;
  width: 0.75rem;
  fill: #5a6872;
  pointer-events: none;
  transition: 250ms; }

.bx--pagination__button {
  border: none;
  background: none;
  cursor: pointer; }
  .bx--pagination__button:hover .bx--pagination__button-icon {
    fill: #3d70b2; }
  .bx--pagination__button:focus {
    outline: 1px solid #3d70b2; }
  .bx--pagination__button:disabled:hover {
    cursor: default; }
    .bx--pagination__button:disabled:hover .bx--pagination__button-icon {
      fill: #5a6872; }

.bx--pagination__button--backward {
  margin-left: 1rem;
  margin-right: 1.5rem; }

.bx--pagination__button--forward {
  margin-left: 1.5rem; }

.bx--pagination__button--no-index {
  border-right: 0;
  margin-right: 1px; }

.bx--pagination .bx--select {
  margin-right: 0.5rem; }

.bx--pagination .bx--select--inline {
  margin-right: 0;
  width: auto; }

.bx--pagination .bx--select-input {
  height: 1.5rem;
  width: auto;
  padding: 0 1.25rem 0 0.5rem;
  margin: 0;
  font-weight: 600;
  text-align-last: center;
  box-shadow: none; }
  .bx--pagination .bx--select-input:focus {
    outline: 1px solid #3d70b2; }

.bx--pagination .bx--select__arrow {
  right: 0.3rem;
  top: 0.625rem; }

.bx--pagination .bx--text-input {
  background-color: #f4f7fb;
  height: 1.5rem;
  min-width: 1.5rem;
  width: 1.5rem;
  padding: 0;
  margin: 0;
  font-weight: 600;
  text-align: center;
  box-shadow: none;
  order: 0; }
  .bx--pagination .bx--text-input:focus {
    outline: 1px solid #3d70b2; }

.bx--pagination--inline {
  height: 42px;
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
  margin-right: -1rem; }
  .bx--pagination--inline .bx--pagination__button {
    height: 100%;
    border-left: 1px solid #dfe3e6;
    border-right: 1px solid #dfe3e6;
    width: 2.625rem;
    margin: 0; }
  .bx--pagination--inline .bx--pagination__button--forward {
    border-right: 0; }
  .bx--pagination--inline .bx--pagination__button--backward {
    margin-left: 1rem; }
  .bx--pagination--inline .bx--select--inline {
    position: relative;
    top: -1.5px; }
  .bx--pagination--inline .bx--select__arrow {
    right: 0.3rem; }

.bx--pagination.bx--skeleton .bx--skeleton__text {
  margin-right: 1rem;
  margin-bottom: 0; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--accordion {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  list-style: none;
  width: 100%; }

.bx--accordion__item {
  transition: all 250ms cubic-bezier(0.5, 0, 0.1, 1);
  border-top: 1px solid #dfe3e6;
  overflow: hidden; }
  .bx--accordion__item:focus {
    outline: none; }
    .bx--accordion__item:focus .bx--accordion__arrow {
      outline: 1px solid #3d70b2;
      overflow: visible;
      outline-offset: -0.5px; }
  .bx--accordion__item:last-child {
    border-bottom: 1px solid #dfe3e6; }

.bx--accordion__heading {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0;
  display: inline-block;
  background: none;
  appearance: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  width: 100%;
  color: #152934;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  cursor: pointer;
  padding: 0.5rem 0; }
  .bx--accordion__heading::-moz-focus-inner {
    border: 0; }
  .bx--accordion__heading:focus {
    outline: none; }
    .bx--accordion__heading:focus .bx--accordion__arrow {
      outline: 1px solid #3d70b2;
      overflow: visible;
      outline-offset: -0.5px; }

.bx--accordion__arrow {
  transition: all 250ms cubic-bezier(0.5, 0, 0.1, 1);
  height: 1.25rem;
  width: 1.25rem;
  padding: 0.25rem 0.125rem 0.25rem 0.25rem;
  margin: 0 0 0 0.25rem;
  fill: #5a6872; }

.bx--accordion__title {
  margin: 0 0 0 1rem; }

.bx--accordion__content {
  transition: all 300ms cubic-bezier(0, 0, 0.25, 1);
  padding: 0 1rem 0 2.5rem;
  height: 0;
  visibility: hidden;
  opacity: 0; }
  .bx--accordion__content p {
    font-size: 0.875rem; }

.bx--accordion__item--active {
  overflow: visible; }
  .bx--accordion__item--active .bx--accordion__content {
    padding-top: 1rem;
    padding-bottom: 1.5rem;
    height: auto;
    visibility: visible;
    opacity: 1;
    transition: all 300ms cubic-bezier(0.25, 0, 1, 1); }
  .bx--accordion__item--active .bx--accordion__arrow {
    transform: rotate(90deg);
    fill: #3d70b2; }

.bx--accordion.bx--skeleton .bx--accordion__heading,
.bx--accordion.bx--skeleton .bx--accordion__button {
  cursor: default; }

.bx--accordion.bx--skeleton .bx--accordion__arrow {
  pointer-events: none;
  fill: #5a6872;
  cursor: default; }
  .bx--accordion.bx--skeleton .bx--accordion__arrow:hover, .bx--accordion.bx--skeleton .bx--accordion__arrow:focus, .bx--accordion.bx--skeleton .bx--accordion__arrow:active {
    border: none;
    outline: none;
    cursor: default; }

.bx--skeleton .bx--accordion__heading:focus .bx--accordion__arrow {
  border: none;
  outline: none;
  cursor: default; }

.bx--accordion__title.bx--skeleton__text {
  margin-bottom: 0; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--text-truncate--end {
  width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.bx--text-truncate--front {
  width: 100%;
  display: inline-block;
  direction: rtl;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.bx--progress {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  display: flex;
  list-style: none; }

.bx--progress-step {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  flex: 1;
  min-width: 7rem;
  transition: 250ms all cubic-bezier(0.5, 0, 0.1, 1);
  overflow: visible; }

.bx--progress-line {
  position: absolute;
  top: 0.625rem;
  right: 100%;
  height: 1px;
  width: calc(100% - 24px);
  border: 1px inset transparent; }
  .bx--progress-step:first-child .bx--progress-line {
    display: none; }

.bx--progress-step svg {
  position: relative;
  z-index: 1;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  /* margin-bottom: 0.5rem; */
  fill: #3d70b2; }

.bx--progress-label {
  line-height: 1;
  width: 75%; }

.bx--progress-step--current circle:first-child {
  stroke: #3d70b2;
  stroke-width: 5;
  fill: transparent; }

.bx--progress-step--current .bx--progress-label {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #3d70b2;
  font-weight: 600; }

.bx--progress-step--current .bx--progress-line {
  background-color: #3d70b2; }

.bx--progress-step--incomplete circle {
  stroke: #8897a2;
  stroke-width: 5;
  fill: transparent; }

.bx--progress-step--incomplete .bx--progress-label {
  color: #5a6872; }

.bx--progress-step--incomplete .bx--progress-line {
  background-color: #8897a2; }

.bx--progress-step--complete circle {
  stroke: #3d70b2;
  stroke-width: 5;
  fill: transparent; }

.bx--progress-step--complete polygon {
  fill: #3d70b2; }

.bx--progress-step--complete .bx--progress-label {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #3d70b2;
  font-weight: 600; }

.bx--progress-step--complete .bx--progress-line {
  background-color: #3d70b2; }

.bx--progress.bx--skeleton .bx--progress-label {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: rgba(61, 112, 178, 0.1);
  height: 0.75rem;
  width: 2.5rem; }
  .bx--progress.bx--skeleton .bx--progress-label:hover, .bx--progress.bx--skeleton .bx--progress-label:focus, .bx--progress.bx--skeleton .bx--progress-label:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--progress.bx--skeleton .bx--progress-label:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    background: rgba(61, 112, 178, 0.1);
    animation: 3000ms ease-in-out skeleton infinite; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--text-truncate--end {
  width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.bx--text-truncate--front {
  width: 100%;
  display: inline-block;
  direction: rtl;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--breadcrumb {
  font-size: 0.875rem;
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  display: inline; }
  @media screen and (min-width: 500px) {
    .bx--breadcrumb {
      display: flex;
      flex-wrap: wrap; } }

.bx--breadcrumb-item {
  margin-right: 1rem;
  display: flex;
  align-items: center; }

.bx--breadcrumb-item::after {
  content: '/';
  margin-left: 1rem;
  color: #5a6872; }

.bx--breadcrumb--no-trailing-slash .bx--breadcrumb-item:last-child::after {
  content: ''; }

.bx--breadcrumb-item:last-child {
  margin-right: 0; }
  .bx--breadcrumb-item:last-child::after {
    margin-right: 0; }

.bx--breadcrumb .bx--link {
  white-space: nowrap;
  font-weight: 400;
  text-decoration: none;
  border-bottom: 1px solid transparent; }
  .bx--breadcrumb .bx--link:hover, .bx--breadcrumb .bx--link:focus {
    outline: none;
    color: #294c79;
    border-bottom: 1px solid #294c79; }

@-moz-document url-prefix() {
  .bx--breadcrumb .bx--link {
    border-bottom: none; } }

.bx--breadcrumb.bx--skeleton .bx--link {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: rgba(61, 112, 178, 0.1);
  width: 6.25rem;
  height: 1rem; }
  .bx--breadcrumb.bx--skeleton .bx--link:hover, .bx--breadcrumb.bx--skeleton .bx--link:focus, .bx--breadcrumb.bx--skeleton .bx--link:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--breadcrumb.bx--skeleton .bx--link:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    background: rgba(61, 112, 178, 0.1);
    animation: 3000ms ease-in-out skeleton infinite; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--text-truncate--end {
  width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.bx--text-truncate--front {
  width: 100%;
  display: inline-block;
  direction: rtl;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.bx--text-truncate--end {
  width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.bx--text-truncate--front {
  width: 100%;
  display: inline-block;
  direction: rtl;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--text-truncate--end {
  width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.bx--text-truncate--front {
  width: 100%;
  display: inline-block;
  direction: rtl;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.bx--toolbar {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 1rem 0; }
  .bx--toolbar > div {
    margin: 0 0.25rem; }
  .bx--toolbar .bx--search-input {
    height: 2rem;
    background-color: transparent;
    outline: none; }
  .bx--toolbar .bx--search-close {
    display: none; }
  .bx--toolbar .bx--overflow-menu__icon {
    fill: #5a6872;
    transition: fill 50ms cubic-bezier(0.5, 0, 0.1, 1); }
  .bx--toolbar .bx--search-magnifier {
    fill: #5a6872;
    transform: scale(1.15);
    transition: all 175ms cubic-bezier(0.5, 0, 0.1, 1);
    top: 0.5rem;
    left: 0.375rem;
    cursor: pointer; }
  .bx--toolbar fieldset {
    border: 0;
    padding: 0; }
  .bx--toolbar .bx--toolbar-search--active {
    width: 15.625rem; }
    .bx--toolbar .bx--toolbar-search--active .bx--search-magnifier {
      transform: scale(1);
      top: 0.5625rem; }
    .bx--toolbar .bx--toolbar-search--active .bx--search-input {
      background-color: #fff; }
    .bx--toolbar .bx--toolbar-search--active .bx--search-close {
      display: block; }
  .bx--toolbar .bx--checkbox-label {
    margin-bottom: 0; }
  .bx--toolbar .bx--overflow-menu--open > .bx--overflow-menu__icon {
    fill: #3d70b2; }

.bx--toolbar-search {
  width: 1.8rem;
  transition: all 175ms cubic-bezier(0.5, 0, 0.1, 1); }

.bx--toolbar-search__btn {
  position: absolute;
  left: 0;
  top: 0;
  background: transparent;
  border: 0;
  height: 2rem;
  width: 2rem; }
  .bx--toolbar-search__btn:focus {
    outline: 1px solid #3d70b2; }

.bx--toolbar-filter-icon {
  padding-left: 0;
  padding-right: 0; }

.bx--toolbar-menu__title {
  font-size: 0.75rem;
  letter-spacing: 0;
  font-weight: 600;
  padding: 0.5rem 1.25rem; }

.bx--toolbar-menu__option {
  padding: 0.5rem 1.25rem; }

.bx--toolbar-menu__divider {
  width: 100%;
  border: 0;
  border-top: 1px solid #dfe3e6; }

.bx--radio-button-group {
  border: none; }

.bx--time-picker {
  display: flex;
  align-items: flex-end; }
  .bx--time-picker .bx--label {
    order: 1; }

.bx--time-picker__input {
  display: flex;
  flex-direction: column; }

.bx--time-picker .bx--select-input {
  padding-right: 2rem; }

.bx--time-picker .bx--select__arrow {
  right: 0.875rem; }

.bx--time-picker__input-field {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  background-color: #f4f7fb;
  border: none;
  width: 4.875rem;
  color: #152934;
  height: 2.5rem;
  padding: 0 1rem;
  order: 2;
  box-shadow: 0 1px 0 0 #5a6872; }
  .bx--time-picker__input-field:focus {
    outline: none;
    box-shadow: 0 2px 0 0 #3d70b2; }
  .bx--time-picker__input-field:focus ~ .bx--label {
    color: #3d70b2; }
  .bx--time-picker__input-field[data-invalid], .bx--time-picker__input-field[data-invalid]:focus {
    box-shadow: 0 2px 0 0 #e0182d; }
  .bx--time-picker__input-field[data-invalid]:focus ~ .bx--label {
    color: #e0182d; }
  .bx--time-picker__input-field ~ .bx--form-requirement {
    order: 3;
    color: #e0182d;
    font-weight: 400;
    margin-top: 0;
    max-height: 0; }
    .bx--time-picker__input-field ~ .bx--form-requirement::before {
      display: none; }
  .bx--time-picker__input-field[data-invalid] ~ .bx--form-requirement {
    overflow: visible;
    max-height: 0;
    margin-top: 0.25rem; }
  .bx--time-picker__input-field:disabled {
    opacity: 0.5;
    cursor: not-allowed; }

.bx--time-picker--light .bx--time-picker__input-field {
  background: #fff; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--text-truncate--end {
  width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.bx--text-truncate--front {
  width: 100%;
  display: inline-block;
  direction: rtl;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--slider-container {
  max-width: 37.5rem;
  min-width: 12.5rem;
  display: flex;
  align-items: center;
  user-select: none; }
  @media screen and (min-width: 768px) {
    .bx--slider-container {
      min-width: 21.875rem; } }

.bx--slider {
  position: relative;
  width: 100%;
  margin: 0 1rem; }

.bx--slider--disabled {
  opacity: 0.5; }

.bx--slider--disabled .bx--slider__thumb:hover {
  transform: translate(-50%, -50%); }

.bx--slider--disabled .bx--slider__thumb:focus {
  box-shadow: none;
  outline: none; }

.bx--slider--disabled .bx--slider__thumb:active {
  background: #3d70b2;
  transform: translate(-50%, -50%); }

.bx--slider__range-label {
  font-size: 0.875rem;
  color: #5a6872; }
  .bx--slider__range-label:last-of-type {
    margin-right: 1rem; }

.bx--slider__track {
  position: absolute;
  width: 100%;
  height: 0.25rem;
  background: #8897a2;
  cursor: pointer;
  transform: translate(0%, -50%); }

.bx--slider__filled-track {
  position: absolute;
  width: 100%;
  height: 0.25rem;
  background: #3d70b2;
  transform-origin: left;
  pointer-events: none;
  transform: translate(0%, -50%); }

.bx--slider__thumb {
  position: absolute;
  height: 1.5rem;
  width: 1.5rem;
  background: #3d70b2;
  border-radius: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  transition: transform 100ms cubic-bezier(0.5, 0, 0.1, 1), background 100ms cubic-bezier(0.5, 0, 0.1, 1);
  cursor: pointer;
  outline: none; }
  .bx--slider__thumb--clicked {
    transition: left 250ms cubic-bezier(0.5, 0, 0.1, 1); }
  .bx--slider__thumb:hover {
    transform: translate(-50%, -50%) scale(1.05); }
  .bx--slider__thumb:focus {
    box-shadow: 0 0 0 3px #7cc7ff;
    outline: 1px solid transparent; }
  .bx--slider__thumb:active {
    background: #36649f;
    transform: translate(-50%, -50%) scale(1.25); }

.bx--slider__input {
  display: none; }

.bx--slider-text-input,
.bx-slider-text-input {
  width: 3.75rem;
  min-width: 0;
  height: 2rem;
  padding: 0;
  text-align: center;
  font-weight: 600;
  -moz-appearance: textfield; }
  .bx--slider-text-input::-webkit-outer-spin-button, .bx--slider-text-input::-webkit-inner-spin-button,
  .bx-slider-text-input::-webkit-outer-spin-button,
  .bx-slider-text-input::-webkit-inner-spin-button {
    display: none; }

.bx--slider-container.bx--skeleton .bx--slider__range-label {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: rgba(61, 112, 178, 0.1);
  width: 1.25rem;
  height: 0.75rem; }
  .bx--slider-container.bx--skeleton .bx--slider__range-label:hover, .bx--slider-container.bx--skeleton .bx--slider__range-label:focus, .bx--slider-container.bx--skeleton .bx--slider__range-label:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--slider-container.bx--skeleton .bx--slider__range-label:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    background: rgba(61, 112, 178, 0.1);
    animation: 3000ms ease-in-out skeleton infinite; }

.bx--slider-container.bx--skeleton .bx--slider__track {
  cursor: default;
  pointer-events: none; }

.bx--slider-container.bx--skeleton .bx--slider__thumb {
  left: 50%;
  cursor: default;
  pointer-events: none; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--tile {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  display: block;
  min-width: 8rem;
  min-height: 4rem;
  background-color: #fff;
  border: 1px solid #f4f7fb;
  position: relative;
  padding: 1rem; }
  .bx--tile:focus {
    outline: 1px solid #3d70b2; }

.bx--tile--clickable,
.bx--tile--selectable,
.bx--tile--expandable {
  transition: 250ms cubic-bezier(0.5, 0, 0.1, 1);
  cursor: pointer; }
  .bx--tile--clickable:hover,
  .bx--tile--selectable:hover,
  .bx--tile--expandable:hover {
    border: 1px solid #dfe3e6; }
  .bx--tile--clickable:hover .bx--tile__checkmark, .bx--tile--clickable:focus .bx--tile__checkmark,
  .bx--tile--selectable:hover .bx--tile__checkmark,
  .bx--tile--selectable:focus .bx--tile__checkmark,
  .bx--tile--expandable:hover .bx--tile__checkmark,
  .bx--tile--expandable:focus .bx--tile__checkmark {
    opacity: 1; }
  .bx--tile--clickable:hover .bx--tile__chevron svg, .bx--tile--clickable:focus .bx--tile__chevron svg,
  .bx--tile--selectable:hover .bx--tile__chevron svg,
  .bx--tile--selectable:focus .bx--tile__chevron svg,
  .bx--tile--expandable:hover .bx--tile__chevron svg,
  .bx--tile--expandable:focus .bx--tile__chevron svg {
    fill: #3d70b2; }

.bx--tile--clickable:focus,
.bx--tile--expandable:focus {
  outline: 1px solid #3d70b2; }

.bx--tile--selectable {
  padding-right: 3rem; }

.bx--tile--selectable:focus {
  outline: none;
  border: 1px solid #3d70b2; }

.bx--tile--is-clicked {
  box-shadow: none;
  border: 1px solid #dfe3e6; }

.bx--tile__checkmark,
.bx--tile__chevron {
  position: absolute;
  transition: 250ms cubic-bezier(0.5, 0, 0.1, 1);
  border: none;
  background: transparent; }
  .bx--tile__checkmark:focus,
  .bx--tile__chevron:focus {
    outline: 1px solid #3d70b2; }

.bx--tile__checkmark {
  height: 1rem;
  top: 1rem;
  right: 1rem;
  opacity: 0; 
  display: inline-block;
  margin-right: .5vw;

}
  .bx--tile__checkmark svg {
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.25);
    fill: rgba(61, 112, 178, 0.25); }

.bx--tile__chevron {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  height: 1rem; }
  .bx--tile__chevron svg {
    transform-origin: center;
    transition: 250ms cubic-bezier(0.5, 0, 0.1, 1);
    fill: #5a6872; }
  .bx--tile__chevron:hover {
    cursor: pointer; }

.bx--tile--expandable {
  overflow: hidden;
  cursor: default;
  transition: 250ms cubic-bezier(0.5, 0, 0.1, 1); }

.bx--tile-content__above-the-fold {
  display: block; }

.bx--tile-content__below-the-fold {
  display: block;
  opacity: 0;
  transition: 250ms cubic-bezier(0.5, 0, 0.1, 1); }

.bx--tile--is-expanded {
  overflow: visible;
  transition: 250ms cubic-bezier(0.5, 0, 0.1, 1); }
  .bx--tile--is-expanded .bx--tile__chevron svg {
    transform: rotate(-180deg); }
  .bx--tile--is-expanded .bx--tile-content__below-the-fold {
    opacity: 1;
    transition: 250ms cubic-bezier(0.5, 0, 0.1, 1); }

.bx--tile--is-selected,
.bx--tile--is-selected:hover,
.bx--tile--is-selected:focus {
  border: 1px solid #3d70b2;
  outline: none; }

.bx--tile-input:checked + .bx--tile__checkmark {
  opacity: 1; }

.bx--tile-input:checked + .bx--tile__checkmark svg {
  fill: #3d70b2;
  background-color: #fff; }

.bx--tile-content {
  width: 100%;
  height: 100%; }

.bx--tile-input {
  display: none; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--lightbox {
  width: 66rem;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.1); }

.bx--lightbox__main {
  position: relative; }

.bx--lightbox__btn {
  border: 0;
  background: transparent;
  cursor: pointer;
  position: absolute;
  top: 50%; }
  .bx--lightbox__btn:first-of-type {
    left: -2rem; }
  .bx--lightbox__btn:last-of-type {
    right: -2rem;
    transform: rotate(180deg); }
  .bx--lightbox__btn:focus {
    outline: 1px solid #3d70b2; }
  .bx--lightbox__btn svg {
    height: 1.5rem;
    fill: #5a6872; }

.bx--lightbox__item {
  display: none;
  width: 100%; }

.bx--lightbox__item--shown {
  display: block; }

.bx--lightbox__footer {
  background: #fff;
  overflow: hidden; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--carousel {
  display: flex;
  align-items: center; }

.bx--carousel-container {
  max-width: 50.625rem;
  overflow: hidden;
  padding: 0 1px; }

.bx--filmstrip {
  display: flex;
  justify-content: space-between;
  transition: transform 100ms cubic-bezier(0.25, 0, 1, 1);
  padding: 1.5rem 0;
  width: auto; }

.bx--filmstrip-btn {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0;
  display: inline-block;
  background: none;
  appearance: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  width: 100%;
  height: 1.25rem;
  width: 1.25rem;
  margin-bottom: 1rem;
  margin-right: 0.1875rem;
  margin-left: 0.1875rem; }
  .bx--filmstrip-btn::-moz-focus-inner {
    border: 0; }
  .bx--filmstrip-btn:hover {
    cursor: pointer; }
  .bx--filmstrip-btn:focus {
    outline: 1px solid #3d70b2; }

.bx--carousel__btn {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0;
  display: inline-block;
  background: none;
  appearance: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  cursor: pointer;
  padding: 0; }
  .bx--carousel__btn::-moz-focus-inner {
    border: 0; }
  .bx--carousel__btn:first-child {
    margin-right: 1.25rem; }
  .bx--carousel__btn:last-child {
    margin-left: 1.25rem; }
  .bx--carousel__btn:focus {
    outline: 1px solid #3d70b2; }
  .bx--carousel__btn:last-of-type {
    transform: rotate(180deg); }
  .bx--carousel__btn svg {
    height: 1.5rem;
    width: 1rem;
    fill: #3d70b2; }

.bx--carousel__item {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0;
  display: inline-block;
  background: none;
  appearance: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  width: 100%;
  padding: 0;
  line-height: 0;
  margin-right: 1.25rem;
  cursor: pointer; }
  .bx--carousel__item::-moz-focus-inner {
    border: 0; }
  .bx--carousel__item:hover, .bx--carousel__item:focus {
    outline: 1px solid #3d70b2; }

.bx--carousel__item--active {
  outline: 1px solid #3d70b2; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--skeleton__text {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: rgba(61, 112, 178, 0.1);
  width: 100%;
  height: 1rem;
  margin-bottom: 0.5rem; }
  .bx--skeleton__text:hover, .bx--skeleton__text:focus, .bx--skeleton__text:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--skeleton__text:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    background: rgba(61, 112, 178, 0.1);
    animation: 3000ms ease-in-out skeleton infinite; }

.bx--skeleton__heading {
  height: 1.5rem;
  margin-bottom: 0.75rem; }

.bx--icon--skeleton {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: rgba(61, 112, 178, 0.1);
  display: inline-block;
  width: 1rem;
  height: 1rem; }
  .bx--icon--skeleton:hover, .bx--icon--skeleton:focus, .bx--icon--skeleton:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--icon--skeleton:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    background: rgba(61, 112, 178, 0.1);
    animation: 3000ms ease-in-out skeleton infinite; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--text-truncate--end {
  width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.bx--text-truncate--front {
  width: 100%;
  display: inline-block;
  direction: rtl;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--footer--bottom-fixed {
  position: fixed;
  bottom: 0;
  left: 0; }

.bx--footer {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-top: 2px solid #3d70b2;
  background-color: #fff;
  min-height: 3.5rem;
  z-index: 5000;
  padding: 1.25rem 5%;
  width: 100%; }

.bx--footer-info {
  display: flex; }
  @media screen and (max-width: 600px) {
    .bx--footer-info {
      flex-direction: column; } }

.bx--footer-info__item {
  font-size: 1.125rem;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  margin: 0;
  margin-right: 4rem; }

.bx--footer-info__item > .bx--link {
  font-weight: 600; }

.bx--footer-info__item > .bx--footer-label {
  font-size: 0.875rem;
  line-height: 1.5;
  margin: 0; }
  @media screen and (max-width: 600px) {
    .bx--footer-info__item > .bx--footer-label {
      display: none; } }

.bx--footer-cta {
  margin-left: auto; }

/**
 * Generic `deprecate` mixin that is being used to indicate that a component is
 * no longer going to be present in the next major release of Carbon.
 */
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--fab {
  transform: rotate(0);
  transition: transform 250ms;
  transform-origin: center;
  display: inline-block;
  width: 4.5rem;
  height: 4.5rem;
  text-decoration: none;
  filter: drop-shadow(0px 3px 3px 0 rgba(0, 0, 0, 0.1)); }
  .bx--fab__hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    visibility: visible;
    white-space: nowrap;
    color: #fff; }
  .bx--fab__svg {
    width: 100%; }
    .bx--fab__svg .bx--fab__hexagon {
      transition: fill 250ms;
      fill: #5596e6; }
    .bx--fab__svg .bx--fab__plus-icon {
      transform: rotate(0);
      transition: transform 250ms;
      transform-origin: center;
      fill: #fff; }
  .bx--fab[data-state='closed'] {
    transform: rotate(90deg);
    transition: transform 250ms;
    transform-origin: center; }
    .bx--fab[data-state='closed'] .bx--fab__hexagon {
      transition: fill 250ms;
      fill: #42535c; }
    .bx--fab[data-state='closed'] .bx--fab__plus-icon {
      transform: rotate(-45deg);
      transition: transform 250ms;
      transform-origin: center; }
